import { listNotifications as api_listNotifications } from "../../api/notifications/listNotifications";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Notification from "../../models/Notification";
import Spinner from "../../components/UI/Spinner/Spinner";
import NotificationsList from "./NotificationsList/NotificationsList";
import NotificationContext from "../../contexts/notification-context";
import { parseCursor } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

const NotificationsPage = () => {
    const { t } = useTranslation();
    const { markAllNotificationsAsStale } = useContext(NotificationContext);
    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getNotifications = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                const response = await api_listNotifications(cursor);
                if (cursor === undefined) setNotifications(response.results);
                else
                    setNotifications((prev: Notification[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
                markAllNotificationsAsStale();
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [markAllNotificationsAsStale]
    );

    useEffect(() => {
        setNotifications([]);
        setCursor(undefined);
        setHasMore(true);
        getNotifications(undefined, true);
    }, [getNotifications]);

    return (
        <div>
            <h6 className="mb-3">{t("navbar.notifications")}</h6>
            <hr />
            {!!notifications.length && (
                <NotificationsList
                    notifications={notifications}
                    getNotifications={() => getNotifications(cursor, hasMore)}
                    isLoading={isLoading}
                />
            )}
            {isLoading && <Spinner className="mt-3" />}
            {!isLoading && !notifications.length && (
                <div className="d-flex justify-content-center form-text">
                    {t("notificationsPage.noNotificationsFound")}
                </div>
            )}
        </div>
    );
};

export default NotificationsPage;
