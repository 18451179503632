import blank_profile_image from "../../../../../images/blank-profile-picture.svg";
import React, { ChangeEvent, useContext, useRef, useState } from "react";
import {
    FailureResponse as UpdateProfileImageFailureResponse,
    updateProfileImage as api_updateProfileImage,
} from "../../../../../api/users/updateProfileImage";
import AuthContext from "../../../../../contexts/auth-context";
import Button from "../../../../../components/UI/Button/Button";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../contexts/modal-context";
import { ApiErrorCode } from "../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import InputGroup from "../../../../../components/UI/InputGroup/InputGroup";
import { useTranslation } from "react-i18next";
import { parseProfileImageSrc } from "../../../../../utils/helpers";

const ProfileImageForm = () => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const inputRef = useRef<HTMLInputElement>(null);
    const { user, storeUser } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [profileImageInputErrorMsg, setProfileImageInputErrorMsg] =
        useState("");

    const profileImageInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setImageFile(null);
        const file = event.currentTarget.files![0];
        if (file.size >= 2_092_152) {
            setProfileImageInputErrorMsg(
                t(
                    "settingsPage.profileSettings.profileImageForm.profileImageSize"
                )
            );
        } else {
            setImageFile(file);
            setProfileImageInputErrorMsg("");
        }
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (imageFile) {
            try {
                setIsLoading(true);
                const response = await api_updateProfileImage(imageFile);
                storeUser(response);
                setImageFile(null);
                inputRef.current!.value = "";
            } catch (error) {
                const { errors, status_code, code } =
                    error as UpdateProfileImageFailureResponse;

                if (errors) {
                    if (status_code === 403) {
                        if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                            showModal(
                                <EmailNotVerifiedErrorOverlay
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                        } else
                            showModal(
                                <AlertOverlay
                                    status="error"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                    } else {
                        if (errors.profile_image)
                            setProfileImageInputErrorMsg(
                                errors.profile_image[0]
                            );
                    }
                }
            }
            setIsLoading(false);
        }
    };

    let image_src: string = blank_profile_image;
    if (!!user?.profile_image)
        image_src = parseProfileImageSrc(user.profile_image);

    return (
        <>
            <h6>
                {t(
                    "settingsPage.profileSettings.profileImageForm.uploadProfileImage"
                )}
            </h6>
            <hr />
            <div className="d-flex flex-column align-items-center">
                <img
                    src={image_src}
                    alt="profile"
                    width="130"
                    height="130"
                    className="rounded-circle me-2 border"
                />
                <form className="mt-3 w-100" onSubmit={submitHandler}>
                    <span className="form-text">
                        {t(
                            "settingsPage.profileSettings.profileImageForm.uploadSquareImage"
                        )}
                    </span>
                    <InputGroup
                        className="input-group-sm"
                        errorMsg={profileImageInputErrorMsg}
                        type="file"
                        accept="image/*"
                        ref={inputRef}
                        onChange={profileImageInputChangeHandler}
                        trailingAddon={
                            <Button
                                className="btn btn-sm btn-primary"
                                type="submit"
                                disabled={
                                    !imageFile || !!profileImageInputErrorMsg
                                }
                                isLoading={isLoading}
                                onlySpinner={true}
                            >
                                {t(
                                    "settingsPage.profileSettings.profileImageForm.upload"
                                )}
                            </Button>
                        }
                    />
                </form>
            </div>
        </>
    );
};

export default ProfileImageForm;
