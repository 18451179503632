import Petition, { PetitionStatus } from "../../../../models/Petition";
import PetitionAddressee, {
    PetitionAddresseeStatus,
} from "../../../../models/PetitionAddressee";
import { TbHeartHandshake } from "react-icons/tb";
import { BsShare } from "react-icons/bs";
import { MdClear, MdDone, MdDoneAll, MdStarBorder } from "react-icons/md";
import React, { useContext } from "react";
import AuthContext from "../../../../contexts/auth-context";
import Button from "../../../UI/Button/Button";
import { GoLinkExternal } from "react-icons/go";
import ClipboardCopy from "../../../UI/ClipboardCopy/ClipboardCopy";
import { useTranslation } from "react-i18next";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    XIcon,
    LinkedinShareButton,
    LinkedinIcon,
} from "react-share";

type Props = {
    petition: Petition;
    onCancelButtonClick: () => void;
    onSupportButtonClick: () => void;
    onAcceptButtonClick: (addressee_uuid: string) => void;
    onRejectButtonClick: (addressee_uuid: string) => void;
    onMarkAsFulfilledButtonClick: () => void;
    onRateEndResultButtonClick: () => void;
    acceptLoading: boolean;
    rejectLoading: boolean;
    markAsFulfilledLoading: boolean;
    cancelLoading: boolean;
    supportLoading: boolean;
};

const PetitionActionButtons = ({
    petition,
    onCancelButtonClick: cancelButtonClickHandler,
    onSupportButtonClick: supportButtonClickHandler,
    onAcceptButtonClick: acceptButtonClickHandler,
    onRejectButtonClick: rejectButtonClickHandler,
    onMarkAsFulfilledButtonClick: markAsFulfilledButtonClickHandler,
    onRateEndResultButtonClick: rateEndResultButtonClickHandler,
    acceptLoading,
    rejectLoading,
    markAsFulfilledLoading,
    cancelLoading,
    supportLoading,
}: Props) => {
    const { t } = useTranslation();
    const petitionUrl = `${
        process.env.REACT_APP_API_BASE_URL || process.env.REACT_APP_BASE_URL
    }/ssr/petitions/${petition.uuid}`;
    const { user } = useContext(AuthContext);
    const shareButton = (
        <div className="dropup">
            <button
                type="button"
                className="btn btn-sm btn-link text-decoration-none text-dark"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <BsShare className="mb-1 me-1" /> {t("petition.buttons.share")}
            </button>
            <ul
                className="dropdown-menu dropdown-menu-end"
                style={{ width: "16rem" }}
            >
                {/*<li className="px-3 py-2">{t("petition.sharePetitionLink")}</li>*/}
                {/*<li>*/}
                {/*    <hr className="dropdown-divider" />*/}
                {/*</li>*/}
                <li className="px-3 py-2 d-flex gap-2">
                    <FacebookShareButton url={petitionUrl}>
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={petitionUrl}>
                        <XIcon size={32} round />
                    </TwitterShareButton>
                    <LinkedinShareButton url={petitionUrl}>
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                    <ClipboardCopy
                        copyText={petitionUrl}
                        btnText={t("clipboardCopy.copyLink")}
                    />
                </li>
            </ul>
        </div>
    );
    let supportButton = <></>;
    let cancelButton = <></>;
    let acceptButton = <></>;
    let rejectButton = <></>;
    let markAsFulfilledButton = <></>;
    let viewEndResultButton = <></>;
    let rateEndResultButton = <></>;

    if (petition.am_i_addressee) {
        const addressee = petition.addressees.find(
            (item: PetitionAddressee) => item.user.uuid === user!.uuid
        )!;

        switch (petition.status) {
            case PetitionStatus.ONGOING:
                switch (addressee.status) {
                    case PetitionAddresseeStatus.PENDING:
                        acceptButton = (
                            <Button
                                className="btn btn-sm btn-link text-decoration-none text-success"
                                title={t("petition.buttons.accept")}
                                onClick={() =>
                                    acceptButtonClickHandler(addressee.uuid)
                                }
                                isLoading={acceptLoading}
                                disabled={acceptLoading || rejectLoading}
                            >
                                <MdDone className="mb-1" />{" "}
                                {t("petition.buttons.accept")}
                            </Button>
                        );
                        rejectButton = (
                            <Button
                                className="btn btn-sm btn-link text-decoration-none text-danger"
                                title={t("petition.buttons.reject")}
                                onClick={() =>
                                    rejectButtonClickHandler(addressee.uuid)
                                }
                                isLoading={rejectLoading}
                                disabled={acceptLoading || rejectLoading}
                            >
                                <MdClear className="mb-1" />{" "}
                                {t("petition.buttons.reject")}
                            </Button>
                        );
                        break;
                    case PetitionAddresseeStatus.ACCEPTED:
                        acceptButton = (
                            <Button
                                className="btn btn-sm btn-link text-decoration-none text-success"
                                title={t("petition.buttons.accepted")}
                                disabled
                            >
                                <MdDone className="mb-1" />{" "}
                                {t("petition.buttons.accepted")}
                            </Button>
                        );
                        break;
                }
                break;
            case PetitionStatus.REJECTED:
                rejectButton = (
                    <Button
                        className="btn btn-sm btn-link text-decoration-none text-danger"
                        title={t("petition.buttons.rejected")}
                        disabled
                    >
                        <MdClear className="mb-1" />{" "}
                        {t("petition.buttons.rejected")}
                    </Button>
                );
                break;
            case PetitionStatus.ACCEPTED:
                markAsFulfilledButton = (
                    <Button
                        className="btn btn-sm btn-link text-decoration-none text-success"
                        title={t("petition.buttons.markAsFulfilled")}
                        onClick={markAsFulfilledButtonClickHandler}
                        isLoading={markAsFulfilledLoading}
                    >
                        <MdDoneAll className="mb-1" />{" "}
                        {t("petition.buttons.markAsFulfilled")}
                    </Button>
                );
                break;
        }
    } else {
        if (petition.am_i_supporter) {
            supportButton = (
                <Button
                    className="btn btn-sm btn-link text-decoration-none"
                    title={t("petition.buttons.supported")}
                    disabled
                >
                    <TbHeartHandshake className="mb-1" />{" "}
                    {t("petition.buttons.supported")}
                </Button>
            );

            // if (petition.am_i_author && petition.supporters_count === 1) {
            if (petition.am_i_author) {
                if (petition.status === PetitionStatus.ONGOING) {
                    cancelButton = (
                        <Button
                            className="btn btn-sm btn-link text-decoration-none text-danger"
                            title={t("petition.buttons.cancel")}
                            onClick={cancelButtonClickHandler}
                            isLoading={cancelLoading}
                        >
                            <MdClear className="mb-1" />{" "}
                            {t("petition.buttons.cancel")}
                        </Button>
                    );
                } else if (petition.status === PetitionStatus.CANCELED) {
                    // cancelButton = (
                    //     <Button
                    //         className="btn btn-sm btn-link text-decoration-none text-danger"
                    //         title={t("petition.buttons.canceled")}
                    //         disabled
                    //     >
                    //         <MdClear className="mb-1" />{" "}
                    //         {t("petition.buttons.canceled")}
                    //     </Button>
                    // );
                }
            }
        } else if (petition.status === PetitionStatus.ONGOING) {
            supportButton = (
                <Button
                    className="btn btn-sm btn-link text-decoration-none"
                    title={t("petition.buttons.support")}
                    onClick={supportButtonClickHandler}
                    isLoading={supportLoading}
                >
                    <TbHeartHandshake className="mb-1" />{" "}
                    {t("petition.buttons.support")}
                </Button>
            );
        }
    }

    if (petition.status === PetitionStatus.FULFILLED) {
        supportButton = <></>;
        if (petition.end_result) {
            viewEndResultButton = (
                <a
                    className="btn btn-sm btn-link text-decoration-none text-dark"
                    href={petition.end_result.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    <GoLinkExternal className="mb-1" />{" "}
                    {t("petition.buttons.viewEndResult")}
                </a>
            );

            if (petition.end_result.can_i_rate) {
                rateEndResultButton = (
                    <Button
                        className="btn btn-sm btn-link text-decoration-none text-dark"
                        title={t("petition.buttons.rateEndResult")}
                        onClick={rateEndResultButtonClickHandler}
                    >
                        <MdStarBorder className="mb-1" />{" "}
                        {t("petition.buttons.rateEndResult")}
                    </Button>
                );
            }
        }
    }
    return (
        <div className="d-flex flex-fill justify-content-end gap-2">
            {supportButton}
            {cancelButton}
            {acceptButton}
            {rejectButton}
            {markAsFulfilledButton}
            {rateEndResultButton}
            {viewEndResultButton}
            {shareButton}
        </div>
    );
};

export default PetitionActionButtons;
