import React, { ChangeEvent, useState } from "react";
import AuthForm from "../../../components/AuthForm/AuthForm";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import { Link } from "react-router-dom";
import {
    FailureResponse,
    requestCreatorSignup as api_requestCreatorSignup,
} from "../../../api/admins/requestCreatorSignup";
import { useTranslation } from "react-i18next";

const CreatorSignupRequestForm = () => {
    const { t } = useTranslation();
    const title = t("auth.joinAsCreator");
    const regex = /[A-Za-z0-9.\-_]{3,30}/gi;
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [youtubeHandle, setYoutubeHandle] = useState("");
    const [emailInputErrorMsg, setEmailInputErrorMsg] = useState("");
    const [youtubeHandleInputErrorMsg, setYoutubeHandleInputErrorMsg] =
        useState("");
    const [formErrorMsg, setFormErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const isEmailValid = () => {
        if (!email) {
            setEmailInputErrorMsg(t("auth.pleaseEnterEmail"));
            return false;
        }
        return true;
    };

    const isYoutubeHandleValid = () => {
        if (!youtubeHandle) {
            setYoutubeHandleInputErrorMsg(t("auth.pleaseEnterYouTubeHandle"));
            return false;
        }
        return youtubeHandleInputErrorMsg === "";
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (isEmailValid() && isYoutubeHandleValid()) {
            setIsLoading(true);
            try {
                const response = await api_requestCreatorSignup(
                    email,
                    "@" + youtubeHandle
                );
                setSuccessMsg(response.message);
            } catch (error) {
                const { errors } = error as FailureResponse;

                if (errors) {
                    if (errors.non_field_errors)
                        setFormErrorMsg(errors.non_field_errors[0]);
                    if (errors.email) setEmailInputErrorMsg(errors.email[0]);
                    if (errors.youtube_handle)
                        setYoutubeHandleInputErrorMsg(errors.youtube_handle[0]);
                }
            }
            setIsLoading(false);
        }
    };

    const emailInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
        setEmailInputErrorMsg("");
        setFormErrorMsg("");
    };

    const youtubeHandleInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        let handle = event.currentTarget.value;
        if (handle.startsWith("@")) handle = handle.substring(1);
        setYoutubeHandle(handle);
        setFormErrorMsg("");
        if (
            !!handle &&
            (!handle.match(regex) ||
                handle.length !== handle.match(regex)![0].length)
        )
            setYoutubeHandleInputErrorMsg(t("auth.invalidYouTubeHandle"));
        else setYoutubeHandleInputErrorMsg("");
    };

    return (
        <>
            {!successMsg && (
                <AuthForm
                    id="creator-signup-request-form"
                    title={title}
                    errorMsg={formErrorMsg}
                    submitButtonText={t("auth.requestToJoin")}
                    onSubmit={submitHandler}
                    isLoading={isLoading}
                >
                    <InputGroup
                        type="email"
                        placeholder={t("auth.email")}
                        errorMsg={emailInputErrorMsg}
                        onChange={emailInputChangeHandler}
                        value={email}
                    />
                    <InputGroup
                        type="text"
                        placeholder={t("auth.youtubeHandle")}
                        errorMsg={youtubeHandleInputErrorMsg}
                        onChange={youtubeHandleInputChangeHandler}
                        value={youtubeHandle}
                        leadingAddon={
                            <span className="input-group-text">@</span>
                        }
                    />
                </AuthForm>
            )}
            {successMsg && (
                <>
                    <h3 className="text-center mb-4">{title}</h3>
                    <div className="alert alert-success" role="alert">
                        {successMsg}
                    </div>
                </>
            )}
            <div className="d-flex flex-column align-items-center mt-2 gap-3">
                <div className="form-text mt-3">
                    {t("auth.alreadyHaveAccount")}{" "}
                    <Link to="/login" className="text-decoration-none">
                        {t("auth.login")}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default CreatorSignupRequestForm;
