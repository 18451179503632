import { Link, useSearchParams } from "react-router-dom";
import AuthForm from "../../../components/AuthForm/AuthForm";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/auth-context";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import { FailureResponse } from "../../../api/users/signup";
import {
    FALLBACK_LOCALE,
    LOCALE,
    SIGNUP_BY_INVITE_ONLY,
} from "../../../constants";
import { Trans, useTranslation } from "react-i18next";
import { GoogleLogin } from "@react-oauth/google";

export enum SignUpFormID {
    SIGNUP_FORM = "signup-form",
    SIGNUP_FORM_INVITE = "signup-form-invite",
}

interface Props {
    id: SignUpFormID;
}

const SignupForm = ({ id }: Props) => {
    const { t } = useTranslation();
    let [searchParams] = useSearchParams();
    const creatorInvitationCode = searchParams.get("code");
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [nameInputErrorMsg, setNameInputErrorMsg] = useState("");
    const [usernameInputErrorMsg, setUsernameInputErrorMsg] = useState("");
    const [emailInputErrorMsg, setEmailInputErrorMsg] = useState("");
    const [passwordInputErrorMsg, setPasswordInputErrorMsg] = useState("");
    const [formErrorMsg, setFormErrorMsg] = useState("");

    useEffect(() => {}, []);

    const isNameValid = () => {
        if (!name) {
            setNameInputErrorMsg(t("auth.pleaseEnterName"));
            return false;
        }
        return true;
    };

    const isUsernameValid = () => {
        if (!username) {
            setUsernameInputErrorMsg(t("auth.pleaseEnterUsername"));
            return false;
        }
        return true;
    };

    const isEmailValid = () => {
        if (!email) {
            setEmailInputErrorMsg(t("auth.pleaseEnterEmail"));
            return false;
        }
        return true;
    };

    const isPasswordValid = () => {
        if (!password) {
            setPasswordInputErrorMsg(t("auth.pleaseEnterPassword"));
            return false;
        }
        return true;
    };

    const nameInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.currentTarget.value);
        setNameInputErrorMsg("");
        setFormErrorMsg("");
    };

    const usernameInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setUsername(event.currentTarget.value);
        setUsernameInputErrorMsg("");
        setFormErrorMsg("");
    };

    const emailInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
        setEmailInputErrorMsg("");
        setFormErrorMsg("");
    };

    const passwordInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setPassword(event.currentTarget.value);
        setPasswordInputErrorMsg("");
        setFormErrorMsg("");
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();

        if (
            isNameValid() &&
            isUsernameValid() &&
            isEmailValid() &&
            isPasswordValid()
        ) {
            setIsLoading(true);
            setFormErrorMsg("");
            try {
                const invitedBy =
                    id === SignUpFormID.SIGNUP_FORM
                        ? creatorInvitationCode
                        : localStorage.getItem("invitedBy");
                await authCtx.signup(
                    name,
                    username,
                    email,
                    password,
                    invitedBy
                );
                localStorage.removeItem("invitedBy");
                const referrer = localStorage.getItem("referrer");
                if (referrer) {
                    window.location.href = referrer;
                }
            } catch (error) {
                const { errors } = error as FailureResponse;

                if (errors) {
                    if (errors.non_field_errors)
                        setFormErrorMsg(errors.non_field_errors[0]);
                    if (errors.name) setNameInputErrorMsg(errors.name[0]);
                    if (errors.username)
                        setUsernameInputErrorMsg(errors.username[0]);
                    if (errors.email) setEmailInputErrorMsg(errors.email[0]);
                    if (errors.password)
                        setPasswordInputErrorMsg(errors.password[0]);
                    if (errors.invited_by)
                        setFormErrorMsg(errors.invited_by[0]);
                }
            }
            setIsLoading(false);
        }
    };

    const loginWithGoogle = async (googleJWT: string) => {
        setFormErrorMsg("");
        setIsLoading(true);
        try {
            const invitedBy = localStorage.getItem("invitedBy");
            await authCtx.loginWithGoogle(googleJWT, invitedBy);
            localStorage.removeItem("invitedBy");
            const referrer = localStorage.getItem("referrer");
            if (referrer) {
                window.location.href = referrer;
            }
        } catch (error) {
            const { errors } = error as FailureResponse;

            if (errors) {
                if (errors.non_field_errors)
                    setFormErrorMsg(errors.non_field_errors[0]);
            }
        }
        setIsLoading(false);
    };

    const title = t("auth.joinAblebees");
    const signupForm = (
        <>
            <AuthForm
                id={id}
                title={title}
                errorMsg={formErrorMsg}
                submitButtonText={t("auth.signup")}
                onSubmit={submitHandler}
                isLoading={isLoading}
            >
                <GoogleLogin
                    width="360"
                    text="signup_with"
                    locale={localStorage.getItem(LOCALE) || FALLBACK_LOCALE}
                    onSuccess={async (credentialResponse) => {
                        await loginWithGoogle(
                            credentialResponse.credential || ""
                        );
                    }}
                    onError={() => {}}
                />
                <div>or</div>
                <InputGroup
                    type="text"
                    placeholder={t("auth.name")}
                    errorMsg={nameInputErrorMsg}
                    onChange={nameInputChangeHandler}
                    value={name}
                />
                <InputGroup
                    type="email"
                    placeholder={t("auth.email")}
                    errorMsg={emailInputErrorMsg}
                    onChange={emailInputChangeHandler}
                    value={email}
                />
                <InputGroup
                    type="text"
                    placeholder={t("auth.username")}
                    errorMsg={usernameInputErrorMsg}
                    onChange={usernameInputChangeHandler}
                    value={username}
                />
                <InputGroup
                    type="password"
                    placeholder={t("auth.password")}
                    errorMsg={passwordInputErrorMsg}
                    onChange={passwordInputChangeHandler}
                    value={password}
                />
            </AuthForm>
            <div className="d-flex flex-column align-items-center mt-2 gap-3">
                <div className="form-text">
                    <Trans
                        i18nKey="auth.acceptTerms"
                        components={[
                            <Link
                                to="/terms"
                                className="text-decoration-none"
                            />,
                            <Link
                                to="/privacy"
                                className="text-decoration-none"
                            />,
                            <Link
                                to="/cookies"
                                className="text-decoration-none"
                            />,
                        ]}
                    />
                </div>
                <div className="form-text mt-2">
                    {t("auth.alreadyHaveAccount")}{" "}
                    <Link to="/login" className="text-decoration-none">
                        {t("auth.login")}
                    </Link>
                </div>
            </div>
        </>
    );

    const redirect = (
        <>
            <div className="d-flex flex-column align-items-center">
                <h3 className="mb-4">{title}</h3>
                <p className="mb-0 text-center">
                    {t("auth.getInvitationLink")}
                </p>
                <hr className="w-75" />
                <div>
                    <p className="text-center">{t("auth.joinAsCreatorMsg")}</p>
                    <Link
                        to="/join-as-creator"
                        className="btn btn-primary text-decoration-none w-100"
                    >
                        {t("auth.joinAsCreator")}
                    </Link>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center mt-2 gap-3">
                <div className="form-text mt-3">
                    {t("auth.alreadyHaveAccount")}{" "}
                    <Link to="/login" className="text-decoration-none">
                        {t("auth.login")}
                    </Link>
                </div>
            </div>
        </>
    );

    let content = signupForm;
    if (
        SIGNUP_BY_INVITE_ONLY &&
        id === SignUpFormID.SIGNUP_FORM &&
        !creatorInvitationCode
    )
        content = redirect;

    return content;
};

export default SignupForm;
