import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export type SuccessResponse = {};

export const followFavoriteYoutubeCreators = async (
    channel_ids: string[]
): Promise<SuccessResponse> => {
    const token = localStorage.getItem("token");
    try {
        const channel_ids_params = channel_ids
            .map((channel_id) => `channel_id=${channel_id}`)
            .join("&");
        return await axios.get(
            `/users/follow-favorite-ytcreators?${channel_ids_params}`,
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
    } catch (error: any) {
        throw error.response.data;
    }
};
