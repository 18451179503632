import React, { ForwardedRef } from "react";
import Notification, {
    NotificationClickDestinationType,
} from "../../../../models/Notification";
import blank_profile_image from "../../../../images/blank-profile-picture.svg";
import classes from "./NotificationsListItem.module.scss";
import { markNotificationAsSeen as api_markNotificationAsSeen } from "../../../../api/notifications/markNotificationAsSeen";
import { useNavigate } from "react-router-dom";
import logo from "../../../../icon.svg";
import { parseProfileImageSrc } from "../../../../utils/helpers";

type Props = {
    notification: Notification;
};

const NotificationsListItem = React.forwardRef(
    ({ notification }: Props, ref: ForwardedRef<HTMLDivElement>) => {
        const navigate = useNavigate();
        let link_to = `/petitions/${notification.notification.click_destination_unique_id}`;
        if (
            notification.notification.click_destination_type ===
            NotificationClickDestinationType.USER
        )
            link_to = `/users/${notification.notification.click_destination_unique_id}`;

        const dt_created_date = new Date(
            notification.dt_created
        ).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            // year: "numeric",
        });

        const dt_created_time = new Date(
            notification.dt_created
        ).toLocaleTimeString("en-GB", { hour: "numeric", minute: "numeric" });

        const markNotificationAsSeen = async () => {
            try {
                await api_markNotificationAsSeen(notification.uuid);
            } catch (error) {
                console.log(error);
            }
        };

        const clickHandler = async () => {
            await markNotificationAsSeen();
            navigate(link_to);
        };

        const get_image_src = () => {
            if (notification.notification.initiator) {
                if (notification.notification.initiator.profile_image) {
                    return parseProfileImageSrc(
                        notification.notification.initiator.profile_image
                    );
                }
                return blank_profile_image;
            }
            return logo;
        };

        return (
            <div
                className={`d-flex align-items-center justify-content-between border rounded p-2 form-text mt-0  ${
                    classes.notification
                } ${!notification.is_seen && classes.not_seen}`}
                onClick={clickHandler}
                ref={ref}
            >
                <div className="d-flex gap-2 align-items-center">
                    <img
                        src={get_image_src()}
                        width="45"
                        height="45"
                        className="rounded me-2"
                    />
                    <span>{notification.notification.message}</span>
                </div>
                <div className="d-flex flex-column align-items-end">
                    <span>{dt_created_date}</span>
                    <span>{dt_created_time}</span>
                </div>
            </div>
        );
    }
);

export default NotificationsListItem;
