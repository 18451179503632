import React, {
    Ref,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    FailureResponse,
    listPayouts as api_listPayouts,
} from "../../../../../api/payments/listPayouts";
import { ApiErrorCode } from "../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../contexts/modal-context";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import PayoutsListItem from "./PayoutsListItem/PayoutsListItem";
import Payout from "../../../../../models/Payout";
import { useTranslation } from "react-i18next";
import { parseCursor } from "../../../../../utils/helpers";

const PayoutsList = () => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(true);
    const [payouts, setPayouts] = useState<Payout[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getPayouts = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                const response = await api_listPayouts(cursor);
                if (cursor === undefined) setPayouts(response.results);
                else
                    setPayouts((prev: Payout[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                const { errors, status_code, code } = error as FailureResponse;
                if (errors)
                    if (
                        status_code === 403 &&
                        code === ApiErrorCode.EMAIL_NOT_VERIFIED
                    ) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
            }
            setIsLoading(false);
        },
        [showModal]
    );

    useEffect(() => {
        setCursor(undefined);
        setHasMore(true);
        getPayouts(undefined, true);
    }, [getPayouts]);

    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
        (node: Element) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => {
                    if (entries[0].isIntersecting) {
                        getPayouts(cursor, hasMore);
                    }
                }
            );
            if (node) observer.current.observe(node);
        },
        [isLoading, getPayouts]
    );

    const renderPayoutsList = payouts.map((item: Payout, index) => (
        <PayoutsListItem
            key={item.uuid}
            payout={item}
            ref={
                payouts.length === index + 1
                    ? (lastElementRef as Ref<HTMLDivElement>)
                    : undefined
            }
        />
    ));

    return (
        <>
            <h6>{t("settingsPage.payoutSettings.payoutsList.payouts")}</h6>
            <hr />
            <div className="d-flex flex-column gap-1">
                {payouts.length > 0 ? (
                    renderPayoutsList
                ) : (
                    <span className="form-text">
                        {t(
                            "settingsPage.payoutSettings.payoutsList.noPayoutsFound"
                        )}
                    </span>
                )}
            </div>
            {isLoading && <Spinner className="mt-3" />}
        </>
    );
};

export default PayoutsList;
