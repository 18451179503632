import axios from "../axiosConfig";
import User from "../../models/User";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type SuccessResponse = {
    key: string;
    user: User;
};

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        email?: string[];
        password?: string[];
        non_field_errors?: string[];
    };
};

export const login = async (
    email: string,
    password: string
): Promise<SuccessResponse> => {
    try {
        const response = await axios.post(
            "/users/signin",
            {
                email,
                password,
            },
            {
                headers: {
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        response.data.user = new User(response.data.user);
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};

export const loginWithGoogle = async (
    google_jwt: string,
    invited_by: string | null
): Promise<SuccessResponse> => {
    try {
        const response = await axios.post(
            "/users/signin-with-google",
            {
                google_jwt,
                invited_by,
            },
            {
                headers: {
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );

        response.data.user = new User(response.data.user);
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
