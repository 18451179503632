import User from "../../../models/User";
import ProfilePagePetitionsNavbar from "./ProfilePagePetitionsNavbar/ProfilePagePetitionsNavbar";
import PetitionsList from "../../../components/PetitionsList/PetitionsList";
import React, { useCallback, useEffect, useState } from "react";
import Petition from "../../../models/Petition";
import {
    listPetitions as api_listPetitions,
    SuccessResponse,
} from "../../../api/petitions/listPetitions";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { parseCursor } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

type Props = {
    user: User;
    activeTab: ProfilePagePetitionsTab;
};

export enum ProfilePagePetitionsTab {
    SUPPORTED = "supported",
    RECEIVED = "received",
    STARTED = "started",
}

const ProfilePagePetitions = ({ user, activeTab }: Props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [petitions, setPetitions] = useState<Petition[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getPetitions = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                let response: SuccessResponse;
                switch (activeTab) {
                    case ProfilePagePetitionsTab.SUPPORTED:
                        response = await api_listPetitions(
                            undefined,
                            undefined,
                            user.uuid,
                            undefined,
                            cursor
                        );
                        break;
                    case ProfilePagePetitionsTab.RECEIVED:
                        response = await api_listPetitions(
                            undefined,
                            [user.uuid],
                            undefined,
                            undefined,
                            cursor
                        );
                        break;
                    case ProfilePagePetitionsTab.STARTED:
                        response = await api_listPetitions(
                            user.uuid,
                            undefined,
                            undefined,
                            undefined,
                            cursor
                        );
                        break;
                }
                if (cursor === undefined) setPetitions(response.results);
                else
                    setPetitions((prev: Petition[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [user.uuid, activeTab]
    );

    useEffect(() => {
        setPetitions([]);
        setHasMore(true);
        setCursor(undefined);
        getPetitions(undefined, true);
    }, [getPetitions]);

    let noPetitionsFound = "";
    if (activeTab === ProfilePagePetitionsTab.SUPPORTED)
        noPetitionsFound = t("profilePage.noSupportedPetitions");
    else if (activeTab === ProfilePagePetitionsTab.RECEIVED)
        noPetitionsFound = t("profilePage.noReceivedPetitions");
    else noPetitionsFound = t("profilePage.noStartedPetitions");

    return (
        <>
            <ProfilePagePetitionsNavbar
                activeTab={activeTab}
                isCreator={user.is_creator}
            />
            <div>
                {!!petitions.length && (
                    <PetitionsList
                        petitions={petitions}
                        getPetitions={() => getPetitions(cursor, hasMore)}
                        isLoading={isLoading}
                    />
                )}
                {isLoading && <Spinner className="mt-3" />}
                {!isLoading && !petitions.length && (
                    <div className="d-flex justify-content-center form-text">
                        {noPetitionsFound}
                    </div>
                )}
            </div>
        </>
    );
};

export default ProfilePagePetitions;
