export enum PayoutStatus {
    PAID = "paid",
    PENDING = "pending",
    IN_TRANSIT = "in_transit",
    CANCELED = "canceled",
    FAILED = "failed",
}

export default class Payout {
    constructor(
        readonly uuid: string,
        readonly arrival_date: string,
        readonly created: string,
        readonly currency: string,
        readonly amount: number,
        readonly destination_bank_name: string,
        readonly destination_last4: string,
        readonly status: PayoutStatus,
        readonly failure_message: string,
        readonly failure_code: string
    ) {}
}
