import React, { Ref, useCallback, useRef } from "react";
import PetitionEndResultRater from "../../../../../models/PetitionEndResultRater";
import PetitionEndResultRatersListItem from "./PetitionEndResultRatersListItem/PetitionEndResultRatersListItem";

type Props = {
    raters: PetitionEndResultRater[];
    getRaters: () => void;
    isLoading: boolean;
};

const PetitionEndResultRatersList = ({
    raters,
    getRaters,
    isLoading,
}: Props) => {
    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
        (node: Element) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => {
                    if (entries[0].isIntersecting) {
                        getRaters();
                    }
                }
            );
            if (node) observer.current.observe(node);
        },
        [isLoading, getRaters]
    ) as Ref<HTMLAnchorElement>;

    const renderedRaters = raters.map(
        (item: PetitionEndResultRater, index: number) => (
            <PetitionEndResultRatersListItem
                key={item.uuid}
                rater={item}
                ref={raters.length === index + 1 ? lastElementRef : undefined}
            />
        )
    );
    return <div className="d-flex flex-column gap-1">{renderedRaters}</div>;
};

export default PetitionEndResultRatersList;
