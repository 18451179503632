import User from "../../../models/User";
import ProfilePageFollowsNavbar from "./ProfilePageFollowsNavbar/ProfilePageFollowsNavbar";
import React, { useCallback, useEffect, useState } from "react";
import { listUserFollows as api_listUserFollows } from "../../../api/users/listUserFollows";
import UserFollow from "../../../models/UserFollow";
import UsersList from "../../../components/UsersList/UsersList";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { parseCursor } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

type Props = {
    user: User;
    activeTab: ProfilePageFollowsTab;
};

export enum ProfilePageFollowsTab {
    FOLLOWERS = "followers",
    FOLLOWING = "following",
}

const ProfilePageFollows = ({ user, activeTab }: Props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getUsers = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                const response = await api_listUserFollows(
                    user.username,
                    activeTab,
                    cursor
                );
                if (cursor === undefined)
                    setUsers(
                        response.results.map((item: UserFollow) => item.user)
                    );
                else
                    setUsers((prev: User[]) => [
                        ...prev,
                        ...response.results.map(
                            (item: UserFollow) => item.user
                        ),
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [user.username, activeTab]
    );

    useEffect(() => {
        setUsers([]);
        setHasMore(true);
        setCursor(undefined);
        getUsers(undefined, true);
    }, [getUsers]);

    return (
        <>
            <ProfilePageFollowsNavbar activeTab={activeTab} />
            <div>
                {!!users && (
                    <UsersList
                        users={users}
                        getUsers={() => getUsers(cursor, hasMore)}
                        isLoading={isLoading}
                    />
                )}
                {isLoading && <Spinner className="mt-1" />}
                {!isLoading && !users.length && (
                    <div className="d-flex justify-content-center form-text mt-0">
                        {activeTab === ProfilePageFollowsTab.FOLLOWERS
                            ? t("profilePage.noFollowers")
                            : t("profilePage.notFollowingAnyone")}
                    </div>
                )}
            </div>
        </>
    );
};

export default ProfilePageFollows;
