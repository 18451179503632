import classes from "./ModalOverlay.module.scss";
import { ComponentProps, useContext, useEffect } from "react";
import ModalContext from "../../../contexts/modal-context";

interface Props extends ComponentProps<any> {}

const ModalOverlay = ({ children }: Props) => {
    const { closeModal } = useContext(ModalContext);

    useEffect(() => {
        const keyDownHandler = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                event.preventDefault();
                closeModal();
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => document.removeEventListener("keydown", keyDownHandler);
    }, []);

    return (
        <div className={`modal show d-block ${classes.modal}`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default ModalOverlay;
