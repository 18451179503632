import React, { useContext } from "react";
import TopUpBalanceForm from "./TopUpBalanceForm/TopUpBalanceForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../../contexts/auth-context";
import { FALLBACK_LOCALE, LOCALE } from "../../../../../constants";
import { StripeElementLocale } from "@stripe/stripe-js/types/stripe-js/elements-group";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PK!, {
    locale: (localStorage.getItem(LOCALE) ||
        FALLBACK_LOCALE) as StripeElementLocale,
});

const BalanceSettings = () => {
    const { user } = useContext(AuthContext);
    const { t } = useTranslation();
    return (
        <div>
            <h6>
                {t("settingsPage.accountSettings.balanceSettings.topUpBalance")}
            </h6>
            <hr />
            <div className="d-flex flex-column gap-3 justify-content-between">
                <div className="d-flex gap-2">
                    <span className="fs-6">
                        {t(
                            "settingsPage.accountSettings.balanceSettings.balance"
                        )}
                        :
                    </span>{" "}
                    <span className="text-primary">
                        {user?.account!.payment_settings.currency.toUpperCase()}{" "}
                        {(
                            user!.account!.payment_settings.balance / 100
                        ).toFixed(2)}
                    </span>
                </div>
                <Elements stripe={stripe}>
                    <TopUpBalanceForm />
                </Elements>
            </div>
        </div>
    );
};

export default BalanceSettings;
