import React, { ButtonHTMLAttributes, ForwardedRef } from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean;
    onlySpinner?: boolean;
}

const Button = React.forwardRef(
    (
        { isLoading, onlySpinner, disabled, children, ...rest }: Props,
        ref: ForwardedRef<HTMLButtonElement>
    ) => {
        let content = (
            <>
                {isLoading && (
                    <span className="spinner-border spinner-border-sm me-3" />
                )}
                {children}
            </>
        );

        if (onlySpinner)
            content = (
                <>
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm mx-3" />
                    )}
                    {!isLoading && children}
                </>
            );

        return (
            <button disabled={isLoading || disabled} ref={ref} {...rest}>
                {content}
            </button>
        );
    }
);

export default Button;
