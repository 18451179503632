class PaymentSettings {
    readonly balance: number;
    readonly currency: string;
    readonly is_stripe_account_set_up: boolean;
    readonly can_accept_pledges: boolean;
    readonly accepts_pledges: boolean;
    readonly stripe_payouts_enabled: boolean;
    readonly accepting_pledges_enabled: boolean;
    readonly payout_country_code: string;

    constructor(data: any) {
        this.balance = data.balance;
        this.currency = data.currency;
        this.is_stripe_account_set_up = data.is_stripe_account_set_up;
        this.can_accept_pledges = data.can_accept_pledges;
        this.accepts_pledges = data.accepts_pledges;
        this.stripe_payouts_enabled = data.stripe_payouts_enabled;
        this.accepting_pledges_enabled = data.accepting_pledges_enabled;
        this.payout_country_code = data.payout_country_code;
    }
}

export default PaymentSettings;
