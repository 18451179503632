import React, { useContext } from "react";
import ProfileImageForm from "./ProfileImageForm/ProfileImageForm";
import ProfileInfoForm from "./ProfileInfoForm/ProfileInfoForm";
import InvitationLinkForm from "./InvitationLinkForm/InvitationLinkForm";
import AuthContext from "../../../../contexts/auth-context";
import { SIGNUP_BY_INVITE_ONLY } from "../../../../constants";
import ProfileAboutForm from "./ProfileAboutForm/ProfileAboutForm";

const ProfileSettingsPage = () => {
    const { user } = useContext(AuthContext);

    return (
        <div className="d-flex flex-column gap-3">
            <div className="border rounded-3 p-3">
                <ProfileImageForm />
            </div>
            <div className="border rounded-3 p-3">
                <ProfileInfoForm />
            </div>
            {user?.is_creator && (
                <>
                    <div className="border rounded-3 p-3">
                        <ProfileAboutForm />
                    </div>
                    <div className="border rounded-3 p-3">
                        <InvitationLinkForm />
                    </div>
                </>
            )}
        </div>
    );
};

export default ProfileSettingsPage;
