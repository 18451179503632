import React, { ForwardedRef } from "react";
import PetitionEndResultRater from "../../../../../../models/PetitionEndResultRater";
import UserBasicInfo from "../../../../../UserBasicInfo/UserBasicInfo";
import { Link } from "react-router-dom";
import classes from "./PetitionEndResultRatersListItem.module.scss";
import { MdStarBorder } from "react-icons/md";

type Props = {
    rater: PetitionEndResultRater;
};

const PetitionEndResultRatersListItem = React.forwardRef(
    ({ rater }: Props, ref: ForwardedRef<HTMLAnchorElement>) => {
        return (
            <Link
                key={rater.user.uuid}
                to={`/users/${rater.user.username}`}
                className={`${classes.user} d-block text-decoration-none text-dark rounded`}
                ref={ref}
            >
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-4 px-2 py-3 border rounded">
                    <UserBasicInfo user={rater.user} />
                    {!!rater.comment && (
                        <span className="form-text">{rater.comment}</span>
                    )}
                    <div className="flex-fill">
                        <span className="btn btn-warning disabled float-end">
                            <MdStarBorder /> {rater.rate}
                        </span>
                    </div>
                </div>
            </Link>
        );
    }
);

export default PetitionEndResultRatersListItem;
