import { Navigate, Outlet, useMatch } from "react-router-dom";
import React, { useContext } from "react";
import Footer from "../../components/Footer/Footer";
import authContext from "../../contexts/auth-context";

export enum HelpPageTab {
    HOW_TO_SETUP_CREATOR_ACCOUNT = "how-to-set-up-creator-account",
}

const HelpPage = () => {
    const { isLoggedIn } = useContext(authContext);

    const match = useMatch("/help/:tab");
    if (!match)
        return (
            <Navigate
                to={`/help/${HelpPageTab.HOW_TO_SETUP_CREATOR_ACCOUNT}`}
            />
        );

    return (
        <div className="container">
            <div className="py-4">
                <Outlet />
            </div>
            {!isLoggedIn && <Footer />}
        </div>
    );
};

export default HelpPage;
