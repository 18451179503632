import axios from "../axiosConfig";
import User from "../../models/User";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export const getUser = async (uuid_or_username?: string): Promise<User> => {
    const endpoint = uuid_or_username ? uuid_or_username : "self";
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/users/${endpoint}`, {
            headers: {
                Authorization: token ? `Token ${token}` : "",
                "Accept-Language":
                    localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
            },
        });
        return new User(response.data);
    } catch (error: any) {
        throw error.response.data;
    }
};
