import User from "../../models/User";
import UsersListItem from "./UsersListItem/UsersListItem";
import { Link } from "react-router-dom";
import classes from "./UsersList.module.scss";
import { ComponentProps, Ref, useCallback, useRef } from "react";
import YoutubeCreator from "../../models/YoutubeCreator";

interface Props extends ComponentProps<any> {
    users: (User | YoutubeCreator)[];
    itemClickHandler?: (user: User | YoutubeCreator) => void;
    showFollowButton?: boolean;
    showVerifiedMark?: boolean;
    getUsers?: () => void;
    isLoading?: boolean;
}

const UsersList = ({
    users,
    getUsers,
    isLoading,
    itemClickHandler,
    showFollowButton = true,
    showVerifiedMark = true,
    className,
}: Props) => {
    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
        (node: Element) => {
            if (!getUsers || isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => {
                    if (entries[0].isIntersecting) {
                        getUsers();
                    }
                }
            );
            if (node) observer.current.observe(node);
        },
        [isLoading, getUsers]
    );

    const renderedUsers = users.map(
        (user: User | YoutubeCreator, index: number) => {
            const item = (
                <UsersListItem
                    user={user}
                    showFollowButton={showFollowButton}
                    showVerifiedMark={showVerifiedMark}
                />
            );

            if (itemClickHandler) {
                return (
                    <div
                        key={
                            user instanceof YoutubeCreator
                                ? user.channel_id
                                : user.uuid
                        }
                        className={`${classes.user} rounded`}
                        onClick={() => itemClickHandler(user)}
                        ref={
                            users.length === index + 1
                                ? (lastElementRef as Ref<HTMLDivElement>)
                                : undefined
                        }
                    >
                        {item}
                    </div>
                );
            } else {
                return (
                    <Link
                        key={(user as User).uuid}
                        to={`/users/${(user as User).username}`}
                        className={`${classes.user} d-block text-decoration-none text-dark rounded`}
                        ref={
                            users.length === index + 1
                                ? (lastElementRef as Ref<HTMLAnchorElement>)
                                : undefined
                        }
                    >
                        {item}
                    </Link>
                );
            }
        }
    );
    return (
        <div className={`${className} d-flex flex-column gap-1`}>
            {renderedUsers}
        </div>
    );
};

export default UsersList;
