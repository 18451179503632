import React from "react";
import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import RatePetitionEndResultForm from "./RatePetitionEndResultForm/RatePetitionEndResultForm";
import Petition from "../../../../models/Petition";
import { useTranslation } from "react-i18next";

type Props = {
    petition: Petition;
    onRate: (petition: Petition) => void;
};

const RatePetitionEndResultOverlay = ({ petition, onRate }: Props) => {
    const { t } = useTranslation();
    return (
        <ModalOverlay>
            <h6>{t("modal.overlays.rateEndResult.rateEndResult")}</h6>
            <hr />
            <RatePetitionEndResultForm petition={petition} onRate={onRate} />
        </ModalOverlay>
    );
};

export default RatePetitionEndResultOverlay;
