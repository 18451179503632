import React, { useContext, useState } from "react";
import Button from "../../../UI/Button/Button";
import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import ModalContext from "../../../../contexts/modal-context";
import { getEmailVerificationCode as api_getEmailVerificationCode } from "../../../../api/users/getEmailVerificationCode";
import AlertOverlay from "../AlertOverlay/AlertOverlay";
import { useTranslation } from "react-i18next";

type Props = {
    message: string;
};

const EmailNotVerifiedErrorOverlay = ({ message }: Props) => {
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);

    const getEmailVerificationCode = async () => {
        setIsLoading(true);
        try {
            const response = await api_getEmailVerificationCode();
            showModal(
                <AlertOverlay status={"success"} message={response.message} />
            );
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    return (
        <ModalOverlay>
            <h6 className={`text-capitalize text-danger`}>
                {t("modal.error")}
            </h6>
            <hr />
            <p>{message}</p>
            <hr />
            <div className="d-flex justify-content-end gap-2">
                <Button
                    className="btn btn-sm btn-primary"
                    onClick={() => getEmailVerificationCode()}
                    isLoading={isLoading}
                >
                    {t(
                        "modal.overlays.emailNotVerifiedError.emailVerificationCode"
                    )}
                </Button>
                <Button className="btn btn-sm btn-dark" onClick={closeModal}>
                    {t("modal.close")}
                </Button>
            </div>
        </ModalOverlay>
    );
};

export default EmailNotVerifiedErrorOverlay;
