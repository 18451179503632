import PaymentMethodsListItem from "./PaymentMethodsListItem/PaymentMethodsListItem";
import PaymentMethod from "../../../../../models/PaymentMethod";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
    paymentMethods: PaymentMethod[];
    setPaymentMethodAsDefault: (id: string) => void;
    deletePaymentMethod: (id: string) => void;
};

const PaymentMethodsList = ({
    paymentMethods,
    setPaymentMethodAsDefault,
    deletePaymentMethod,
}: Props) => {
    const { t } = useTranslation();
    const renderPaymentMethodsList = paymentMethods.map(
        (item: PaymentMethod) => (
            <PaymentMethodsListItem
                key={item.id}
                paymentMethod={item}
                setAsDefault={setPaymentMethodAsDefault}
                deletePaymentMethod={deletePaymentMethod}
            />
        )
    );

    return (
        <div>
            <h6>
                {t(
                    "settingsPage.paymentMethodsSettings.paymentMethodsList.paymentMethods"
                )}
            </h6>
            <hr />
            <div className="d-flex flex-column gap-1">
                {renderPaymentMethodsList.length > 0 ? (
                    renderPaymentMethodsList
                ) : (
                    <span className="form-text">
                        {t(
                            "settingsPage.paymentMethodsSettings.paymentMethodsList.noPaymentMethods"
                        )}
                    </span>
                )}
            </div>
        </div>
    );
};

export default PaymentMethodsList;
