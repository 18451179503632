import React from "react";
import PaymentsList from "./PaymentsList/PaymentsList";

const BillingSettingsPage = () => {
    return (
        <div className="d-flex flex-column gap-3">
            <div className="border rounded-3 p-3">
                <PaymentsList />
            </div>
        </div>
    );
};

export default BillingSettingsPage;
