import React, { useContext, useState } from "react";
import ModalContext from "../../../../../contexts/modal-context";
import {
    ApiErrorCode,
    MAX_PETITION_END_RESULT_RATE_COMMENT_LENGTH,
} from "../../../../../constants";
import {
    FailureResponse,
    ratePetitionEndResult as api_ratePetitionEndResult,
} from "../../../../../api/petitions/ratePetitionEndResult";
import EmailNotVerifiedErrorOverlay from "../../EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AlertOverlay from "../../AlertOverlay/AlertOverlay";
import Button from "../../../../UI/Button/Button";
import Petition from "../../../../../models/Petition";
import Textarea from "../../../../UI/Textarea/Textarea";
import Select from "../../../../UI/Select/Select";
import { useTranslation } from "react-i18next";

type Props = {
    petition: Petition;
    onRate: (petition: Petition) => void;
};

const RatePetitionEndResultForm = ({ petition, onRate }: Props) => {
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const [rate, setRate] = useState<number>(NaN);
    const [comment, setComment] = useState<string>();
    const [rateSelectErrorMsg, setRateSelectErrorMsg] = useState("");
    const [commentTextareaErrorMsg, setCommentTextareaErrorMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const rateChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const val = parseInt(event.currentTarget.value);
        setRate(val);
        setRateSelectErrorMsg("");
    };

    const commentChangeHandler = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setComment(
            event.currentTarget.value.length > 0
                ? event.currentTarget.value
                : undefined
        );
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();

        if (isNaN(rate)) {
            setRateSelectErrorMsg(
                t("modal.overlays.rateEndResult.rateEndResult")
            );
            return;
        }

        setIsLoading(true);
        try {
            const response = await api_ratePetitionEndResult(
                petition.uuid,
                rate!,
                comment
            );
            onRate(response);
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (errors.rate) setRateSelectErrorMsg(errors.rate[0]);
                    if (errors.comment)
                        setCommentTextareaErrorMsg(errors.comment[0]);
                    if (errors.non_field_errors)
                        setErrorMsg(errors.non_field_errors[0]);
                    if (errors.error)
                        setErrorMsg(
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error
                        );
                }
            }
        }
        setIsLoading(false);
    };

    return (
        <form onSubmit={submitHandler}>
            <div className="mb-3 row">
                <Select
                    className="form-select form-select-sm"
                    errorMsg={rateSelectErrorMsg}
                    onChange={rateChangeHandler}
                    value={rate}
                >
                    <option selected>Select rate value</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                </Select>
            </div>
            <div className="mb-3 row">
                <Textarea
                    className="form-control-sm"
                    rows={5}
                    errorMsg={commentTextareaErrorMsg}
                    placeholder={t(
                        "modal.overlays.rateEndResult.rateEndResult"
                    )}
                    onChange={commentChangeHandler}
                    value={comment}
                    maxLength={MAX_PETITION_END_RESULT_RATE_COMMENT_LENGTH}
                />
                <div className="form-text d-flex justify-content-end">
                    {MAX_PETITION_END_RESULT_RATE_COMMENT_LENGTH -
                        (comment?.length || 0)}
                </div>
            </div>
            {errorMsg && (
                <div className="form-text text-danger mt-0">{errorMsg}</div>
            )}
            <hr />
            <div className="d-flex justify-content-end gap-1">
                <Button
                    className="btn btn-sm btn-primary"
                    type="submit"
                    disabled={isLoading}
                    isLoading={isLoading}
                >
                    {t("modal.overlays.rateEndResult.rate")}
                </Button>
                <Button
                    className="btn btn-sm btn-dark"
                    type="button"
                    onClick={closeModal}
                >
                    {t("modal.close")}
                </Button>
            </div>
        </form>
    );
};

export default RatePetitionEndResultForm;
