import PaymentMethodSetupForm from "./PaymentMethodSetupForm/PaymentMethodSetupForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import React, { useContext } from "react";
import { FALLBACK_LOCALE, LOCALE } from "../../../../../constants";
import { useTranslation } from "react-i18next";
import { StripeElementLocale } from "@stripe/stripe-js/types/stripe-js/elements-group";
import { Link } from "react-router-dom";
import AuthContext from "../../../../../contexts/auth-context";

type Props = {
    setPaymentMethodAsDefault: (id: string) => void;
};

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PK!, {
    locale: (localStorage.getItem(LOCALE) ||
        FALLBACK_LOCALE) as StripeElementLocale,
});

const AddNewPaymentMethod = ({ setPaymentMethodAsDefault }: Props) => {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);

    let content = <></>;
    if (user?.account) {
        if (user.account.payment_settings.currency) {
            const options: StripeElementsOptions = {
                mode: "setup",
                currency: user.account.payment_settings.currency,
                setupFutureUsage: "on_session",
                locale: (localStorage.getItem(LOCALE) ||
                    FALLBACK_LOCALE) as StripeElementLocale,
            };

            content = (
                <Elements stripe={stripe} options={options}>
                    <PaymentMethodSetupForm
                        setPaymentMethodAsDefault={setPaymentMethodAsDefault}
                    />
                </Elements>
            );
        } else {
            content = (
                <div className="d-flex gap-3 justify-content-between">
                    <div>{t("petition.pleaseSetCurrency")}</div>
                    <Link
                        to="/settings/account"
                        className="btn btn-sm btn-primary"
                    >
                        {t("petition.setCurrency")}
                    </Link>
                </div>
            );
        }
    }

    return (
        <>
            <h6>
                {t(
                    "settingsPage.paymentMethodsSettings.addNewPaymentMethod.addNewPaymentMethod"
                )}
            </h6>
            <hr />
            {content}
        </>
    );
};

export default AddNewPaymentMethod;
