import React from "react";
import Button from "../UI/Button/Button";

interface Props {
    id: string;
    title: string;
    errorMsg: string;
    submitButtonText: string;
    onSubmit: (event: React.FormEvent) => void;
    isLoading: boolean;
    children: React.ReactNode;
}

const AuthForm = ({
    id,
    title,
    errorMsg,
    submitButtonText,
    onSubmit,
    isLoading,
    children,
}: Props) => {
    return (
        <form
            id={id}
            className="d-flex flex-column gap-3 align-items-center"
            onSubmit={onSubmit}
        >
            <h3 className={errorMsg && "mb-0"}>{title}</h3>
            {errorMsg && (
                <div className="form-text text-danger mt-0">{errorMsg}</div>
            )}
            {children}
            <Button
                className="btn btn-primary w-100"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
            >
                {submitButtonText}
            </Button>
        </form>
    );
};

export default AuthForm;
