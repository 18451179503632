import React from "react";
import UserBasicInfo from "../../UserBasicInfo/UserBasicInfo";
import PetitionSupporter from "../../../models/PetitionSupporter";

type Props = {
    supporter: PetitionSupporter;
    showPledgeAmount: boolean;
};

const PetitionSupportersListItem = ({
    supporter,
    showPledgeAmount = false,
}: Props) => {
    return (
        <div className="d-flex align-items-center justify-content-between px-2 py-3 border rounded">
            <UserBasicInfo user={supporter.user} />
            {showPledgeAmount && (
                <span className="form-text text-dark">
                    {supporter.currency.toUpperCase()}{" "}
                    {(supporter.pledge_amount / 100).toFixed(2)}
                </span>
            )}
        </div>
    );
};

export default PetitionSupportersListItem;
