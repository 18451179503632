import axios from "../axiosConfig";
import YoutubeCreator from "../../models/YoutubeCreator";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export type SuccessResponse = {
    results: YoutubeCreator[];
    next: string;
    previous: string;
};

export const listYoutubeCreators = async (
    q: string
): Promise<SuccessResponse> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/users/ytcreators`, {
            headers: {
                Authorization: token ? `Token ${token}` : "",
                "Accept-Language":
                    localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
            },
            params: {
                q,
            },
        });
        response.data.results = response.data.results.map(
            (item: YoutubeCreator) => new YoutubeCreator(item)
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
