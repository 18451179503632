import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export const getNewNotificationsCount = async (): Promise<number> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/notifications/new-count`, {
            headers: {
                Authorization: token ? `Token ${token}` : "",
                "Accept-Language":
                    localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
            },
        });
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
