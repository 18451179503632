import React, { ChangeEvent, useState } from "react";
import AuthForm from "../../../components/AuthForm/AuthForm";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import { Link, useSearchParams } from "react-router-dom";
import {
    FailureResponse,
    resetPassword,
} from "../../../api/users/resetPassword";
import { useTranslation } from "react-i18next";

const ResetPasswordForm = () => {
    const { t } = useTranslation();
    let [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordInputErrorMsg, setPasswordInputErrorMsg] = useState("");
    const [confirmPasswordInputErrorMsg, setConfirmPasswordInputErrorMsg] =
        useState("");
    const [formErrorMsg, setFormErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const code = searchParams.get("code");

    const isPasswordValid = () => {
        if (!password) {
            setPasswordInputErrorMsg(t("auth.pleaseEnterPassword"));
            return false;
        }
        return true;
    };

    const isConfirmPasswordValid = () => {
        if (!confirmPassword) {
            setConfirmPasswordInputErrorMsg(t("auth.pleaseConfirmPassword"));
            return false;
        }

        if (confirmPassword !== password) {
            setPasswordInputErrorMsg(t("auth.valuesDoNotMatch"));
            setConfirmPasswordInputErrorMsg(t("auth.valuesDoNotMatch"));
            return false;
        }
        return true;
    };

    const passwordInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setPassword(event.currentTarget.value);
        setPasswordInputErrorMsg("");
        setConfirmPasswordInputErrorMsg("");
        setFormErrorMsg("");
    };

    const confirmPasswordInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setConfirmPassword(event.currentTarget.value);
        setPasswordInputErrorMsg("");
        setConfirmPasswordInputErrorMsg("");
        setFormErrorMsg("");
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (isPasswordValid() && isConfirmPasswordValid()) {
            setIsLoading(true);
            try {
                const response = await resetPassword(password, code!);
                setSuccessMsg(response.message);
            } catch (error) {
                const { errors } = error as FailureResponse;

                if (errors) {
                    if (errors.password)
                        setPasswordInputErrorMsg(errors.password[0]);
                    if (errors.code) setFormErrorMsg(errors.code[0]);
                    if (errors.error)
                        setFormErrorMsg(
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error
                        );
                }
            }
            setIsLoading(false);
        }
    };

    return (
        <>
            {!successMsg && (
                <AuthForm
                    id="reset-password-form"
                    title={t("auth.resetPassword")}
                    errorMsg={formErrorMsg}
                    submitButtonText={t("auth.resetPassword")}
                    onSubmit={submitHandler}
                    isLoading={isLoading}
                >
                    <InputGroup
                        type="password"
                        placeholder={t("auth.newPassword")}
                        errorMsg={passwordInputErrorMsg}
                        onChange={passwordInputChangeHandler}
                        value={password}
                    />
                    <InputGroup
                        type="password"
                        placeholder={t("auth.confirmPassword")}
                        errorMsg={confirmPasswordInputErrorMsg}
                        onChange={confirmPasswordInputChangeHandler}
                        value={confirmPassword}
                    />
                </AuthForm>
            )}
            {successMsg && (
                <>
                    <div className="alert alert-success w-100" role="alert">
                        {successMsg}
                    </div>
                    <div className="d-flex flex-column align-items-center mt-2 gap-3">
                        <div className="form-text mt-3">
                            <Link to="/login" className="text-decoration-none">
                                {t("auth.login")}
                            </Link>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ResetPasswordForm;
