import { createContext, useCallback, useState } from "react";
import { login as api_login } from "../api/users/login";
import { loginWithGoogle as api_loginWithGoogle } from "../api/users/login";
import { signup as api_signup } from "../api/users/signup";
import { logout as api_logout } from "../api/users/logout";
import { getUser as api_getUser } from "../api/users/getUser";
import User from "../models/User";
import { googleLogout } from "@react-oauth/google";

const defaultState = {
    token: "",
    user: null,
    isLoggedIn: false,
    navbarHidden: false,
    hideNavbar: (val: boolean) => {},
    login: (email: string, password: string) => {},
    loginWithGoogle: (googleJWT: string, invitedBy: string | null) => {},
    signup: (
        name: string,
        username: string,
        email: string,
        password: string,
        invitedBy: string | null
    ) => {},
    logout: () => {},
    storeUser: (user: User) => {},
    refreshUser: () => {},
};

const AuthContext = createContext<{
    token: string | null;
    user: User | null;
    isLoggedIn: boolean;
    navbarHidden: boolean;
    hideNavbar: (val: boolean) => void;
    login: (email: string, password: string) => void;
    loginWithGoogle: (googleJWT: string, invitedBy: string | null) => void;
    signup: (
        name: string,
        username: string,
        email: string,
        password: string,
        invitedBy: string | null
    ) => void;
    logout: () => void;
    storeUser: (user: User) => void;
    refreshUser: () => void;
}>(defaultState);

export const AuthContextProvider = ({
    children,
}: {
    children: JSX.Element;
}) => {
    const initialToken = localStorage.getItem("token");
    let initialUser: User | null = null;
    if (localStorage.getItem("user"))
        initialUser = new User(JSON.parse(localStorage.getItem("user")!));
    const [token, setToken] = useState(initialToken);
    const [user, setUser] = useState<User | null>(initialUser);
    const [navbarHidden, setNavbarHidden] = useState(false);

    const isLoggedIn = !!token;

    const login = async (email: string, password: string) => {
        const response = await api_login(email, password);
        setToken(response.key);
        localStorage.setItem("token", response.key);
        setUser(response.user);
        localStorage.setItem("user", JSON.stringify(response.user));
    };

    const loginWithGoogle = async (
        googleJWT: string,
        invitedBy: string | null
    ) => {
        const response = await api_loginWithGoogle(googleJWT, invitedBy);
        setToken(response.key);
        localStorage.setItem("token", response.key);
        setUser(response.user);
        localStorage.setItem("user", JSON.stringify(response.user));
    };

    const signup = async (
        name: string,
        username: string,
        email: string,
        password: string,
        invitedBy: string | null
    ) => {
        const response = await api_signup(
            name,
            username,
            email,
            password,
            invitedBy
        );
        setToken(response.key);
        localStorage.setItem("token", response.key);
        setUser(response.user);
        localStorage.setItem("user", JSON.stringify(response.user));
    };

    const logout = async () => {
        try {
            await api_logout();
        } catch (error) {
            console.log(error);
        }
        setToken(null);
        localStorage.removeItem("token");
        setUser(null);
        localStorage.removeItem("user");
        localStorage.removeItem("referrer");
        localStorage.removeItem("invitedBy");
        googleLogout();
    };

    const storeUser = useCallback((usr: User) => {
        if (usr.is_myself) {
            setUser(usr);
            localStorage.setItem("user", JSON.stringify(usr));
        }
    }, []);

    const refreshUser = useCallback(async () => {
        const response = await api_getUser();
        storeUser(response);
    }, [storeUser]);

    const hideNavbar = (val: boolean) => {
        setNavbarHidden(val);
    };

    const contextValue = {
        token,
        user,
        isLoggedIn,
        navbarHidden,
        hideNavbar,
        login,
        loginWithGoogle,
        signup,
        logout,
        storeUser,
        refreshUser,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
