import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type SuccessResponse = {
    message: string;
};

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        password?: string[];
        code?: string[];
        error?: string | string[];
    };
};

export const resetPassword = async (
    password: string,
    code: string
): Promise<SuccessResponse> => {
    try {
        const response = await axios.put(
            "/users/reset-password",
            {
                password,
                code,
            },
            {
                headers: {
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
