import React, { useContext, useState } from "react";
import Button from "../../../../../components/UI/Button/Button";
import ModalContext from "../../../../../contexts/modal-context";
import {
    FailureResponse,
    requestBecomeCreator as api_requestBecomeCreator,
} from "../../../../../api/admins/requestBecomeCreator";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import { ApiErrorCode } from "../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import InputGroup from "../../../../../components/UI/InputGroup/InputGroup";
import { useTranslation } from "react-i18next";
import FindUserOverlay from "../../../../../components/Modal/overlays/FindUserOverlay/FindUserOverlay";
import User from "../../../../../models/User";
import YoutubeCreator from "../../../../../models/YoutubeCreator";
import { AiOutlineDelete } from "react-icons/ai";
import UserBasicInfo from "../../../../../components/UserBasicInfo/UserBasicInfo";

const BecomeCreatorSettings = () => {
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    useState("");
    const [inputErrorMsg, setInputErrorMsg] = useState("");
    const [youtubeCreator, setYoutubeCreator] = useState<YoutubeCreator>();

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!youtubeCreator) return;

        setIsLoading(true);
        try {
            const response = await api_requestBecomeCreator(
                youtubeCreator.channel_id
            );
            showModal(
                <AlertOverlay status={"success"} message={response.message} />
            );
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else {
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    }
                } else if (errors.youtube_channel_id) {
                    showModal(
                        <AlertOverlay
                            status="error"
                            message={errors.youtube_channel_id[0]}
                        />
                    );
                } else if (
                    errors.non_field_errors &&
                    errors.non_field_errors.length > 0
                ) {
                    showModal(
                        <AlertOverlay
                            status="error"
                            message={errors.non_field_errors[0]}
                        />
                    );
                } else if (errors.error) {
                    showModal(
                        <AlertOverlay
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
                } else console.log(errors);
            }
        }
        setIsLoading(false);
    };

    const selectCreatorHandler = (creator: YoutubeCreator) => {
        closeModal();
        setYoutubeCreator(creator);
        setInputErrorMsg("");
    };

    const inputFocusHandler = () => {
        showModal(
            <FindUserOverlay
                selectUserHandler={(user: User | YoutubeCreator) =>
                    selectCreatorHandler(user as YoutubeCreator)
                }
                creatorsOnly={true}
                youtubeCreators={true}
            />
        );
    };

    const removeYoutubeCreatorHandler = () => {
        setYoutubeCreator(undefined);
        setInputErrorMsg("");
    };

    const content = youtubeCreator ? (
        <div className="d-flex justify-content-between align-items-center">
            <UserBasicInfo user={youtubeCreator} />
            <div className="d-flex gap-2">
                <Button
                    className="btn btn-sm btn-outline-danger"
                    onClick={removeYoutubeCreatorHandler}
                    title={t(
                        "settingsPage.accountSettings.becomeCreatorSettings.removeChannel"
                    )}
                >
                    <AiOutlineDelete className="my-1" />
                </Button>
                <Button
                    className="btn btn-sm btn-dark"
                    onClick={submitHandler}
                    isLoading={isLoading}
                    onlySpinner={true}
                >
                    {t(
                        "settingsPage.accountSettings.becomeCreatorSettings.request"
                    )}
                </Button>
            </div>
        </div>
    ) : (
        <InputGroup
            className="input-group-sm"
            errorMsg={inputErrorMsg}
            type="text"
            placeholder={t("searchUserForm.findYourYouTubeChannel")}
            onFocus={inputFocusHandler}
        />
    );

    return (
        <div>
            <h6>
                {t(
                    "settingsPage.accountSettings.becomeCreatorSettings.requestToBecomeCreator"
                )}
            </h6>
            <hr />
            {content}
        </div>
    );
};

export default BecomeCreatorSettings;
