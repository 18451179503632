import User from "./User";

export enum PetitionAddresseeStatus {
    PENDING = "pending",
    ACCEPTED = "accepted",
    REJECTED = "rejected",
}

class PetitionAddressee {
    readonly uuid: string;
    readonly user: User;
    readonly status: PetitionAddresseeStatus;
    readonly rejection_reason: string;
    readonly dt_created: string;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.user = new User(data.user);
        this.status = data.status;
        this.rejection_reason = data.rejection_reason;
        this.dt_created = data.dt_created;
    }
}

export default PetitionAddressee;
