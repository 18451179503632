import User from "./User";

export default class PetitionEndResultRater {
    readonly uuid: string;
    readonly user: User;
    readonly rate: number;
    readonly comment: string;
    readonly dt_created: string;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.user = new User(data.user);
        this.rate = data.rate;
        this.comment = data.comment;
        this.dt_created = data.dt_created;
    }
}
