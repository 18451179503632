import React, { ForwardedRef } from "react";
import Payout, { PayoutStatus } from "../../../../../../models/Payout";
import { useTranslation } from "react-i18next";
import { MdOutlineRemoveRedEye } from "react-icons/md";

type Props = {
    payout: Payout;
};

const PayoutsListItem = React.forwardRef(
    ({ payout }: Props, ref: ForwardedRef<HTMLDivElement>) => {
        const { t } = useTranslation();
        let statusBadge = <></>;
        let arrivalDate = (
            <span>
                {t(
                    "settingsPage.payoutSettings.payoutsList.payoutsListItem.arrival"
                )}
                :{" "}
                {new Date(payout.arrival_date).toLocaleString("en-GB", {
                    dateStyle: "medium",
                })}
            </span>
        );
        let viewError = <></>;

        switch (payout.status) {
            case PayoutStatus.PENDING: {
                statusBadge = (
                    <span className="badge bg-warning-subtle text-warning-emphasis">
                        {t(
                            "settingsPage.payoutSettings.payoutsList.payoutsListItem.pending"
                        )}
                    </span>
                );
                break;
            }
            case PayoutStatus.CANCELED:
                statusBadge = (
                    <span className="badge bg-secondary-subtle text-secondary-emphasis">
                        {t(
                            "settingsPage.payoutSettings.payoutsList.payoutsListItem.canceled"
                        )}
                    </span>
                );
                break;
            case PayoutStatus.FAILED:
                statusBadge = (
                    <span className="badge bg-danger-subtle text-danger-emphasis">
                        {t(
                            "settingsPage.payoutSettings.payoutsList.payoutsListItem.failed"
                        )}
                    </span>
                );
                arrivalDate = <></>;
                viewError = (
                    <div className="dropup">
                        <button
                            className="btn btn-sm btn-link text-decoration-none text-danger"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            title={t("petition.pledgedAmount")}
                        >
                            <MdOutlineRemoveRedEye className="mb-1" />{" "}
                            {t(
                                "settingsPage.payoutSettings.payoutsList.payoutsListItem.viewError"
                            )}
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end p-2">
                            <li className="form-text py-1 m-0 d-flex flex-column">
                                <span className="fw-semibold">
                                    {t(
                                        "settingsPage.payoutSettings.payoutsList.payoutsListItem.payoutMethod"
                                    )}
                                    :
                                </span>
                                <span>{payout.destination_bank_name}</span>
                                <span>**** {payout.destination_last4}</span>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li className="form-text py-1 m-0">
                                {payout.failure_message}
                            </li>
                        </ul>
                    </div>
                );
                break;
            case PayoutStatus.IN_TRANSIT:
                statusBadge = (
                    <span className="badge bg-info-subtle text-info-emphasis">
                        {t(
                            "settingsPage.payoutSettings.payoutsList.payoutsListItem.inTransit"
                        )}
                    </span>
                );
                break;
            case PayoutStatus.PAID:
                statusBadge = (
                    <span className="badge bg-success-subtle text-success-emphasis">
                        {t(
                            "settingsPage.payoutSettings.payoutsList.payoutsListItem.paid"
                        )}
                    </span>
                );
                break;
        }

        return (
            <div
                className="d-flex flex-wrap align-items-center justify-content-between border rounded p-2 form-text"
                ref={ref}
            >
                <div className="d-flex flex-wrap gap-3">
                    <div className="d-flex gap-1">
                        <span>{payout.currency.toUpperCase()}</span>
                        <span>{(payout.amount / 100).toFixed(2)}</span>
                    </div>
                    {statusBadge}
                </div>
                <div className="d-flex flex-column align-items-end">
                    <span>
                        {new Date(payout.created).toLocaleString("en-GB", {
                            dateStyle: "medium",
                            timeStyle: "short",
                        })}
                    </span>
                    {arrivalDate}
                    {viewError}
                </div>
            </div>
        );
    }
);

export default PayoutsListItem;
