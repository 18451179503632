import React, { useCallback, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { getUser as api_getUser } from "../../api/users/getUser";
import User from "../../models/User";
import Spinner from "../../components/UI/Spinner/Spinner";
import blank_profile_image from "../../images/blank-profile-picture.svg";
import { MdOutlineVerified } from "react-icons/md";
import SignupForm, { SignUpFormID } from "../AuthPage/SignupForm/SignupForm";
import Footer from "../../components/Footer/Footer";
import classes from "./InviteeWelcomePage.module.scss";
import { Trans, useTranslation } from "react-i18next";
import { parseProfileImageSrc } from "../../utils/helpers";

const InviteeWelcomePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const match = useMatch("/team/:username");
    const username: string = match?.params.username as string;
    localStorage.setItem("invitedBy", username);
    const [invitor, setInvitor] = useState<User | null>();
    const [isLoading, setLoading] = useState(true);

    const getUser = useCallback(async () => {
        setLoading(true);
        setInvitor(null);
        try {
            const response = await api_getUser(username!);
            if (!response.is_creator) navigate("/signup");
            setInvitor(response);
        } catch (error) {
            navigate("/signup");
        }
        setLoading(false);
    }, [username]);

    useEffect(() => {
        getUser();
    }, [getUser]);

    let image_src: string = blank_profile_image;
    if (invitor && invitor.profile_image)
        image_src = parseProfileImageSrc(invitor.profile_image);

    return (
        <>
            <div className={classes.content}>
                {isLoading && <Spinner />}
                {!isLoading && (
                    <div className="container">
                        <div className="row p-3 px-0">
                            <div className="col-12 col-md-6 p-3 d-flex flex-column justify-content-center align-items-center gap-3">
                                {invitor && (
                                    <>
                                        <div className="d-flex align-items-center gap-3">
                                            <div>
                                                <img
                                                    src={image_src}
                                                    alt="profile"
                                                    width="100"
                                                    height="100"
                                                    className="rounded-circle border border-1"
                                                />
                                            </div>
                                            <div className="d-flex gap-1 flex-column">
                                                <div className="d-flex align-items-center gap-1">
                                                    <div className="d-flex flex-wrap gap-1 fs-5 fw-bold">
                                                        <span>
                                                            {invitor.name}
                                                        </span>
                                                    </div>
                                                    {invitor.is_account_verified && (
                                                        <MdOutlineVerified className="text-primary" />
                                                    )}
                                                </div>
                                                <div className="">
                                                    @{invitor.username}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column align-items-center gap-1">
                                            <span className="text-center">
                                                <Trans
                                                    i18nKey="auth.thanksForAcceptingMyInvitation"
                                                    components={[
                                                        <span className="text-primary" />,
                                                    ]}
                                                />
                                            </span>
                                        </div>
                                    </>
                                )}
                                {!invitor && (
                                    <div className="d-flex flex-column align-items-center gap-1">
                                        <span className="text-center">
                                            <Trans
                                                i18nKey="auth.couldNotRecognizeTheInvitationLink"
                                                components={[
                                                    <span className="text-primary" />,
                                                ]}
                                            />
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-6 p-3">
                                <SignupForm
                                    id={SignUpFormID.SIGNUP_FORM_INVITE}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default InviteeWelcomePage;
