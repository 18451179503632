import axios from "../axiosConfig";
import Notification from "../../models/Notification";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export const markNotificationAsSeen = async (
    notification_uuid: string
): Promise<Notification[]> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.patch(
            `/notifications/${notification_uuid}`,
            {
                is_seen: true,
            },
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
