import ProfileLink from "../../ProfileLink/ProfileLink";
import { TbHeartHandshake } from "react-icons/tb";
import { MdStarBorder } from "react-icons/md";
import { BsQuestionCircle } from "react-icons/bs";
import React, { useContext, useState } from "react";
import ModalContext from "../../../contexts/modal-context";
import Petition, { PetitionStatus } from "../../../models/Petition";
import PetitionSupportersListOverlay from "../../Modal/overlays/PetitionSupportersListOverlay/PetitionSupportersListOverlay";
import SupportPetitionOverlay from "../../Modal/overlays/SupportPetitionOverlay/SupportPetitionOverlay";
import { Link, useNavigate } from "react-router-dom";
import Countdown from "../../UI/Countdown/Countdown";
import PetitionAddressee, {
    PetitionAddresseeStatus,
} from "../../../models/PetitionAddressee";
import {
    FailureResponse as UpdatePetitionAddresseeFailureResponse,
    updatePetitionAddressee as api_updatePetitionAddressee,
} from "../../../api/petitions/updatePetitionAddressee";
import {
    FailureResponse as UpdatePetitionFailureResponse,
    updatePetition as api_updatePetition,
} from "../../../api/petitions/updatePetition";
import AlertOverlay from "../../Modal/overlays/AlertOverlay/AlertOverlay";
import PetitionActionButtons from "./PetitionActionButtons/PetitionActionButtons";
import AddPetitionEndResultOverlay from "../../Modal/overlays/AddPetitionEndResultOverlay/AddPetitionEndResultOverlay";
import RatePetitionEndResultOverlay from "../../Modal/overlays/RatePetitionEndResultOverlay/RatePetitionEndResultOverlay";
import PetitionEndResultRatersListOverlay from "../../Modal/overlays/PetitionEndResultRatersListOverlay/PetitionEndResultRatersListOverlay";
import AuthContext from "../../../contexts/auth-context";
import {
    FailureResponse,
    supportPetition as api_supportPetition,
} from "../../../api/petitions/supportPetition";
import { ApiErrorCode } from "../../../constants";
import EmailNotVerifiedErrorOverlay from "../../Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import { useTranslation } from "react-i18next";
import { getTimeRemainingFormatted } from "../../../utils/helpers";
import RejectPetitionOverlay from "../../Modal/overlays/RejectPetitionOverlay/RejectPetitionOverlay";

type Props = {
    petition: Petition;
};

const PetitionsListItem = ({ petition: initialPetition }: Props) => {
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const { user } = useContext(AuthContext);
    const [petition, setPetition] = useState(initialPetition);
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);
    const [markAsFulfilledLoading, setMarkAsFulfilledLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [supportLoading, setSupportLoading] = useState(false);
    const navigate = useNavigate();

    const showSupportersHandler = () => {
        showModal(<PetitionSupportersListOverlay petition={petition} />);
    };

    const supportPetitionWithPledgingHandler = (updatedPetition: Petition) => {
        setPetition(updatedPetition);
        closeModal();
    };

    const supportPetitionWithoutPledging = async () => {
        setSupportLoading(true);
        try {
            const response = await api_supportPetition(petition.uuid, 0);
            setPetition(response);
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (errors.non_field_errors)
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={errors.non_field_errors[0]}
                            />
                        );
                    if (errors.error)
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                }
            }
        }
        setSupportLoading(false);
    };

    const supportPetition = async () => {
        if (user) {
            if (petition.accepts_pledges) {
                if (user.account!.payment_settings.currency)
                    showModal(
                        <SupportPetitionOverlay
                            petition={petition}
                            onSupport={supportPetitionWithPledgingHandler}
                        />
                    );
                else
                    showModal(
                        <AlertOverlay
                            status="error"
                            message={t("petition.pleaseSetCurrency")}
                            link={
                                <Link
                                    to="/settings/account"
                                    className="btn btn-sm btn-primary"
                                >
                                    {t("petition.setCurrency")}
                                </Link>
                            }
                        />
                    );
            } else await supportPetitionWithoutPledging();
        } else {
            localStorage.setItem("referrer", window.location.href);
            navigate("/signup");
            // showModal(
            //     <ModalOverlay>
            //         <div className="my-2">
            //             <SignupForm id={SignUpFormID.SIGNUP_FORM} />
            //         </div>
            //     </ModalOverlay>
            // );
        }
    };

    const showPetitionEndResultRatersHandler = () => {
        showModal(<PetitionEndResultRatersListOverlay petition={petition} />);
    };

    const ratePetitionEndResultHandler = (updatedPetition: Petition) => {
        setPetition(updatedPetition);
        closeModal();
    };

    const ratePetition = () => {
        showModal(
            <RatePetitionEndResultOverlay
                petition={petition}
                onRate={ratePetitionEndResultHandler}
            />
        );
    };

    const updatePetitionAddresseeStatus = async (
        addressee_uuid: string,
        status: PetitionAddresseeStatus,
        rejection_reason?: string
    ) => {
        if (status === PetitionAddresseeStatus.ACCEPTED) setAcceptLoading(true);
        else setRejectLoading(true);
        try {
            const response = await api_updatePetitionAddressee(
                petition.uuid,
                addressee_uuid,
                status,
                rejection_reason
            );
            setPetition(response);
        } catch (error) {
            const { errors, code } =
                error as UpdatePetitionAddresseeFailureResponse;
            if (errors) {
                if (errors.rejection_reason) {
                    showModal(
                        <AlertOverlay
                            status="error"
                            message={errors.rejection_reason[0]}
                        />
                    );
                } else if (code === ApiErrorCode.NO_PAYOUT_METHOD) {
                    showModal(
                        <AlertOverlay
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                            link={
                                <Link
                                    to="/settings/payout"
                                    className="btn btn-sm btn-primary"
                                >
                                    {t(
                                        "settingsPage.payoutSettings.payoutMethodSettings.addPayoutMethod"
                                    )}
                                </Link>
                            }
                        />
                    );
                } else {
                    showModal(
                        <AlertOverlay
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
                }
            }
        }
        setAcceptLoading(false);
        setRejectLoading(false);
    };

    const updatePetitionStatus = async (status: PetitionStatus) => {
        if (status === PetitionStatus.FULFILLED)
            setMarkAsFulfilledLoading(true);
        else setCancelLoading(true);

        try {
            const response = await api_updatePetition(petition.uuid, status);
            setPetition(response);
        } catch (error) {
            const { errors } = error as UpdatePetitionFailureResponse;
            if (errors)
                showModal(
                    <AlertOverlay
                        status="error"
                        message={
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error!
                        }
                    />
                );
        }

        setMarkAsFulfilledLoading(false);
        setCancelLoading(false);
    };

    const statusName: string = t(`petition.statuses.${petition.status}`);
    let statusClass = "";
    switch (petition.status) {
        case PetitionStatus.ONGOING:
            statusClass = "warning";
            break;
        case PetitionStatus.CANCELED:
            statusClass = "light";
            break;
        case PetitionStatus.REJECTED:
            statusClass = "danger";
            break;
        case PetitionStatus.EXPIRED:
            statusClass = "secondary";
            break;
        case PetitionStatus.ACCEPTED:
            statusClass = "info";
            break;
        case PetitionStatus.NOT_FULFILLED:
            statusClass = "dark";
            break;
        case PetitionStatus.FULFILLED:
            statusClass = "success";
            break;
    }

    let usd_pledged_amount = 0;
    for (let item of petition.total_pledged_amount_by_currency) {
        if (item.currency.toUpperCase() === "USD")
            usd_pledged_amount = item.total_amount;
    }

    return (
        <>
            <div
                className={`d-flex flex-column border-top border-start border-end gap-3 
                p-3 rounded-top`}
            >
                <div className="d-flex flex-column border-bottom gap-2 pb-3">
                    <h6 className="card-title">{petition.title}</h6>
                    <p
                        className="card-text form-text"
                        style={{ whiteSpace: "pre-line" }}
                    >
                        {petition.description}
                    </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    {petition.addressees && petition.addressees.length > 0 ? (
                        <ProfileLink user={petition.addressees[0].user} />
                    ) : (
                        <div />
                    )}
                    <div className="d-flex flex-column gap-1 flex-sm-row align-items-center">
                        {petition.end_result &&
                            petition.end_result.average_rate && (
                                <button
                                    className="btn btn-sm btn-link text-decoration-none text-dark"
                                    onClick={showPetitionEndResultRatersHandler}
                                    title={t("petition.buttons.raters")}
                                >
                                    <MdStarBorder className="mb-1" />{" "}
                                    {petition.end_result.average_rate}
                                </button>
                            )}
                        <button
                            className="btn btn-sm btn-link text-decoration-none text-dark"
                            onClick={showSupportersHandler}
                            title={t("petition.buttons.supporters")}
                        >
                            <TbHeartHandshake className="mb-1" />{" "}
                            {petition.supporters_count}
                        </button>
                        {petition.accepts_pledges && (
                            // <div className="dropup">
                            //     <button
                            //         className="btn btn-sm btn-link text-decoration-none text-black"
                            //         data-bs-toggle="dropdown"
                            //         aria-expanded="false"
                            //         title={t("petition.pledgedAmount")}
                            //     >
                            //         <BsCashCoin />
                            //     </button>
                            //     <ul className="dropdown-menu dropdown-menu-end p-2">
                            //         {petition.total_pledged_amount_by_currency.map(
                            //             (item: {
                            //                 currency: string;
                            //                 total_amount: number;
                            //             }) => (
                            //                 <li
                            //                     key={item.currency}
                            //                     className="form-text py-1 m-0"
                            //                 >
                            //                     {item.currency.toUpperCase()}{" "}
                            //                     {(
                            //                         item.total_amount / 100
                            //                     ).toFixed(2)}
                            //                 </li>
                            //             )
                            //         )}
                            //     </ul>
                            // </div>
                            <span className="form-text text-dark m-0">
                                USD {(usd_pledged_amount / 100).toFixed(2)}
                            </span>
                        )}
                    </div>
                </div>
                <div className="d-flex flex-wrap align-items-center justify-content-between border-top pt-3">
                    {/*{petition.dt_next_status_update && (*/}
                    {/*    <Countdown*/}
                    {/*        deadline={new Date(petition.dt_next_status_update)}*/}
                    {/*    />*/}
                    {/*)}*/}
                    <PetitionActionButtons
                        petition={petition}
                        onCancelButtonClick={() =>
                            showModal(
                                <AlertOverlay
                                    status="warning"
                                    message={t("petition.sureWantToCancel")}
                                    onConfirm={() =>
                                        updatePetitionStatus(
                                            PetitionStatus.CANCELED
                                        )
                                    }
                                />
                            )
                        }
                        onSupportButtonClick={supportPetition}
                        onAcceptButtonClick={(addressee_uuid: string) =>
                            showModal(
                                <AlertOverlay
                                    status="warning"
                                    message={t("petition.sureWantToAccept", {
                                        remaining_time:
                                            getTimeRemainingFormatted(
                                                new Date(
                                                    petition.dt_next_status_update
                                                )
                                            ),
                                    })}
                                    onConfirm={() =>
                                        updatePetitionAddresseeStatus(
                                            addressee_uuid,
                                            PetitionAddresseeStatus.ACCEPTED
                                        )
                                    }
                                />
                            )
                        }
                        onRejectButtonClick={(addressee_uuid: string) =>
                            showModal(
                                <RejectPetitionOverlay
                                    onConfirm={(
                                        rejection_reason: string | undefined
                                    ) =>
                                        updatePetitionAddresseeStatus(
                                            addressee_uuid,
                                            PetitionAddresseeStatus.REJECTED,
                                            rejection_reason
                                        )
                                    }
                                />
                            )
                        }
                        onMarkAsFulfilledButtonClick={() =>
                            showModal(
                                <AddPetitionEndResultOverlay
                                    petition={petition}
                                    onPetitionUpdate={setPetition}
                                />
                            )
                        }
                        onRateEndResultButtonClick={ratePetition}
                        acceptLoading={acceptLoading}
                        rejectLoading={rejectLoading}
                        markAsFulfilledLoading={markAsFulfilledLoading}
                        cancelLoading={cancelLoading}
                        supportLoading={supportLoading}
                    />
                </div>
            </div>
            <div
                className={`rounded-bottom bg-${statusClass}-subtle 
                form-text mt-0 py-1 border-start border-end border-bottom`}
            >
                <div
                    className={`d-flex justify-content-center gap-2 text-${statusClass}-emphasis `}
                >
                    {statusName.toUpperCase()}
                    {petition.status === PetitionStatus.REJECTED && (
                        <div className="dropdown-center">
                            <button
                                className="btn nav-link"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <BsQuestionCircle
                                    style={{ marginBottom: "2px" }}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title={t("petition.viewRejectionReason")}
                                />
                            </button>
                            <ul className="dropdown-menu">
                                <h6 className="dropdown-header">
                                    {t("petition.rejectionReason")}:
                                </h6>
                                {petition.addressees.map(
                                    (
                                        item: PetitionAddressee,
                                        index: number
                                    ) => {
                                        return (
                                            <>
                                                <div
                                                    className="form-text dropdown-item-text"
                                                    style={{
                                                        whiteSpace: "pre-line",
                                                    }}
                                                >
                                                    {item.rejection_reason}
                                                </div>
                                                {index <
                                                    petition.addressees.length -
                                                        1 && (
                                                    <hr className="dropdown-divider" />
                                                )}
                                            </>
                                        );
                                    }
                                )}
                            </ul>
                        </div>
                    )}
                </div>
                {petition.dt_next_status_update && (
                    <div
                        className={`d-flex justify-content-center form-text mt-0 text-${statusClass}-emphasis `}
                    >
                        <Countdown
                            deadline={new Date(petition.dt_next_status_update)}
                        />
                        <div className="dropdown-center">
                            <button
                                className="btn nav-link"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <BsQuestionCircle
                                    style={{ marginBottom: "1px" }}
                                />
                            </button>
                            <ul className="dropdown-menu">
                                <span className="form-text dropdown-item-text">
                                    {petition.status === PetitionStatus.ONGOING
                                        ? t(
                                              "petition.timeRemainingToAcceptAndFulfill"
                                          )
                                        : t("petition.timeRemainingToFulfill")}
                                </span>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default PetitionsListItem;
