import React, { Ref, useCallback, useEffect, useRef, useState } from "react";
import PaymentsListItem from "./PaymentsListItem/PaymentsListItem";
import { listPayments as api_listPayments } from "../../../../../api/payments/listPayments";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import Payment from "../../../../../models/Payment";
import { parseCursor } from "../../../../../utils/helpers";
import { useTranslation } from "react-i18next";

const PaymentsList = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getPayments = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                const response = await api_listPayments(cursor);
                if (cursor === undefined) setPayments(response.results);
                else
                    setPayments((prev: Payment[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        []
    );

    useEffect(() => {
        setPayments([]);
        setCursor(undefined);
        setHasMore(true);
        getPayments(undefined, true);
    }, [getPayments]);

    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
        (node: Element) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => {
                    if (entries[0].isIntersecting) {
                        getPayments(cursor, hasMore);
                    }
                }
            );
            if (node) observer.current.observe(node);
        },
        [isLoading, getPayments, cursor, hasMore]
    );

    const renderedPaymentsList = payments.map(
        (item: Payment, index: number) => (
            <PaymentsListItem
                key={item.uuid}
                payment={item}
                ref={
                    payments.length === index + 1
                        ? (lastElementRef as Ref<HTMLDivElement>)
                        : undefined
                }
            />
        )
    );

    return (
        <div>
            <h6>{t("settingsPage.billingSettings.paymentsList.payments")}</h6>
            <hr />
            {!!payments && (
                <div className="d-flex flex-column gap-1">
                    {payments.length > 0 ? (
                        renderedPaymentsList
                    ) : (
                        <span className="form-text">
                            {t(
                                "settingsPage.billingSettings.paymentsList.noPaymentsFound"
                            )}
                        </span>
                    )}
                </div>
            )}
            {isLoading && <Spinner className="mt-3" />}
        </div>
    );
};

export default PaymentsList;
