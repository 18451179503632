import React from "react";
import { Link } from "react-router-dom";
import { BsFillSuitHeartFill } from "react-icons/bs";
import { Trans, useTranslation } from "react-i18next";

type Props = {
    showBorderTop?: boolean;
};

const Footer = ({ showBorderTop = true }: Props) => {
    const { t } = useTranslation();
    return (
        <div className={`py-3 pb-5 ${showBorderTop && "border-top"}`}>
            <div className="d-flex flex-column gap-3 form-text align-items-center pb-xxl-3">
                <div className="d-flex flex-column justify-content-center align-items-center flex-sm-row gap-3">
                    <Link to="/about" className="text-decoration-none">
                        {t("footer.about")}
                    </Link>
                    <Link to="/faq" className="text-decoration-none">
                        {t("footer.faqs")}
                    </Link>
                    <Link to="/terms" className="text-decoration-none">
                        {t("footer.termsAndConditions")}
                    </Link>
                    <Link to="/privacy" className="text-decoration-none">
                        {t("footer.privacyPolicy")}
                    </Link>
                    <Link to="/cookies" className="text-decoration-none">
                        {t("footer.cookiesPolicy")}
                    </Link>
                </div>
                <div className="d-flex flex-column gap-1 align-items-center">
                    <span>Ⓒ 2024 Ablebees Inc.</span>
                    <span className="text-center">
                        {/*<Trans*/}
                        {/*    i18nKey="footer.madeWithLove"*/}
                        {/*    components={[*/}
                        {/*        <BsFillSuitHeartFill className="text-danger" />,*/}
                        {/*    ]}*/}
                        {/*/>*/}
                        548 Market St, PMB 76869, San Francisco, CA 94104-5401,
                        US
                    </span>

                    <span>
                        <a
                            href="mailto: info@ablebees.com"
                            className="text-decoration-none"
                        >
                            info@ablebees.com
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Footer;
