import classes from "./PageContainer.module.scss";
import React, { useContext } from "react";
import AuthContext from "../../contexts/auth-context";
import Navbar from "../Navbar/Navbar";

type Props = {
    children: React.ReactNode;
};

const PageContainer = ({ children }: Props) => {
    const { isLoggedIn, navbarHidden } = useContext(AuthContext);

    let content = (
        <div className={`${classes["page-container"]} position-relative`}>
            <div className="row mx-0">
                <div className="col pt-3 px-0">{children}</div>
            </div>
        </div>
    );

    if (isLoggedIn && !navbarHidden) {
        content = (
            <div
                className={`${classes["page-container"]} container position-relative`}
            >
                <Navbar />
                <div className={`${classes["page-content"]}`}>
                    <div className="row">
                        <div className="col col-lg-9 col-xl-8 col-xxl-7 p-0 m-3">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return content;
};

export default PageContainer;
