import { Link, useMatch } from "react-router-dom";
import { SettingsPageTab } from "../SettingsPage";
import { useContext } from "react";
import authContext from "../../../contexts/auth-context";
import { useTranslation } from "react-i18next";

const SettingsPageNavbar = () => {
    const { t } = useTranslation();
    const { user } = useContext(authContext);
    const match = useMatch("/settings/:tab");
    const activeTab = match?.params.tab;
    return (
        <ul className="nav nav-underline">
            <li className="nav-item">
                <Link
                    className={`nav-link ${
                        activeTab === SettingsPageTab.PROFILE && "active"
                    }`}
                    to={SettingsPageTab.PROFILE}
                >
                    {t("settingsPage.navbar.profile")}
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className={`nav-link ${
                        activeTab === SettingsPageTab.ACCOUNT && "active"
                    }`}
                    to={SettingsPageTab.ACCOUNT}
                >
                    {t("settingsPage.navbar.account")}
                </Link>
            </li>
            <li className="nav-item d-none d-lg-block">
                <Link
                    className={`nav-link ${
                        activeTab === SettingsPageTab.PAYMENT_METHODS &&
                        "active"
                    }`}
                    to={SettingsPageTab.PAYMENT_METHODS}
                >
                    {t("settingsPage.navbar.paymentMethods")}
                </Link>
            </li>
            <li className="nav-item d-none d-lg-block">
                <Link
                    className={`nav-link ${
                        activeTab === SettingsPageTab.BILLING && "active"
                    }`}
                    to={SettingsPageTab.BILLING}
                >
                    {t("settingsPage.navbar.billing")}
                </Link>
            </li>
            {user?.is_creator && (
                <li className="nav-item d-none d-lg-block">
                    <Link
                        className={`nav-link ${
                            activeTab === SettingsPageTab.PAYOUT && "active"
                        }`}
                        to={SettingsPageTab.PAYOUT}
                    >
                        {t("settingsPage.navbar.payout")}
                    </Link>
                </li>
            )}
            {/*<li className="nav-item d-none d-lg-block">*/}
            {/*    <Link*/}
            {/*        className={`nav-link ${*/}
            {/*            activeTab === SettingsPageTab.NOTIFICATIONS && "active"*/}
            {/*        }`}*/}
            {/*        to={SettingsPageTab.NOTIFICATIONS}*/}
            {/*    >*/}
            {/*        Notifications*/}
            {/*    </Link>*/}
            {/*</li>*/}
            <li className="nav-item dropdown d-lg-none">
                <button
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                >
                    {t("settingsPage.navbar.more")}
                </button>
                <ul className="dropdown-menu">
                    <li className="d-lg-none">
                        <Link
                            className="dropdown-item"
                            to={SettingsPageTab.PAYMENT_METHODS}
                        >
                            {t("settingsPage.navbar.paymentMethods")}
                        </Link>
                    </li>
                    <li className="d-lg-none">
                        <Link
                            className="dropdown-item"
                            to={SettingsPageTab.BILLING}
                        >
                            {t("settingsPage.navbar.billing")}
                        </Link>
                    </li>
                    <li className="d-lg-none">
                        <Link
                            className="dropdown-item"
                            to={SettingsPageTab.PAYOUT}
                        >
                            {t("settingsPage.navbar.payout")}
                        </Link>
                    </li>
                    {/*<li className="d-lg-none">*/}
                    {/*    <Link*/}
                    {/*        className="dropdown-item"*/}
                    {/*        to={SettingsPageTab.NOTIFICATIONS}*/}
                    {/*    >*/}
                    {/*        Notifications*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                </ul>
            </li>
        </ul>
    );
};

export default SettingsPageNavbar;
