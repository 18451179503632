import InputGroup from "../../../../../components/UI/InputGroup/InputGroup";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../contexts/auth-context";
import {
    FailureResponse as UpdateUserFailureResponse,
    updateUser as api_updateUser,
} from "../../../../../api/users/updateUser";
import Button from "../../../../../components/UI/Button/Button";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../contexts/modal-context";
import { ApiErrorCode } from "../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import { useTranslation } from "react-i18next";

const ProfileInfoForm = () => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const { user, refreshUser, storeUser } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState(user?.name);
    const [nameInputErrorMsg, setNameInputErrorMsg] = useState("");
    const [username, setUsername] = useState(user?.username);
    const [usernameInputErrorMsg, setUsernameInputErrorMsg] = useState("");

    let saveButtonDisabled = true;

    useEffect(() => {
        refreshUser();
    }, [refreshUser]);

    const checkSaveButtonState = () => {
        if (name !== user?.name || username !== user?.username) {
            saveButtonDisabled = false;
        } else {
            saveButtonDisabled = true;
        }
    };

    checkSaveButtonState();

    const nameInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value !== user?.name)
            saveButtonDisabled = false;
        setName(event.currentTarget.value);
        setNameInputErrorMsg("");
        checkSaveButtonState();
    };

    const usernameInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setUsername(event.currentTarget.value);
        setUsernameInputErrorMsg("");
        checkSaveButtonState();
    };

    const isNameValid = () => {
        if (!name) {
            setNameInputErrorMsg(
                t(
                    "settingsPage.profileSettings.profileInfoForm.pleaseEnterName"
                )
            );
            return false;
        }
        return true;
    };

    const isUsernameValid = () => {
        if (!username) {
            setUsernameInputErrorMsg(
                t(
                    "settingsPage.profileSettings.profileInfoForm.pleaseEnterUsername"
                )
            );
            return false;
        }
        return true;
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (isNameValid() && isUsernameValid()) {
            if (username !== user?.username) {
                showModal(
                    <AlertOverlay
                        status="warning"
                        message={t(
                            "settingsPage.profileSettings.profileInfoForm.invitationLinkWillAlsoBeUpdated"
                        )}
                        onConfirm={updateProfileInfo}
                    />
                );
            } else {
                await updateProfileInfo();
            }
        }
    };

    const updateProfileInfo = async () => {
        try {
            setIsLoading(true);
            const response = await api_updateUser(name, username);
            storeUser(response);
            saveButtonDisabled = true;
        } catch (error) {
            const { errors, status_code, code } =
                error as UpdateUserFailureResponse;

            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (errors.name) setNameInputErrorMsg(errors.name[0]);
                    if (errors.username)
                        setUsernameInputErrorMsg(errors.username[0]);
                }
            }
        }
        setIsLoading(false);
    };

    return (
        <>
            <h6>
                {t(
                    "settingsPage.profileSettings.profileInfoForm.updateBasicInfo"
                )}
            </h6>
            <hr />
            <form onSubmit={submitHandler}>
                <div className="row mb-3">
                    <div className="col">
                        <InputGroup
                            className="input-group-sm"
                            type="text"
                            label={t(
                                "settingsPage.profileSettings.profileInfoForm.name"
                            )}
                            errorMsg={nameInputErrorMsg}
                            onChange={nameInputChangeHandler}
                            value={name}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <InputGroup
                            className="input-group-sm"
                            type="text"
                            label={t(
                                "settingsPage.profileSettings.profileInfoForm.username"
                            )}
                            errorMsg={usernameInputErrorMsg}
                            onChange={usernameInputChangeHandler}
                            value={username}
                        />
                    </div>
                </div>
                <Button
                    className="btn btn-sm btn-primary float-end"
                    type="submit"
                    disabled={isLoading || saveButtonDisabled}
                    isLoading={isLoading}
                >
                    {t("settingsPage.save")}
                </Button>
            </form>
        </>
    );
};

export default ProfileInfoForm;
