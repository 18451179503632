import React from "react";
import { CiBank } from "react-icons/ci";
import { useTranslation } from "react-i18next";

type Props = {
    payoutMethod: {
        account: string;
        account_holder_name: string;
        account_holder_type: string;
        account_type: string;
        available_payout_methods: string[];
        bank_name: string;
        country: string;
        currency: string;
        default_for_currency: boolean;
        fingerprint: string;
        id: string;
        last4: string;
        metadata: any;
        object: string;
        routing_number: string;
        status: string;
    };
};

const PayoutMethodsListItem = ({ payoutMethod }: Props) => {
    const { t } = useTranslation();

    let default_badge = <></>;
    if (payoutMethod.default_for_currency)
        default_badge = (
            <span className="badge bg-secondary">
                {t(
                    "settingsPage.paymentMethodsSettings.paymentMethodsList.paymentMethodsListItem.default"
                )}
            </span>
        );

    return (
        <div className="d-flex flex-wrap align-items-center justify-content-between border rounded px-2 py-1">
            <div className="d-flex gap-3 form-text align-items-center">
                <CiBank className="fs-1" />
                <div className="d-flex flex-column">
                    <div className="d-flex">
                        <span>{payoutMethod.bank_name}</span>
                    </div>
                    <div className="d-flex gap-1 gap-md-2">
                        <span>**** {payoutMethod.last4}</span>
                        <span>{payoutMethod.routing_number}</span>
                        <span>{payoutMethod.currency.toUpperCase()}</span>
                        {default_badge}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayoutMethodsListItem;
