import StartPetitionForm from "../../components/StartPetitionForm/StartPetitionForm";
import PetitionsList from "../../components/PetitionsList/PetitionsList";
import { listPetitions as api_listPetitions } from "../../api/petitions/listPetitions";
import React, { useCallback, useEffect, useState } from "react";
import Petition, { PetitionStatus } from "../../models/Petition";
import User from "../../models/User";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { parseCursor } from "../../utils/helpers";
import YoutubeCreator from "../../models/YoutubeCreator";
import { useTranslation } from "react-i18next";

const StartPetitionPage = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [petitions, setPetitions] = useState<Petition[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);
    const [addressees, setAddressees] = useState<(User | YoutubeCreator)[]>([]);
    const navigate = useNavigate();

    const getPetitions = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (addressees.length === 0 || !hasMore) return;
            setIsLoading(true);
            try {
                const addressee_uuids: string[] = [];
                const addressee_youtube_channel_ids: string[] = [];
                for (let addressee of addressees) {
                    if (addressee instanceof User)
                        addressee_uuids.push(addressee.uuid);
                    else
                        addressee_youtube_channel_ids.push(
                            addressee.channel_id
                        );
                }
                const response = await api_listPetitions(
                    undefined,
                    addressee_uuids,
                    undefined,
                    PetitionStatus.ONGOING,
                    cursor,
                    addressee_youtube_channel_ids
                );
                if (cursor === undefined) setPetitions(response.results);
                else
                    setPetitions((prev: Petition[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [addressees]
    );

    useEffect(() => {
        setPetitions([]);
        setCursor(undefined);
        setHasMore(true);
        getPetitions(undefined, true);
    }, [getPetitions]);

    const createPetitionHandler = (petition: Petition) => {
        localStorage.setItem("firstTimeVisit", petition.uuid);
        navigate(`/petitions/${petition.uuid}`);
    };

    return (
        <div>
            <h6 className="mb-3">{t("navbar.startPetition")}</h6>
            <hr />
            <StartPetitionForm
                onCreatePetition={createPetitionHandler}
                onSelectAddressee={setAddressees}
            />
            {petitions.length > 0 && (
                <>
                    <hr />
                    <PetitionsList
                        petitions={petitions}
                        getPetitions={() => getPetitions(cursor, hasMore)}
                        isLoading={isLoading}
                    />
                </>
            )}
            {isLoading && <Spinner className="mt-3" />}
        </div>
    );
};

export default StartPetitionPage;
