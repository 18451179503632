import User from "./User";

class UserFollow {
    readonly uuid: string;
    readonly dt_created: Date;
    readonly user: User;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.dt_created = data.dt_created;
        this.user = new User(data.user);
    }
}

export default UserFollow;
