import User from "../../../models/User";
import {
    FailureResponse,
    followUser as api_followUser,
} from "../../../api/users/followUser";
import { unfollowUser as api_unfollowUser } from "../../../api/users/unfollowUser";
import React, { MouseEvent, useContext, useState } from "react";
import UserBasicInfo from "../../UserBasicInfo/UserBasicInfo";
import Button from "../../UI/Button/Button";
import AlertOverlay from "../../Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../contexts/modal-context";
import { ApiErrorCode } from "../../../constants";
import EmailNotVerifiedErrorOverlay from "../../Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import YoutubeCreator from "../../../models/YoutubeCreator";
import { useTranslation } from "react-i18next";

type Props = {
    user: User | YoutubeCreator;
    showFollowButton?: boolean;
    showVerifiedMark?: boolean;
};

const UsersListItem = ({
    user,
    showFollowButton = true,
    showVerifiedMark = true,
}: Props) => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const [isFollowing, setIsFollowing] = useState(
        user instanceof User && user.am_i_follower
    );

    const followButtonClickHandler = async (
        event: MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        try {
            if (isFollowing) await api_unfollowUser((user as User).uuid);
            else await api_followUser((user as User).uuid);
            setIsFollowing((prevState: boolean) => !prevState);
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else console.log(errors);
            }
        }
    };

    let followButton = <></>;
    if (showFollowButton && user instanceof User && !user.is_myself) {
        if (isFollowing) {
            followButton = (
                <Button
                    className="btn btn-sm btn-secondary"
                    onClick={followButtonClickHandler}
                    title={t("profilePage.header.unfollowBtn")}
                >
                    {t("profilePage.header.followingBtn")}
                </Button>
            );
        } else {
            followButton = (
                <Button
                    className="btn btn-sm btn-primary"
                    onClick={followButtonClickHandler}
                    title={t("profilePage.header.followBtn")}
                >
                    {t("profilePage.header.followBtn")}
                </Button>
            );
        }
    }

    return (
        <div className="d-flex align-items-center justify-content-between px-2 py-3 border rounded">
            <UserBasicInfo user={user} showVerifiedMark={showVerifiedMark} />
            {showFollowButton && followButton}
        </div>
    );
};

export default UsersListItem;
