import React, { ForwardedRef, InputHTMLAttributes } from "react";

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
    errorMsg: string;
}
const Select = React.forwardRef(
    (
        { errorMsg, className, children, ...rest }: Props,
        ref: ForwardedRef<HTMLSelectElement>
    ) => {
        return (
            <div className="w-100">
                <select
                    className={`form-select ${
                        !!errorMsg && "is-invalid"
                    } ${className}`}
                    ref={ref}
                    {...rest}
                >
                    {children}
                </select>
                <div className="invalid-feedback">{errorMsg}</div>
            </div>
        );
    }
);

export default Select;
