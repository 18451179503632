import if_amex from "../../../../../../images/if_amex.png";
import if_discover from "../../../../../../images/if_discover.png";
import if_jcb from "../../../../../../images/if_jcb.png";
import if_mastercard from "../../../../../../images/if_mastercard.png";
import if_visa from "../../../../../../images/if_visa.png";
import PaymentMethod from "../../../../../../models/PaymentMethod";
import Button from "../../../../../../components/UI/Button/Button";
import { AiOutlineDelete } from "react-icons/ai";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoCheckboxOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

type Props = {
    paymentMethod: PaymentMethod;
    setAsDefault: (id: string) => void;
    deletePaymentMethod: (id: string) => void;
};

const PaymentMethodsListItem = ({
    paymentMethod,
    setAsDefault,
    deletePaymentMethod,
}: Props) => {
    const { t } = useTranslation();
    let brand_img;
    switch (paymentMethod.brand) {
        case "amex":
            brand_img = if_amex;
            break;
        case "discover":
            brand_img = if_discover;
            break;
        case "jcb":
            brand_img = if_jcb;
            break;
        case "mastercard":
            brand_img = if_mastercard;
            break;
        case "visa":
            brand_img = if_visa;
            break;
        default:
            brand_img = if_visa;
            break;
    }

    let expired_badge = <></>;
    let default_badge = <></>;
    if (paymentMethod.is_expired)
        expired_badge = (
            <span className="badge bg-danger-subtle text-danger-emphasis">
                {t(
                    "settingsPage.paymentMethodsSettings.paymentMethodsList.paymentMethodsListItem.expired"
                )}
            </span>
        );
    if (paymentMethod.is_default)
        default_badge = (
            <span className="badge bg-secondary-subtle text-secondary-emphasis">
                {t(
                    "settingsPage.paymentMethodsSettings.paymentMethodsList.paymentMethodsListItem.default"
                )}
            </span>
        );

    return (
        <div className="d-flex flex-wrap align-items-center justify-content-between border rounded px-2 py-1">
            <div className="d-flex gap-2 gap-lg-3 form-text align-items-center">
                <img src={brand_img} style={{ width: "2rem" }} alt="brand" />
                <span>**** {paymentMethod.last4}</span>
                <span>
                    {paymentMethod.exp_month < 10
                        ? `0${paymentMethod.exp_month}`
                        : paymentMethod.exp_month}{" "}
                    / {paymentMethod.exp_year.toString().slice(-2)}
                </span>
            </div>
            <div className="d-flex align-items-center gap-1">
                {default_badge}
                {expired_badge}
                <div className="dropdown">
                    <button
                        className="btn btn-link text-dark"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <HiOutlineDotsHorizontal />
                    </button>
                    <ul className="dropdown-menu">
                        {
                            <li>
                                <Button
                                    className={`dropdown-item ${
                                        (paymentMethod.is_default ||
                                            paymentMethod.is_expired) &&
                                        "disabled"
                                    }`}
                                    onClick={() =>
                                        setAsDefault(paymentMethod.id)
                                    }
                                >
                                    <IoCheckboxOutline className="mb-1 me-2" />
                                    {t(
                                        "settingsPage.paymentMethodsSettings.paymentMethodsList.paymentMethodsListItem.setAsDefault"
                                    )}
                                </Button>
                            </li>
                        }
                        {
                            <li>
                                <Button
                                    className={`dropdown-item ${
                                        paymentMethod.is_default && "disabled"
                                    }`}
                                    onClick={() =>
                                        deletePaymentMethod(paymentMethod.id)
                                    }
                                >
                                    <AiOutlineDelete className="mb-1 me-2" />
                                    {t(
                                        "settingsPage.paymentMethodsSettings.paymentMethodsList.paymentMethodsListItem.delete"
                                    )}
                                </Button>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default PaymentMethodsListItem;
