import React, { ForwardedRef } from "react";
import Payment, { PaymentStatus } from "../../../../../../models/Payment";
import { useTranslation } from "react-i18next";

type Props = {
    payment: Payment;
};

const PaymentsListItem = React.forwardRef(
    ({ payment }: Props, ref: ForwardedRef<HTMLDivElement>) => {
        const { t } = useTranslation();
        let statusBadge = <></>;
        switch (payment.status) {
            case PaymentStatus.PENDING: {
                statusBadge = (
                    <span className="badge bg-warning-subtle text-warning-emphasis">
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.pending"
                        )}
                    </span>
                );
                break;
            }
            case PaymentStatus.PROCESSING: {
                statusBadge = (
                    <span className="badge bg-warning-subtle text-warning-emphasis">
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.processing"
                        )}
                    </span>
                );
                break;
            }
            case PaymentStatus.CANCELED:
                statusBadge = (
                    <span className="badge bg-danger-subtle text-danger-emphasis">
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.canceled"
                        )}
                    </span>
                );
                break;
            case PaymentStatus.SUCCEEDED:
                statusBadge = (
                    <span className="badge bg-success-subtle text-success-emphasis">
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.succeeded"
                        )}
                    </span>
                );
                break;
            case PaymentStatus.REQUIRES_ACTION:
                statusBadge = (
                    <span className="badge bg-info-subtle text-info-emphasis">
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.requiresAction"
                        )}
                    </span>
                );
                break;
            case PaymentStatus.REQUIRES_CAPTURE:
                statusBadge = (
                    <span className="badge bg-info-subtle text-info-emphasis">
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.requiresCapture"
                        )}
                    </span>
                );
                break;
            case PaymentStatus.REQUIRES_CONFIRMATION:
                statusBadge = (
                    <span className="badge bg-info-subtle text-info-emphasis">
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.requiresConfirmation"
                        )}
                    </span>
                );
                break;
            case PaymentStatus.REQUIRES_PAYMENT_METHOD:
                statusBadge = (
                    <span className="badge bg-info-subtle text-info-emphasis">
                        {t(
                            "settingsPage.billingSettings.paymentsList.paymentsListItem.requiresPaymentMethod"
                        )}
                    </span>
                );
                break;
        }

        return (
            <div
                className="d-flex flex-wrap align-items-center justify-content-between border rounded p-2 form-text"
                ref={ref}
            >
                <div className="d-flex flex-column align-items-start gap-1">
                    <div className="d-flex gap-3">
                        <div className="d-flex gap-1 text-black">
                            <span>{payment.currency.toUpperCase()}</span>
                            <span>{(payment.amount / 100).toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="d-flex gap-1 text-secondary">
                        <span>
                            {t(
                                "settingsPage.billingSettings.paymentsList.paymentsListItem.fee"
                            )}
                            :
                        </span>
                        <span>{payment.currency.toUpperCase()}</span>
                        <span>
                            {(payment.transaction_fee / 100).toFixed(2)}
                        </span>
                    </div>
                </div>

                <div className="d-flex flex-column align-items-end gap-1">
                    <span>
                        {new Date(payment.dt_created).toLocaleString("en-GB", {
                            dateStyle: "medium",
                            timeStyle: "short",
                        })}
                    </span>
                    {statusBadge}
                </div>
            </div>
        );
    }
);

export default PaymentsListItem;
