import React, { useState } from "react";
import InputGroup from "../InputGroup/InputGroup";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import { AiOutlineLink } from "react-icons/ai";

type Props = { copyText: string; btnText: string; showValue?: boolean };

const ClipboardCopy = ({ copyText, btnText, showValue = false }: Props) => {
    const { t } = useTranslation();
    const [isCopied, setIsCopied] = useState(false);

    const copyTextToClipboard = async (text: string) => {
        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand("copy", true, text);
        }
    };

    const handleCopyClick = async (event: React.FormEvent) => {
        event.stopPropagation();
        try {
            await copyTextToClipboard(copyText);
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1500);
        } catch (error: any) {
            console.log(error);
        }
    };

    let content = (
        <Button
            className="btn btn-sm btn-link text-decoration-none px-0"
            type="submit"
            onClick={handleCopyClick}
        >
            <AiOutlineLink className="mb-1 me-1" />{" "}
            <span>{isCopied ? `${t("clipboardCopy.copied")}!` : btnText}</span>
        </Button>
    );

    if (showValue) {
        content = (
            <InputGroup
                className="input-group-sm"
                errorMsg=""
                trailingAddon={
                    <Button
                        className="btn btn-sm btn-primary"
                        type="submit"
                        onClick={handleCopyClick}
                    >
                        <span>
                            {isCopied
                                ? `${t("clipboardCopy.copied")}!`
                                : t("clipboardCopy.copy")}
                        </span>
                    </Button>
                }
                type="text"
                value={copyText}
                readOnly={true}
            />
        );
    }

    return content;
};

export default ClipboardCopy;
