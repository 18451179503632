import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type SuccessResponse = {
    countries: [{ code: string; name: string }];
};

export const getPayoutCountries = async (): Promise<SuccessResponse> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/payments/payout-countries`, {
            headers: {
                Authorization: token ? `Token ${token}` : "",
                "Accept-Language":
                    localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
            },
        });
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
