import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<any> {}

const Spinner = ({ className }: Props) => {
    return (
        <div className="d-flex justify-content-center">
            <div className={`spinner-border spinner-border-sm ${className}`}>
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

export default Spinner;
