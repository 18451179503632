// https://stripe.com/docs/api/accounts/object

export enum PayoutDisabledReason {
    ACTION_REQUIRED__REQUESTED_CAPABILITIES = "action_required.requested_capabilities",
    LISTED = "listed",
    REJECTED__FRAUD = "rejected.fraud",
    REJECTED__INCOMPLETE_VERIFICATION = "rejected.incomplete_verification",
    REJECTED__LISTED = "rejected.listed",
    REJECTED__OTHER = "rejected.other",
    REJECTED__TERMS_OF_SERVICE = "rejected.terms_of_service",
    REQUIREMENTS__PAST_DUE = "requirements.past_due",
    REQUIREMENTS__PENDING_VERIFICATION = "requirements.pending_verification",
    UNDER_REVIEW = "under_review",
    // OTHER = "other",
}

class StripeAccount {
    constructor(
        readonly id: string,
        readonly object: string,
        readonly business_profile: {
            readonly mcc: string;
            readonly name: string;
            readonly product_description: string;
            readonly support_address: {
                readonly city: string;
                readonly country: string;
                readonly line1: string;
                readonly line2: string;
                readonly postal_code: string;
                readonly state: string;
            };
            readonly support_email: string;
            readonly support_phone: string;
            readonly support_url: string;
            readonly url: string;
        },
        readonly business_type: string,
        readonly capabilities: {
            readonly card_payments: string;
            readonly transfers: string;
        },
        readonly charges_enabled: boolean,
        readonly company: {
            readonly address: {
                readonly city: string;
                readonly country: string;
                readonly line1: string;
                readonly line2: string;
                readonly postal_code: string;
                readonly state: string;
            };
            readonly directors_provided: boolean;
            readonly executives_provided: boolean;
            readonly name: string;
            readonly owners_provided: boolean;
            readonly phone: string;
            readonly tax_id_provided: boolean;
            readonly verification: {
                readonly document: {
                    readonly back: string;
                    readonly details: string;
                    readonly details_code: string;
                    readonly front: string;
                };
            };
        },
        readonly country: string,
        readonly created: number,
        readonly default_currency: string,
        readonly details_submitted: boolean,
        readonly email: string,
        readonly external_accounts: {
            readonly object: string;
            readonly data: {
                readonly id: string;
                readonly object: string;
                readonly account: string;
                readonly account_holder_name: string;
                readonly account_holder_type: string;
                readonly account_type: string;
                readonly available_payout_methods: string[];
                readonly bank_name: string;
                readonly country: string;
                readonly currency: string;
                readonly default_for_currency: boolean;
                readonly fingerprint: string;
                readonly future_requirements: {
                    readonly currently_due: string[];
                    readonly errors: string[];
                    readonly past_due: string[];
                    readonly pending_verification: string[];
                };
                readonly last4: string;
                readonly metadata: {};
                readonly requirements: {
                    readonly currently_due: string[];
                    readonly errors: string[];
                    readonly past_due: string[];
                    readonly pending_verification: string[];
                };
                readonly routing_number: string;
                readonly status: string;
            }[];
            readonly has_more: boolean;
            readonly total_count: number;
            readonly url: string;
        },
        readonly future_requirements: {
            readonly alternatives: string[];
            readonly current_deadline: number;
            readonly currently_due: string[];
            readonly disabled_reason: string;
            readonly errors: string[];
            readonly eventually_due: string[];
            readonly past_due: string[];
            readonly pending_verification: string[];
        },
        readonly individual: {
            readonly id: string;
            readonly object: string;
            readonly account: string;
            readonly address: {
                readonly city: string;
                readonly country: string;
                readonly line1: string;
                readonly line2: string;
                readonly postal_code: string;
                readonly state: string;
            };
            readonly created: number;
            readonly dob: {
                readonly day: number;
                readonly month: number;
                readonly year: number;
            };
            readonly email: string;
            readonly first_name: string;
            readonly future_requirements: {
                readonly alternatives: string[];
                readonly currently_due: string[];
                readonly errors: string[];
                readonly eventually_due: string[];
                readonly past_due: string[];
                readonly pending_verification: string[];
            };
            readonly last_name: string;
            readonly metadata: {};
            readonly phone: string;
            readonly relationship: {
                readonly director: boolean;
                readonly executive: boolean;
                readonly owner: boolean;
                readonly percent_ownership: string;
                readonly representative: boolean;
                readonly title: string;
            };
            readonly requirements: {
                readonly alternatives: string[];
                readonly currently_due: string[];
                readonly errors: {
                    readonly code: string;
                    readonly reason: string;
                    readonly requirement: string;
                }[];
                readonly eventually_due: string[];
                readonly past_due: string[];
                readonly pending_verification: string[];
            };
            readonly verification: {
                readonly additional_document: {
                    readonly back: string;
                    readonly details: string;
                    readonly details_code: string;
                    readonly front: string;
                };
                readonly details: string;
                readonly details_code: string;
                readonly document: {
                    readonly back: string;
                    readonly details: string;
                    readonly details_code: string;
                    readonly front: string;
                };
                readonly status: string;
            };
        },
        readonly metadata: {
            readonly ablebees_user_uuid: string;
        },
        readonly payouts_enabled: boolean,
        readonly requirements: {
            readonly alternatives: {
                readonly alternative_fields_due: string[];
                readonly original_fields_due: string[];
            }[];
            readonly current_deadline: number;
            readonly currently_due: string[];
            readonly disabled_reason: PayoutDisabledReason;
            readonly errors: {
                readonly code: string;
                readonly reason: string;
                readonly requirement: string;
            }[];
            readonly eventually_due: string[];
            readonly past_due: string[];
            readonly pending_verification: string[];
        },
        readonly settings: {
            readonly bacs_debit_payments: {};
            readonly branding: {
                readonly icon: string;
                readonly logo: string;
                readonly primary_color: string;
                readonly secondary_color: string;
            };
            readonly card_issuing: {
                readonly tos_acceptance: {
                    readonly date: number;
                    readonly ip: string;
                };
            };
            readonly card_payments: {
                readonly decline_on: {
                    readonly avs_failure: boolean;
                    readonly cvc_failure: boolean;
                };
                readonly statement_descriptor_prefix: string;
                readonly statement_descriptor_prefix_kana: string;
                readonly statement_descriptor_prefix_kanji: string;
            };
            readonly dashboard: {
                readonly display_name: string;
                readonly timezone: string;
            };
            readonly payments: {
                readonly statement_descriptor: string;
                readonly statement_descriptor_kana: string;
                readonly statement_descriptor_kanji: string;
            };
            readonly payouts: {
                readonly debit_negative_balances: boolean;
                readonly schedule: {
                    readonly delay_days: number;
                    readonly interval: string;
                };
                readonly statement_descriptor: string;
            };
            readonly sepa_debit_payments: {};
        },
        readonly tos_acceptance: {
            readonly date: number;
            readonly ip: string;
            readonly user_agent: string;
        },
        readonly type: string
    ) {}
}

export default StripeAccount;
