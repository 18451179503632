import { Link } from "react-router-dom";
import { ProfilePagePetitionsTab } from "../ProfilePagePetitions";
import { useTranslation } from "react-i18next";

type Props = {
    activeTab: ProfilePagePetitionsTab;
    isCreator: boolean;
};

const ProfilePagePetitionsNavbar = ({ activeTab, isCreator }: Props) => {
    const { t } = useTranslation();
    const supportedTab = (
        <li className="nav-item">
            <Link
                className={`nav-link ${
                    activeTab === ProfilePagePetitionsTab.SUPPORTED && "active"
                }`}
                to={ProfilePagePetitionsTab.SUPPORTED}
            >
                {t("profilePage.header.supported")}
            </Link>
        </li>
    );

    const receivedTab = (
        <li className="nav-item">
            <Link
                className={`nav-link ${
                    activeTab === ProfilePagePetitionsTab.RECEIVED && "active"
                }`}
                to={ProfilePagePetitionsTab.RECEIVED}
            >
                {t("profilePage.header.received")}
            </Link>
        </li>
    );

    const startedTab = (
        <li className="nav-item">
            <Link
                className={`nav-link ${
                    activeTab === ProfilePagePetitionsTab.STARTED && "active"
                }`}
                to={ProfilePagePetitionsTab.STARTED}
            >
                {t("profilePage.header.started")}
            </Link>
        </li>
    );
    return (
        <ul className="nav nav-underline py-3">
            {isCreator && receivedTab}
            {supportedTab}
            {startedTab}
        </ul>
    );
};

export default ProfilePagePetitionsNavbar;
