import { Link } from "react-router-dom";
import { CiLogout, CiSettings } from "react-icons/ci";
import { HiOutlineBars3 } from "react-icons/hi2";
import { useContext } from "react";
import authContext from "../../../contexts/auth-context";
import { useTranslation } from "react-i18next";

type Props = {
    className?: string;
};
const HeaderDropdown = ({ className }: Props) => {
    const { t } = useTranslation();
    const authCtx = useContext(authContext);
    const signOutHandler = () => {
        authCtx.logout();
    };

    return (
        <div className={`dropdown ${className}`}>
            <div
                className="btn btn-link d-flex align-items-center justify-content-between link-dark text-decoration-none"
                data-bs-toggle="dropdown"
            >
                <HiOutlineBars3 className="mt-1 text-dark fs-4" />
            </div>
            <ul className="dropdown-menu w-100">
                <li>
                    <Link to="/settings" className="dropdown-item py-2">
                        <CiSettings className="mb-1 me-2" />
                        {t("header.settings")}
                    </Link>
                </li>
                <li>
                    <hr className="dropdown-divider" />
                </li>
                <li>
                    <button className="dropdown-item" onClick={signOutHandler}>
                        <CiLogout className="mb-1 me-2" />
                        {t("header.signOut")}
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default HeaderDropdown;
