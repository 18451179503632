import AuthForm from "../../../components/AuthForm/AuthForm";
import React, { ChangeEvent, useState } from "react";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import {
    FailureResponse,
    getPasswordResetCode,
} from "../../../api/users/getPasswordResetCode";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ForgotPasswordForm = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [emailInputErrorMsg, setEmailInputErrorMsg] = useState("");
    const [formErrorMsg, setFormErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const isEmailValid = () => {
        if (!email) {
            setEmailInputErrorMsg(t("auth.pleaseEnterEmail"));
            return false;
        }
        return true;
    };

    const emailInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
        setEmailInputErrorMsg("");
        setFormErrorMsg("");
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (isEmailValid()) {
            setIsLoading(true);
            try {
                const response = await getPasswordResetCode(email);
                setSuccessMsg(response.message);
            } catch (error) {
                const { errors } = error as FailureResponse;

                if (errors) {
                    if (errors.email) setEmailInputErrorMsg(errors.email[0]);
                    if (errors.error)
                        setFormErrorMsg(
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error
                        );
                }
            }
            setIsLoading(false);
        }
    };

    return (
        <>
            {!successMsg && (
                <AuthForm
                    id="forgot-password-form"
                    title={t("auth.forgotPassword")}
                    errorMsg={formErrorMsg}
                    submitButtonText={t("auth.resetPassword")}
                    onSubmit={submitHandler}
                    isLoading={isLoading}
                >
                    <div className="form-text">
                        {t("auth.forgotPasswordInstructions")}
                    </div>
                    <InputGroup
                        type="email"
                        placeholder={t("auth.email")}
                        errorMsg={emailInputErrorMsg}
                        onChange={emailInputChangeHandler}
                        value={email}
                    />
                </AuthForm>
            )}
            {successMsg && (
                <div className="alert alert-success" role="alert">
                    {successMsg}
                </div>
            )}
            <div className="d-flex flex-column align-items-center mt-2 gap-3">
                <div className="form-text mt-3">
                    <Link to="/login" className="text-decoration-none">
                        {t("auth.login")}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ForgotPasswordForm;
