import axios from "../axiosConfig";
import Account from "../../models/Account";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        payment_settings?: {
            currency?: string[];
            payout_country_code?: string[];
        };
        error?: string | string[];
    };
};

export const updateAccount = async (payment_settings?: {
    currency?: string;
    payout_country_code?: string;
}): Promise<Account> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.patch(
            `/users/account`,
            {
                payment_settings,
            },
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return new Account(response.data);
    } catch (error: any) {
        throw error.response.data;
    }
};
