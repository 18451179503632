import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type SuccessResponse = {};

export type FailureResponse = {};

export const logout = async (): Promise<SuccessResponse> => {
    const token = localStorage.getItem("token");
    try {
        return await axios.delete("/users/signout", {
            headers: {
                Authorization: `Token ${token}`,
                "Accept-Language":
                    localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
            },
        });
    } catch (error: any) {
        throw error.response.data;
    }
};
