import React, { useContext } from "react";
import ClipboardCopy from "../../../../../components/UI/ClipboardCopy/ClipboardCopy";
import AuthContext from "../../../../../contexts/auth-context";
import { useTranslation } from "react-i18next";

const InvitationLinkForm = () => {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const invitationLink = `${process.env.REACT_APP_BASE_URL}/team/${user?.username}`;
    return (
        <>
            <h6>
                {t(
                    "settingsPage.profileSettings.invitationLinkForm.shareInvitationLink"
                )}
            </h6>
            <hr />
            {user?.is_email_verified && (
                <ClipboardCopy
                    copyText={invitationLink}
                    btnText={t("clipboardCopy.copyLink")}
                />
            )}
            {!user?.is_email_verified && (
                <span>
                    {t(
                        "settingsPage.profileSettings.invitationLinkForm.verifyYourEmail"
                    )}
                </span>
            )}
        </>
    );
};

export default InvitationLinkForm;
