import React, { useContext, useState } from "react";
import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import { useTranslation } from "react-i18next";
import Button from "../../../UI/Button/Button";
import ModalContext from "../../../../contexts/modal-context";
import { MAX_PETITION_REJECTION_REASON_LENGTH } from "../../../../constants";
import Textarea from "../../../UI/Textarea/Textarea";

type Props = {
    onConfirm: (rejectionReason: string) => void;
};

const RejectPetitionOverlay = ({ onConfirm }: Props) => {
    const { t } = useTranslation();
    const { closeModal } = useContext(ModalContext);
    const [rejectionReason, setRejectionReason] = useState<string>("");
    const [
        rejectionReasonTextareaErrorMsg,
        setRejectionReasonTextareaErrorMsg,
    ] = useState("");

    const rejectionReasonChangeHandler = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setRejectionReason(event.currentTarget.value);
        setRejectionReasonTextareaErrorMsg("");
    };

    const confirmHandler = () => {
        if (rejectionReason.length > 0) {
            onConfirm(rejectionReason);
            closeModal();
        } else {
            setRejectionReasonTextareaErrorMsg(
                t("modal.overlays.rejectPetition.provideRejectionReason")
            );
        }
    };

    return (
        <ModalOverlay>
            <h6>{t("modal.warning")}</h6>
            <hr />
            <p className="form-text text-black">
                {t("modal.overlays.rejectPetition.sureWantToReject")}
            </p>
            <p className="form-text text-black">
                {t("modal.overlays.rejectPetition.mindToExplain")}
            </p>
            <div>
                <Textarea
                    className="form-control-sm"
                    rows={3}
                    errorMsg={rejectionReasonTextareaErrorMsg}
                    placeholder={t(
                        "modal.overlays.rejectPetition.rejectionReason"
                    )}
                    onChange={rejectionReasonChangeHandler}
                    value={rejectionReason}
                    maxLength={MAX_PETITION_REJECTION_REASON_LENGTH}
                />
                <div className="form-text d-flex justify-content-end">
                    {MAX_PETITION_REJECTION_REASON_LENGTH -
                        rejectionReason.length}
                </div>
            </div>
            <hr />
            <div className="d-flex justify-content-end gap-2">
                <Button className="btn btn-sm btn-dark" onClick={closeModal}>
                    {t("modal.close")}
                </Button>
                <Button
                    className="btn btn-sm btn-primary"
                    onClick={confirmHandler}
                >
                    {t("modal.confirm")}
                </Button>
            </div>
        </ModalOverlay>
    );
};

export default RejectPetitionOverlay;
