import UserProfile from "./UserProfile";
import Account from "./Account";

class User {
    readonly uuid: string;
    readonly name: string;
    readonly username: string;
    readonly profile_image: string;
    readonly is_email_verified: boolean;
    readonly is_account_verified: boolean;
    readonly is_myself: boolean;
    readonly am_i_follower: boolean;
    readonly is_creator: boolean;
    readonly is_placeholder: boolean;
    readonly profile?: UserProfile;
    readonly account?: Account;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.name = data.name;
        this.username = data.username;
        this.profile_image = data.profile_image;
        this.is_email_verified = data.is_email_verified;
        this.is_account_verified = data.is_account_verified;
        this.is_myself = data.is_myself;
        this.am_i_follower = data.am_i_follower;
        this.is_creator = data.is_creator;
        this.is_placeholder = data.is_placeholder;
        this.profile = data.profile ? new UserProfile(data.profile) : undefined;
        this.account = data.account ? new Account(data.account) : undefined;
    }
}

export default User;
