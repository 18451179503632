import { useMatch } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import Petition from "../../models/Petition";
import Spinner from "../../components/UI/Spinner/Spinner";
import PetitionsListItem from "../../components/PetitionsList/PetitionsListItem/PetitionsListItem";
import {
    FailureResponse,
    getPetition as api_getPetition,
} from "../../api/petitions/getPetition";
import AuthContext from "../../contexts/auth-context";
import Footer from "../../components/Footer/Footer";
import classes from "./PetitionPage.module.scss";
import { useTranslation } from "react-i18next";

const PetitionPage = () => {
    const { t } = useTranslation();
    const match = useMatch("/petitions/:uuid");
    const petition_uuid = match?.params.uuid!;
    const { isLoggedIn } = useContext(AuthContext);
    const [petition, setPetition] = useState<Petition | null>(null);
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [firstTimeVisit, setFirstTimeVisit] = useState<boolean>(
        localStorage.getItem("firstTimeVisit") === petition_uuid || false
    );
    localStorage.removeItem("firstTimeVisit");
    // const referrer = localStorage.getItem("referrer");
    //
    // if (referrer) {
    //     if (referrer === window.location.href) {
    //         localStorage.removeItem("referrer");
    //     }
    // }

    const getPetition = useCallback(async () => {
        setErrorMsg("");
        try {
            const response = await api_getPetition(petition_uuid);
            setPetition(response);
        } catch (error) {
            const { errors, status_code } = error as FailureResponse;
            console.log(errors);
            if (status_code === 404) {
                setErrorMsg(t("petitionPage.petitionNotFound"));
            }
        }
        setIsLoading(false);
    }, [petition_uuid]);

    useEffect(() => {
        getPetition();
    }, [getPetition]);

    let content = (
        <>
            {isLoading && <Spinner />}
            {!isLoading && petition && (
                <>
                    {firstTimeVisit && (
                        <div
                            className="alert alert-success alert-dismissible fade show"
                            role="alert"
                        >
                            <h5 className="alert-heading mb-3">
                                {t("petitionPage.congratulations")}
                            </h5>
                            <p>
                                {t("petitionPage.petitionSuccessfullyStarted")}
                            </p>
                            <p className="mb-0">
                                {petition.am_i_addressee
                                    ? t("petitionPage.shareAsAddressee")
                                    : t("petitionPage.shareAsAuthor")}
                            </p>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="alert"
                                aria-label="Close"
                            ></button>
                        </div>
                    )}
                    <PetitionsListItem petition={petition} />
                </>
            )}
            {!isLoading && !petition && <p>{errorMsg}</p>}
        </>
    );

    if (!isLoggedIn) {
        content = (
            <>
                <div
                    className={`row justify-content-sm-center p-0 m-0 ${classes.content}`}
                >
                    <div className="col col-md-9 col-lg-6 col-xl-6 col-xxl-4">
                        {content}
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    return content;
};

export default PetitionPage;
