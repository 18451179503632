import User from "../../models/User";
import blank_profile_image from "../../images/blank-profile-picture.svg";
import { MdOutlineVerified } from "react-icons/md";
import React from "react";
import YoutubeCreator from "../../models/YoutubeCreator";
import { useTranslation } from "react-i18next";
import { parseProfileImageSrc } from "../../utils/helpers";

type Props = {
    user: User | YoutubeCreator;
    showVerifiedMark?: boolean;
};

const UserBasicInfo = ({ user, showVerifiedMark = true }: Props) => {
    const { t } = useTranslation();
    let image_src: string = blank_profile_image;
    const image_url =
        user instanceof YoutubeCreator ? user.thumbnail : user.profile_image;
    if (image_url) image_src = parseProfileImageSrc(image_url);

    const isVerifiedAccount =
        (user instanceof YoutubeCreator &&
            user.user &&
            user.user.is_account_verified) ||
        (user instanceof User && user.is_account_verified);

    const isVerifiedPlaceholderAccount =
        (user instanceof YoutubeCreator &&
            user.user &&
            user.user.is_placeholder) ||
        (user instanceof User && user.is_placeholder);

    return (
        <div className="d-flex align-items-center">
            <img
                src={image_src}
                alt="profile"
                width="45"
                height="45"
                className="rounded-circle me-2"
            />
            <div className="d-flex flex-column">
                <div className="d-flex align-items-center gap-1">
                    <div className="d-flex flex-wrap gap-1 form-text text-black fw-bolder">
                        <span>
                            {user instanceof YoutubeCreator
                                ? user.channel_title
                                : user.name}
                        </span>
                    </div>
                    {isVerifiedAccount && showVerifiedMark && (
                        <MdOutlineVerified
                            className="text-primary"
                            style={{ marginTop: "0.2rem" }}
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title={t("userBasicInfo.verifiedAccount")}
                        />
                    )}
                    {isVerifiedPlaceholderAccount && showVerifiedMark && (
                        <MdOutlineVerified
                            className="text-secondary"
                            style={{ marginTop: "0.2rem" }}
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title={t(
                                "userBasicInfo.verifiedPlaceholderAccount"
                            )}
                        />
                    )}
                </div>
                {user instanceof User && (
                    <span className="form-text mt-0">@{user.username}</span>
                )}
            </div>
        </div>
    );
};

export default UserBasicInfo;
