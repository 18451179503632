class UserProfile {
    readonly uuid: string;
    readonly followers_count: number;
    readonly following_count: number;
    readonly youtube: string;
    readonly headline: string;
    readonly description: string;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.followers_count = data.followers_count;
        this.following_count = data.following_count;
        this.youtube = data.youtube;
        this.headline = data.headline;
        this.description = data.description;
    }
}

export default UserProfile;
