import { createContext, Fragment, useCallback, useState } from "react";

const defaultState = {
    visible: false,
    overlay: <Fragment />,
    showModal: (overlay: JSX.Element) => {},
    closeModal: () => {},
};

const ModalContext = createContext(defaultState);

export const ModalContextProvider = ({
    children,
}: {
    children: JSX.Element;
}) => {
    const [visible, setVisible] = useState(false);
    const [overlay, setOverlay] = useState(<Fragment />);

    const showModal = useCallback((overlay: JSX.Element) => {
        setOverlay(overlay);
        setVisible(true);
    }, []);

    const closeModal = useCallback(() => {
        setVisible(false);
        setOverlay(<Fragment />);
    }, []);

    const contextValue = {
        visible,
        overlay,
        showModal,
        closeModal,
    };

    return (
        <ModalContext.Provider value={contextValue}>
            {children}
        </ModalContext.Provider>
    );
};

export default ModalContext;
