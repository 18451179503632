import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CiGlobe } from "react-icons/ci";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

interface languagesInterface {
    en: {
        nativeName: string;
    };
    ru: {
        nativeName: string;
    };
}

const languages: languagesInterface = {
    en: { nativeName: "English" },
    ru: { nativeName: "Русский" },
};

type Props = {
    bgColor: "dark" | "light";
};

const SelectLanguage = ({ bgColor }: Props) => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(
        localStorage.getItem(LOCALE) || FALLBACK_LOCALE
    );

    const handleLanguageSelect = (
        e: React.MouseEvent<HTMLAnchorElement>,
        lng: string
    ) => {
        e.preventDefault();
        localStorage.setItem(LOCALE, lng);
        i18n.changeLanguage(lng);
        setLanguage(lng);
    };

    return (
        <div className="dropdown mt-1">
            <button
                className={`btn btn-link d-flex align-items-center gap-2 text-decoration-none text-${
                    bgColor === "dark" ? "white" : "black"
                }`}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <CiGlobe />
                {languages[language as keyof languagesInterface].nativeName}
            </button>

            <ul className="dropdown-menu">
                {Object.keys(languages).map((lng: string) => (
                    <li key={lng}>
                        <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => handleLanguageSelect(e, lng)}
                        >
                            {
                                languages[lng as keyof languagesInterface]
                                    .nativeName
                            }
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SelectLanguage;
