import React, { useContext, useEffect } from "react";
import PayoutMethodSettings from "./PayoutMethodSettings/PayoutMethodSettings";
import PayoutsList from "./PayoutsList/PayoutsList";
import ModalContext from "../../../../contexts/modal-context";
import PayoutCountrySettings from "./PayoutCountrySettings/PayoutCountrySettings";
import AuthContext from "../../../../contexts/auth-context";

const PayoutSettingsPage = () => {
    const { user } = useContext(AuthContext);
    const { closeModal } = useContext(ModalContext);
    useEffect(() => {
        closeModal();
    }, [closeModal]);

    return (
        <div className="d-flex flex-column gap-3">
            {!user?.account!.payment_settings.payout_country_code && (
                <div className="border rounded-3 p-3">
                    <PayoutCountrySettings />
                </div>
            )}
            <div className="border rounded-3 p-3">
                <PayoutMethodSettings />
            </div>
            <div className="border rounded-3 p-3">
                <PayoutsList />
            </div>
        </div>
    );
};

export default PayoutSettingsPage;
