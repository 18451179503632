import React, { useContext, useState } from "react";
import Button from "../../../../../components/UI/Button/Button";
import {
    requestEnableAcceptingPledges as api_requestEnableAcceptingPledges,
    FailureResponse,
} from "../../../../../api/admins/requestEnableAcceptingPledges";
import ModalContext from "../../../../../contexts/modal-context";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import { ApiErrorCode } from "../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import { useTranslation } from "react-i18next";
import Account from "../../../../../models/Account";
import { Link } from "react-router-dom";

type Props = {
    account: Account;
};

const AcceptPledgesSettings = ({ account }: Props) => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);

    const requestEnableAcceptingPledges = async () => {
        setIsLoading(true);
        try {
            const response = await api_requestEnableAcceptingPledges();
            showModal(
                <AlertOverlay status={"success"} message={response.message} />
            );
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else if (code === ApiErrorCode.NO_PAYOUT_METHOD) {
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                                link={
                                    <Link
                                        to="/settings/payout"
                                        className="btn btn-sm btn-primary"
                                    >
                                        {t(
                                            "settingsPage.payoutSettings.payoutMethodSettings.addPayoutMethod"
                                        )}
                                    </Link>
                                }
                            />
                        );
                    } else {
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    }
                } else if (
                    errors.non_field_errors &&
                    errors.non_field_errors.length > 0
                ) {
                    showModal(
                        <AlertOverlay
                            status="error"
                            message={errors.non_field_errors[0]}
                        />
                    );
                } else console.log(errors);
            }
        }
        setIsLoading(false);
    };
    return (
        <div>
            <h6>
                {t(
                    "settingsPage.accountSettings.acceptPledgesSettings.updatePledgingStatus"
                )}
            </h6>
            <hr />
            {!account.payment_settings.is_stripe_account_set_up && (
                <Link to="/settings/payout" className="btn btn-sm btn-primary">
                    {t(
                        "settingsPage.accountSettings.payoutBalanceSettings.setupPayoutAccount"
                    )}
                </Link>
            )}
            {account.payment_settings.is_stripe_account_set_up && (
                <div className="d-flex justify-content-between align-items-center">
                    <span>
                        {t(
                            "settingsPage.accountSettings.acceptPledgesSettings.pledgingDisabled"
                        )}
                    </span>
                    <Button
                        className="btn btn-sm btn-dark"
                        onClick={requestEnableAcceptingPledges}
                        disabled={isLoading}
                        isLoading={isLoading}
                    >
                        {t(
                            "settingsPage.accountSettings.acceptPledgesSettings.enable"
                        )}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default AcceptPledgesSettings;
