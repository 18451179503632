import i18next from "../i18n/config";

export const parseDate = (date_str: string) => {
    return new Date(date_str)
        .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
        })
        .replace(/ /g, "-");
};

export const parseCursor = (url: string) => {
    const paramsStr = url.split("?").pop();
    const params = paramsStr!.split("&");
    for (let param of params)
        if (param.startsWith("cursor")) {
            const cursor = param.split("=").pop()!;
            return decodeURIComponent(cursor);
        }
};

export const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const parseProfileImageSrc = (profileImage: string) => {
    if (
        !profileImage.includes("https://yt3.ggpht.com") &&
        profileImage.includes("yt3.ggpht.com")
    ) {
        const start_idx = profileImage.indexOf("yt3.ggpht.com");
        return "https://" + profileImage.substring(start_idx, start_idx + 120);
    } else if (
        !profileImage.includes("https://lh3.googleusercontent.com") &&
        profileImage.includes("lh3.googleusercontent.com")
    ) {
        const start_idx = profileImage.indexOf("lh3.googleusercontent.com");
        const end_idx = profileImage.indexOf("s96-c");
        return "https://" + profileImage.substring(start_idx, end_idx + 5);
    }
    return profileImage;
};

export const getTimeRemainingFormatted = (e: Date): string => {
    let { total, days, hours, minutes, seconds } = getTimeRemaining(e);
    return formatRemainingTime(total, days, hours, minutes, seconds);
};

export const getTimeRemaining = (e: Date) => {
    const total = e.getTime() - new Date().getTime();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    const days = Math.floor(total / 1000 / 60 / 60 / 24);
    return {
        total,
        days,
        hours,
        minutes,
        seconds,
    };
};

export const formatRemainingTime = (
    total: number,
    days: number,
    hours: number,
    minutes: number,
    seconds: number
): string => {
    const d = i18next.t("helpers.day_short");
    const h = i18next.t("helpers.hour_short");
    const m = i18next.t("helpers.minute_short");
    const s = i18next.t("helpers.second_short");
    let res = `0${d} 0${h} 0${m} 0${s}`;
    if (total >= 0) {
        res =
            `${days}${d} ` +
            `${hours}${h} ` +
            `${minutes}${m} ` +
            `${seconds}${s}`;
    }
    return res;
};
