import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type SuccessResponse = {
    message: string;
};

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
        non_field_errors?: string[];
    };
};

export const requestEnableAcceptingPledges =
    async (): Promise<SuccessResponse> => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios.post(
                "/admins/request-enable-accepting-pledges",
                {},
                {
                    headers: {
                        Authorization: token ? `Token ${token}` : "",
                        "Accept-Language":
                            localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                    },
                }
            );
            return response.data;
        } catch (error: any) {
            throw error.response.data;
        }
    };
