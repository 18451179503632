import React from "react";
import { Link } from "react-router-dom";

const HowToSetUpCreatorAccountPage = () => {
    return (
        <div>
            <h2 className="mb-4">How to set up a creator account</h2>
            <p>
                Please take the following steps to set up your creator account
                on Ablebees:
            </p>
            <ul className="d-flex flex-column gap-3">
                <li>
                    <p>Sign up as a regular user.</p>
                </li>
                <li>
                    <p>
                        Go to{" "}
                        <Link
                            to="/settings/account"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            here
                        </Link>
                        , find your YouTube channel, and request to become a
                        creator.
                    </p>
                    <p>
                        Once we receive your request, we will verify your
                        ownership of the YouTube channel you requested and link
                        your channel to your Ablebees account. You will get
                        email notifications at each step of the verification
                        process.
                    </p>
                </li>
                <li>
                    <p>
                        Once your channel ownership is verified and your YouTube
                        channel is linked to your Ablebees account, go to{" "}
                        <Link
                            to="/settings/payout"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            here
                        </Link>{" "}
                        and set up your payout method.
                    </p>
                    <p>
                        You will be redirected to the Stripe website to set up
                        your payout method. When you are asked to enter your
                        email address, please ignore the{" "}
                        <i>
                            "Have a Stripe account? You can use the same email."
                        </i>{" "}
                        message. You can type the email address you used to sign
                        up on Ablebees and go to the next step.
                    </p>
                </li>
                <li>
                    <p>
                        Once your payout method is set up, you are ready to let
                        your subscribers know about this new opportunity you
                        offer them over Ablebees and share your personal
                        invitation link with them. You can find the invitation
                        link{" "}
                        <Link
                            to="/settings/profile"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            here
                        </Link>
                        .
                    </p>
                    <p>
                        Those who sign up using your invitation link will
                        automatically become your follower on Ablebees. Whenever
                        you receive a petition, all your followers will get an
                        email notification inviting them to support the
                        petition.
                    </p>
                    <p>
                        Your invitation link contains your username, so please
                        make sure you are happy with the username before you
                        share your link. You can view and modify your username{" "}
                        <Link
                            to="/settings/profile"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            here
                        </Link>
                        .
                    </p>
                </li>
            </ul>
            <p>
                If you have any questions or encounter any problems with the
                creator account setup process, please let us know at
                support@ablebees.com.
            </p>
        </div>
    );
};

export default HowToSetUpCreatorAccountPage;
