import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    FailureResponse as PayOutFailureResponse,
    payOut as api_payOut,
} from "../../../../../api/payments/payOut";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import Button from "../../../../../components/UI/Button/Button";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../contexts/modal-context";
import { parseDate } from "../../../../../utils/helpers";
import StripeBalance from "../../../../../models/StripeBalance";
import { getStripeBalance as api_getStripeBalance } from "../../../../../api/payments/getStripeBalance";
import { Link } from "react-router-dom";
import { ApiErrorCode } from "../../../../../constants";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../../contexts/auth-context";

const PayoutBalanceSettings = () => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const { user, refreshUser } = useContext(AuthContext);
    const [isPayoutLoading, setIsPayoutLoading] = useState(false);
    const [balance, setBalance] = useState<StripeBalance>();
    const [isLoadingBalance, setIsLoadingBalance] = useState(true);

    const getStripeBalance = useCallback(async () => {
        setIsLoadingBalance(true);
        try {
            const response = await api_getStripeBalance();
            setBalance(response);
        } catch (error) {
            console.log(error);
        }
        setIsLoadingBalance(false);
    }, []);

    useEffect(() => {
        if (user!.account!.payment_settings.is_stripe_account_set_up)
            getStripeBalance();
    }, [
        user,
        user?.account!.payment_settings.is_stripe_account_set_up,
        getStripeBalance,
    ]);

    const payoutButtonClickHandler = async () => {
        setIsPayoutLoading(true);
        try {
            const payouts = await api_payOut();
            const payout = payouts[0];
            const dt_arrival = parseDate(payout.arrival_date);
            let alert_status: "success" | "warning" | "error" = "success";
            let alert_message = `${t(
                "settingsPage.accountSettings.payoutBalanceSettings.payoutArrivalDate"
            )} ${dt_arrival}`;
            if (payout.status === "failed") {
                alert_status = "error";
                alert_message = payout.failure_message;
            }

            showModal(
                <AlertOverlay status={alert_status} message={alert_message} />
            );
            refreshUser();
            await getStripeBalance();
        } catch (error) {
            const { errors, code } = error as PayOutFailureResponse;
            if (errors)
                showModal(
                    <AlertOverlay
                        status="error"
                        message={
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error!
                        }
                        link={
                            code === ApiErrorCode.PAYOUTS_NOT_ALLOWED && (
                                <Link
                                    to="/settings/payout"
                                    className="btn btn-sm btn-primary"
                                >
                                    {t(
                                        "settingsPage.accountSettings.payoutBalanceSettings.payoutDetails"
                                    )}
                                </Link>
                            )
                        }
                    />
                );
        }
        setIsPayoutLoading(false);
    };

    return (
        <div>
            <h6>
                {t(
                    "settingsPage.accountSettings.payoutBalanceSettings.payOutEarnings"
                )}
            </h6>
            <hr />
            {!user?.account!.payment_settings.is_stripe_account_set_up && (
                <Link to="/settings/payout" className="btn btn-sm btn-primary">
                    {t(
                        "settingsPage.accountSettings.payoutBalanceSettings.setupPayoutAccount"
                    )}
                </Link>
            )}
            {user?.account!.payment_settings.is_stripe_account_set_up && (
                <div className="d-flex gap-5 align-items-center justify-content-between">
                    <div className="d-flex gap-2">
                        <span className="fs-6">
                            {t(
                                "settingsPage.accountSettings.payoutBalanceSettings.earnings"
                            )}
                            :
                        </span>{" "}
                        <span className="text-primary">
                            {!isLoadingBalance &&
                                !!balance &&
                                balance.available.map((item) => (
                                    <span key={item.currency}>
                                        {item.currency.toUpperCase()}{" "}
                                        {(item.amount / 100).toFixed(2)}
                                    </span>
                                ))}
                            {isLoadingBalance && <Spinner className="mt-1" />}
                        </span>
                    </div>
                    <Button
                        className="btn btn-sm btn-dark"
                        onClick={payoutButtonClickHandler}
                        isLoading={isPayoutLoading}
                    >
                        {t(
                            "settingsPage.accountSettings.payoutBalanceSettings.payOut"
                        )}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default PayoutBalanceSettings;
