import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./custom-bootstrap.scss";
import "bootstrap/dist/js/bootstrap.min";
import App from "./App";
import { ModalContextProvider } from "./contexts/modal-context";
import { AuthContextProvider } from "./contexts/auth-context";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
    >
        <AuthContextProvider>
            <ModalContextProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ModalContextProvider>
        </AuthContextProvider>
    </GoogleOAuthProvider>
);
