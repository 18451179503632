import { NavLink, useLocation } from "react-router-dom";
import React, { useContext, useState } from "react";
import classes from "./Header.module.scss";
import authContext from "../../contexts/auth-context";
import HeaderDropdown from "./HeaderDropdown/HeaderDropdown";
import logoDark from "../../logo.svg";
import logoLight from "../../logo-light.svg";
import SelectLanguage from "../SelectLanguage/SelectLanguage";
import { useTranslation } from "react-i18next";

const Header = () => {
    const { t } = useTranslation();
    const { isLoggedIn } = useContext(authContext);
    const current_path = useLocation().pathname;
    const [bgColorClass, setBgColorClass] = useState("");
    const changeColor = () => {
        if (window.scrollY < 16) {
            setBgColorClass("");
        } else if (window.scrollY < 32) {
            setBgColorClass("header-scroll-bg-1rem");
        } else if (window.scrollY < 48) {
            setBgColorClass("header-scroll-bg-2rem");
        } else if (window.scrollY < 64) {
            setBgColorClass("header-scroll-bg-3rem");
        } else {
            setBgColorClass("header-scroll-bg");
        }
    };

    const logo =
        !isLoggedIn && current_path === "/" && window.scrollY < 16
            ? logoLight
            : logoDark;

    const bgColor =
        !isLoggedIn && current_path === "/" && window.scrollY < 16
            ? "dark"
            : "light";

    window.addEventListener("scroll", changeColor);

    let content = (
        <div className="d-flex align-items-center gap-3 mt-1">
            <NavLink
                to="/login"
                className={`nav-link px-0 text-black ${
                    !isLoggedIn &&
                    current_path === "/" &&
                    window.scrollY < 16 &&
                    "text-white"
                }`}
                style={{ fontWeight: "600" }}
            >
                {t("header.login")}
            </NavLink>
            <NavLink
                to="/signup"
                className="btn btn-danger d-none d-sm-block"
                style={{ fontWeight: "600" }}
            >
                {t("header.signup")}
            </NavLink>
        </div>
    );

    if (isLoggedIn) {
        content = (
            <div className="d-flex align-items-center">
                <HeaderDropdown />
            </div>
        );
    }
    return (
        <header
            className={`${classes.header} ${
                !isLoggedIn &&
                current_path === "/" &&
                classes["header-welcome-bg"]
            } ${bgColorClass !== "" && classes[bgColorClass]} ${
                (isLoggedIn || current_path !== "/") && "border-bottom"
            }`}
        >
            <div className="w-100 px-3">
                <nav className={`nav justify-content-between`}>
                    <NavLink
                        to="/"
                        className="nav-link px-0 d-flex align-items-center gap-1"
                    >
                        <img src={logo} width={150} alt="Logo" />
                    </NavLink>
                    <div className="d-flex align-items-center gap-3">
                        <SelectLanguage bgColor={bgColor} />
                        {content}
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
