export enum PaymentStatus {
    PENDING = "pending",
    REQUIRES_PAYMENT_METHOD = "requires_payment_method",
    REQUIRES_CONFIRMATION = "requires_confirmation",
    REQUIRES_ACTION = "requires_action",
    PROCESSING = "processing",
    REQUIRES_CAPTURE = "requires_capture",
    CANCELED = "canceled",
    SUCCEEDED = "succeeded",
}

export default class Payment {
    constructor(
        readonly uuid: string,
        readonly amount: number,
        readonly transaction_fee: number,
        readonly net_amount: number,
        readonly currency: string,
        readonly status: PaymentStatus,
        readonly dt_created: string
    ) {}
}
