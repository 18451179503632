import React, { ChangeEvent, useContext, useState } from "react";
import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import InputGroup from "../../../UI/InputGroup/InputGroup";
import Button from "../../../UI/Button/Button";
import ModalContext from "../../../../contexts/modal-context";

import {
    addPetitionEndResult as api_addPetitionEndResult,
    FailureResponse,
} from "../../../../api/petitions/addPetitionEndResult";
import Petition from "../../../../models/Petition";
import { ApiErrorCode } from "../../../../constants";
import EmailNotVerifiedErrorOverlay from "../EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AlertOverlay from "../AlertOverlay/AlertOverlay";
import { useTranslation } from "react-i18next";

type Props = {
    petition: Petition;
    onPetitionUpdate: (petition: Petition) => void;
};

const AddPetitionEndResultOverlay = ({ petition, onPetitionUpdate }: Props) => {
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const [url, setUrl] = useState("");
    const [urlInputErrorMsg, setUrlInputErrorMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const urlChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setUrl(event.currentTarget.value);
        setUrlInputErrorMsg("");
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await api_addPetitionEndResult(petition.uuid, url);
            onPetitionUpdate(response);
            closeModal();
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;

            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (errors.url) setUrlInputErrorMsg(errors.url[0]);
                    if (errors.non_field_errors)
                        setErrorMsg(errors.non_field_errors[0]);
                    if (errors.error)
                        setErrorMsg(
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error!
                        );
                }
            }
        }
        setIsLoading(false);
    };

    return (
        <ModalOverlay>
            <h6>{t("modal.overlays.addPetitionEndResult.addEndResultUrl")}</h6>
            <hr />
            <form onSubmit={submitHandler}>
                {!!errorMsg && (
                    <div className="form-text text-danger mt-0">{errorMsg}</div>
                )}
                <InputGroup
                    className="input-group-sm"
                    errorMsg={urlInputErrorMsg}
                    type="text"
                    placeholder={t(
                        "modal.overlays.addPetitionEndResult.endResultUrl"
                    )}
                    onChange={urlChangeHandler}
                />
                <hr />
                <div className="d-flex justify-content-end gap-1">
                    <Button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        isLoading={isLoading}
                    >
                        {t("petition.buttons.markAsFulfilled")}
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-sm btn-dark"
                        onClick={closeModal}
                    >
                        {t("modal.cancel")}
                    </Button>
                </div>
            </form>
        </ModalOverlay>
    );
};

export default AddPetitionEndResultOverlay;
