import axios from "../axiosConfig";
import Petition from "../../models/Petition";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        title?: string[];
        description?: string[];
        petition_duration_in_days?: string[];
        author_pledge_amount?: string[];
        addressees?: string[];
        non_field_errors?: string[];
        error?: string | string[];
    };
};

export const createPetition = async (
    title: string,
    description: string,
    addressees: string[],
    petition_duration_in_days: number,
    author_pledge_amount: number,
    youtube_channel_ids: string[] = []
): Promise<Petition> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.post(
            `/petitions/`,
            {
                title,
                description,
                addressees,
                petition_duration_in_days,
                author_pledge_amount,
                youtube_channel_ids,
            },
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return new Petition(response.data);
    } catch (error: any) {
        throw error.response.data;
    }
};
