import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        email?: string[];
        youtube_handle?: string[];
        non_field_errors?: string[];
    };
};

export const requestCreatorSignup = async (
    email: string,
    youtube_handle: string
) => {
    try {
        const response = await axios.post(
            "/admins/request-creator-signup",
            {
                email,
                youtube_handle,
            },
            {
                headers: {
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
