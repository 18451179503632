import React, { ForwardedRef, InputHTMLAttributes } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    errorMsg?: string;
    leadingAddon?: JSX.Element;
    trailingAddon?: JSX.Element;
}
const InputGroup = React.forwardRef(
    (
        {
            errorMsg,
            label,
            leadingAddon,
            trailingAddon,
            className,
            ...rest
        }: Props,
        ref: ForwardedRef<HTMLInputElement>
    ) => {
        return (
            <>
                {label && <label className="form-label">{label}</label>}
                <div
                    className={`input-group has-validation w-100 ${className}`}
                >
                    {leadingAddon}
                    <input
                        className={`form-control ${!!errorMsg && "is-invalid"}`}
                        ref={ref}
                        {...rest}
                    />
                    {trailingAddon}
                    <div className="invalid-feedback">{errorMsg}</div>
                </div>
            </>
        );
    }
);

export default InputGroup;
