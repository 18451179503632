import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import User from "../../../../models/User";
import React, { useCallback, useContext, useEffect, useState } from "react";
import UsersList from "../../../UsersList/UsersList";
import SearchUserForm from "../../../SearchUserForm/SearchUserForm";
import { listUsers as api_listUsers } from "../../../../api/users/listUsers";
import { listYoutubeCreators as api_listYoutubeCreators } from "../../../../api/users/listYoutubeCreators";
import Spinner from "../../../UI/Spinner/Spinner";
import ModalContext from "../../../../contexts/modal-context";
import Button from "../../../UI/Button/Button";
import { parseCursor } from "../../../../utils/helpers";
import YoutubeCreator from "../../../../models/YoutubeCreator";
import { useTranslation } from "react-i18next";

type Props = {
    selectUserHandler: (user: User | YoutubeCreator) => void;
    creatorsOnly?: boolean;
    youtubeCreators?: boolean;
    showVerifiedMark?: boolean;
};

const FindUserOverlay = ({
    selectUserHandler,
    creatorsOnly = false,
    youtubeCreators = false,
    showVerifiedMark = true,
}: Props) => {
    const { t } = useTranslation();
    const { closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState<(User | YoutubeCreator)[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const getUsers = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!searchTerm || !hasMore) return;
            setIsLoading(true);
            try {
                let response: {
                    results: User[] | YoutubeCreator[];
                    next: any;
                    previous?: string;
                };
                if (youtubeCreators) {
                    response = await api_listYoutubeCreators(searchTerm);
                } else {
                    response = await api_listUsers(
                        searchTerm,
                        false,
                        creatorsOnly,
                        cursor
                    );
                }
                if (cursor === undefined) setUsers(response.results);
                else
                    setUsers((prev: (User | YoutubeCreator)[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [searchTerm]
    );

    useEffect(() => {
        setUsers([]);
        setCursor(undefined);
        setHasMore(true);
        getUsers(undefined, true);
    }, [getUsers]);

    return (
        <ModalOverlay>
            <SearchUserForm
                onChange={setSearchTerm}
                creatorsOnly={creatorsOnly}
                youtubeCreators={youtubeCreators}
            />
            {(isLoading || users.length > 0 || searchTerm) && (
                <div className="mt-3">
                    {!!users && (
                        <UsersList
                            users={users}
                            itemClickHandler={selectUserHandler}
                            showFollowButton={false}
                            showVerifiedMark={showVerifiedMark}
                            getUsers={() => getUsers(cursor, hasMore)}
                            isLoading={isLoading}
                        />
                    )}
                    {isLoading && <Spinner className="mt-3" />}
                    {!isLoading && users.length === 0 && (
                        <p className="form-text text-center">
                            {t("modal.overlays.findUser.noOneFound")}
                        </p>
                    )}
                </div>
            )}
            <hr />
            <div className="d-flex justify-content-end">
                <Button className="btn btn-sm btn-dark" onClick={closeModal}>
                    {t("modal.close")}
                </Button>
            </div>
        </ModalOverlay>
    );
};

export default FindUserOverlay;
