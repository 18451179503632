import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import InputGroup from "../../../../../components/UI/InputGroup/InputGroup";
import Button from "../../../../../components/UI/Button/Button";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../../contexts/auth-context";
import Textarea from "../../../../../components/UI/Textarea/Textarea";
import {
    ApiErrorCode,
    MAX_DESCRIPTION_LENGTH,
    MAX_HEADLINE_LENGTH,
    MAX_PETITION_DESCRIPTION_LENGTH,
} from "../../../../../constants";
import {
    FailureResponse as UpdateProfileFailureResponse,
    updateProfile as api_updateProfile,
} from "../../../../../api/users/updateProfile";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../contexts/modal-context";

const ProfileAboutForm = () => {
    const { t } = useTranslation();
    const { user, refreshUser } = useContext(AuthContext);
    const { showModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [headline, setHeadline] = useState(user!.profile!.headline);
    const [headlineInputErrorMsg, setHeadlineInputErrorMsg] = useState("");
    const [description, setDescription] = useState(user!.profile!.description);
    const [descriptionTextareaErrorMsg, setDescriptionInputErrorMsg] =
        useState("");

    let saveButtonDisabled = true;

    useEffect(() => {
        refreshUser();
    }, [refreshUser]);

    const checkSaveButtonState = () => {
        if (
            headline !== user?.profile!.headline ||
            description !== user?.profile!.description
        ) {
            saveButtonDisabled = false;
        } else {
            saveButtonDisabled = true;
        }
    };

    checkSaveButtonState();

    const headlineInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        if (event.currentTarget.value !== user?.profile!.headline)
            saveButtonDisabled = false;
        setHeadline(event.currentTarget.value);
        setHeadlineInputErrorMsg("");
        checkSaveButtonState();
    };

    const descriptionTextareaChangeHandler = (
        event: ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (event.currentTarget.value !== user?.profile!.description)
            saveButtonDisabled = false;
        setDescription(event.currentTarget.value);
        setDescriptionInputErrorMsg("");
        checkSaveButtonState();
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            setIsLoading(true);
            const response = await api_updateProfile(
                user!.uuid,
                headline !== user?.profile!.headline ? headline : undefined,
                description !== user?.profile!.description
                    ? description
                    : undefined
            );
            refreshUser();
            saveButtonDisabled = true;
        } catch (error) {
            const { errors, status_code, code } =
                error as UpdateProfileFailureResponse;

            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (errors.headline)
                        setHeadlineInputErrorMsg(errors.headline[0]);
                    if (errors.description)
                        setDescriptionInputErrorMsg(errors.description[0]);
                }
            }
        }
        setIsLoading(false);
    };

    return (
        <>
            <h6>
                {t(
                    "settingsPage.profileSettings.profileAboutForm.updateAboutInfo"
                )}
            </h6>
            <hr />
            <form onSubmit={submitHandler}>
                <div className="row mb-3">
                    <div className="col">
                        <InputGroup
                            className="input-group-sm"
                            type="text"
                            label={t(
                                "settingsPage.profileSettings.profileAboutForm.headlineLabel"
                            )}
                            errorMsg={headlineInputErrorMsg}
                            onChange={headlineInputChangeHandler}
                            value={headline}
                            maxLength={MAX_HEADLINE_LENGTH}
                        />
                        <div className="form-text d-flex justify-content-end">
                            {headline
                                ? MAX_HEADLINE_LENGTH - headline.length
                                : MAX_HEADLINE_LENGTH}
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Textarea
                            className="form-control-sm"
                            resize={"block"}
                            rows={5}
                            errorMsg={descriptionTextareaErrorMsg}
                            label={t(
                                "settingsPage.profileSettings.profileAboutForm.descriptionLabel"
                            )}
                            onChange={descriptionTextareaChangeHandler}
                            value={description}
                            maxLength={MAX_DESCRIPTION_LENGTH}
                        />
                        <div className="form-text d-flex justify-content-end">
                            {description
                                ? MAX_DESCRIPTION_LENGTH - description.length
                                : MAX_DESCRIPTION_LENGTH}
                        </div>
                    </div>
                </div>
                <Button
                    className="btn btn-sm btn-primary float-end"
                    type="submit"
                    disabled={isLoading || saveButtonDisabled}
                    isLoading={isLoading}
                >
                    {t("settingsPage.save")}
                </Button>
            </form>
        </>
    );
};

export default ProfileAboutForm;
