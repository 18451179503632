import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HelpPageTab } from "../../HelpPage/HelpPage";

type FAQ = {
    question: string;
    answer: string[];
};

type Props = {
    centerTabs?: boolean;
    viewAsContentCreator: boolean;
    setViewAsContentCreator: React.Dispatch<React.SetStateAction<boolean>>;
};

const Faqs = ({
    centerTabs = false,
    viewAsContentCreator,
    setViewAsContentCreator,
}: Props) => {
    const { t } = useTranslation();

    const consumerFAQs: FAQ[] = t("faqPage.questions.byConsumers", {
        returnObjects: true,
    }).map((item) => item);

    const creatorFAQs: FAQ[] = t("faqPage.questions.byCreators", {
        returnObjects: true,
    }).map((item) => item);

    const renderFAQ = (item: FAQ, index: number, itemType: string) => {
        const components = {
            "link-to-how-to-set-up-creator-account": (
                <Link
                    target="_blank"
                    to={`/help/${HelpPageTab.HOW_TO_SETUP_CREATOR_ACCOUNT}`}
                    className="text-decoration-none"
                />
            ),
        };

        return (
            <div className="accordion-item" key={`${itemType}${index}`}>
                <h2 className="accordion-header">
                    <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${itemType}${index}`}
                        aria-expanded="false"
                        aria-controls={`${itemType}${index}`}
                    >
                        {item.question}
                    </button>
                </h2>
                <div
                    id={`${itemType}${index}`}
                    className="accordion-collapse collapse"
                    data-bs-parent={`#accordion${
                        itemType.charAt(0).toUpperCase() + itemType.slice(1)
                    }`}
                >
                    <div className="accordion-body">
                        {item.answer.map((paragraph: string, index: number) => (
                            <p key={index}>
                                <Trans
                                    defaults={paragraph}
                                    components={components}
                                />
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const renderedConsumerFAQs = consumerFAQs.map((item: FAQ, index: number) =>
        renderFAQ(item, index, "consumer")
    );

    const renderedCreatorFAQs = creatorFAQs.map((item: FAQ, index: number) =>
        renderFAQ(item, index, "creator")
    );

    return (
        <>
            <div className={centerTabs ? `d-flex justify-content-center` : ""}>
                <ul
                    className="nav nav-underline mb-3"
                    id="pills-tab"
                    role="tablist"
                >
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${
                                viewAsContentCreator ? "" : "active"
                            }`}
                            id="pills-content-consumers-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-content-consumers"
                            type="button"
                            role="tab"
                            aria-controls="pills-content-consumers"
                            aria-selected="false"
                            onClick={() => setViewAsContentCreator(false)}
                        >
                            {t("faqPage.byViewers")}
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${
                                viewAsContentCreator ? "active" : ""
                            }`}
                            id="pills-content-creators-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-content-creators"
                            type="button"
                            role="tab"
                            aria-controls="pills-content-creators"
                            aria-selected="false"
                            onClick={() => setViewAsContentCreator(true)}
                        >
                            {t("faqPage.byCreators")}
                        </button>
                    </li>
                </ul>
            </div>
            <div>
                <div className="tab-content w-100" id="pills-tabContent">
                    <div
                        className={`tab-pane fade ${
                            viewAsContentCreator ? "show active" : ""
                        }`}
                        id="pills-content-creators"
                        role="tabpanel"
                        aria-labelledby="pills-content-creators-tab"
                    >
                        <div className="accordion" id="accordionCreator">
                            {renderedCreatorFAQs}
                        </div>
                    </div>
                </div>
                <div className="tab-content w-100" id="pills-tabContent">
                    <div
                        className={`tab-pane fade ${
                            viewAsContentCreator ? "" : "show active"
                        }`}
                        id="pills-content-consumers"
                        role="tabpanel"
                        aria-labelledby="pills-content-creators-tab"
                    >
                        <div className="accordion" id="accordionConsumer">
                            {renderedConsumerFAQs}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Faqs;
