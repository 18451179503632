import { Link, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {
    FailureResponse as VerifyEmailFailureResponse,
    verifyEmail as api_verifyEmail,
} from "../../api/users/verifyEmail";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useTranslation } from "react-i18next";

const VerifyEmailPage = () => {
    const { t } = useTranslation();
    let [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");

    const verifyEmail = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await api_verifyEmail(code!);
            const referrer = localStorage.getItem("referrer");
            if (referrer) {
                window.location.href = referrer;
            }
            setError(false);
            setMessage(response.message);
        } catch (error) {
            const { errors } = error as VerifyEmailFailureResponse;
            setError(true);
            if (errors)
                if (errors.code) setMessage(errors.code[0]);
                else if (errors.error)
                    setMessage(
                        errors.error instanceof Array
                            ? errors.error[0]
                            : errors.error
                    );
        }
        setIsLoading(false);
    }, [code]);

    useEffect(() => {
        verifyEmail();
    }, [verifyEmail]);

    return (
        <div className="d-flex flex-column align-items-center">
            {isLoading && <Spinner />}
            {!isLoading && (
                <>
                    <div
                        className={`alert alert-${
                            error ? "danger" : "success"
                        } w-100`}
                        role="alert"
                    >
                        {message}
                    </div>
                    <div className="d-flex flex-column align-items-center mt-2 gap-3">
                        <div className="form-text mt-3">
                            <Link to="/" className="text-decoration-none">
                                {t("verifyEmailPage.home")}
                            </Link>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default VerifyEmailPage;
