import React, { ChangeEvent, useContext, useState } from "react";
import ModalContext from "../../contexts/modal-context";
import FindUserOverlay from "../Modal/overlays/FindUserOverlay/FindUserOverlay";
import User from "../../models/User";
import { AiOutlineDelete } from "react-icons/ai";
import {
    createPetition as api_createPetition,
    FailureResponse,
} from "../../api/petitions/createPetition";
import Petition from "../../models/Petition";
import Textarea from "../UI/Textarea/Textarea";
import InputGroup from "../UI/InputGroup/InputGroup";
import {
    ApiErrorCode,
    MAX_PETITION_DESCRIPTION_LENGTH,
    MAX_PETITION_DURATION_IN_DAYS,
    MAX_PETITION_PLEDGE_AMOUNT,
    MAX_PETITION_TITLE_LENGTH,
    MIN_PETITION_DURATION_IN_DAYS,
    MIN_PETITION_PLEDGE_AMOUNT,
} from "../../constants";
import Button from "../UI/Button/Button";
import AlertOverlay from "../Modal/overlays/AlertOverlay/AlertOverlay";
import EmailNotVerifiedErrorOverlay from "../Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AuthContext from "../../contexts/auth-context";
import { Link } from "react-router-dom";
import YoutubeCreator from "../../models/YoutubeCreator";
import { useTranslation } from "react-i18next";
import UserBasicInfo from "../UserBasicInfo/UserBasicInfo";

type Props = {
    onSelectAddressee: (addressees: (User | YoutubeCreator)[]) => void;
    onCreatePetition: (petition: Petition) => void;
};

const StartPetitionForm = ({ onSelectAddressee, onCreatePetition }: Props) => {
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const { user } = useContext(AuthContext);
    const [addressee, setAddressee] = useState<User | YoutubeCreator | null>(
        null
    );
    const [addresseeInputErrorMsg, setAddresseeInputErrorMsg] = useState("");
    const [title, setTitle] = useState<string>("");
    const [titleTextareaErrorMsg, setTitleTextareaErrorMsg] = useState("");
    const [description, setDescription] = useState<string>("");
    const [descriptionTextareaErrorMsg, setDescriptionTextareaErrorMsg] =
        useState("");
    const [pledgeAmount, setPledgeAmount] = useState<number>(
        MIN_PETITION_PLEDGE_AMOUNT
    );
    const [pledgeAmountInputErrorMsg, setPledgeAmountInputErrorMsg] =
        useState("");
    const [petitionDurationInDays, setPetitionDurationInDays] =
        useState<number>(MAX_PETITION_DURATION_IN_DAYS);
    const [
        petitionDurationInDaysInputErrorMsg,
        setPetitionDurationInDaysInputErrorMsg,
    ] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const creatorsOnly = true;
    const youtubeCreators = true;

    const validMinPledgeAmount =
        addressee &&
        ((addressee instanceof YoutubeCreator &&
            addressee.user &&
            addressee.user.uuid === user?.uuid) ||
            (addressee instanceof User &&
                (addressee.uuid === user?.uuid ||
                    !addressee.account!.payment_settings
                        .accepting_pledges_enabled)))
            ? 0
            : MIN_PETITION_PLEDGE_AMOUNT;

    const titleChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setTitle(event.currentTarget.value);
        setTitleTextareaErrorMsg("");
    };

    const descriptionChangeHandler = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setDescription(event.currentTarget.value);
        setDescriptionTextareaErrorMsg("");
    };

    const pledgeAmountChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const val = parseInt(event.currentTarget.value);
        setPledgeAmount(val);
        if (
            isNaN(val) ||
            val < MIN_PETITION_PLEDGE_AMOUNT ||
            val > MAX_PETITION_PLEDGE_AMOUNT
        ) {
            setPledgeAmountInputErrorMsg(
                t("startPetitionPage.pleaseEnterValueBetween", {
                    min_value: MIN_PETITION_PLEDGE_AMOUNT,
                    max_value: MAX_PETITION_PLEDGE_AMOUNT,
                })
            );
        } else {
            setPledgeAmountInputErrorMsg("");
        }
    };

    const petitionDurationInDaysChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const val = parseInt(event.currentTarget.value);
        setPetitionDurationInDays(val);
        if (
            isNaN(val) ||
            val < MIN_PETITION_DURATION_IN_DAYS ||
            val > MAX_PETITION_DURATION_IN_DAYS
        ) {
            setPetitionDurationInDaysInputErrorMsg(
                t("startPetitionPage.pleaseEnterValueBetween", {
                    min_value: MIN_PETITION_DURATION_IN_DAYS,
                    max_value: MAX_PETITION_DURATION_IN_DAYS,
                })
            );
        } else {
            setPetitionDurationInDaysInputErrorMsg("");
        }
    };

    const isAddresseeValid = () => {
        if (!addressee) {
            setAddresseeInputErrorMsg(
                t("startPetitionPage.pleaseSelectAddressee")
            );
            return false;
        }
        return true;
    };

    const isTitleValid = () => {
        if (!title) {
            setTitleTextareaErrorMsg(t("startPetitionPage.pleaseEnterTitle"));
            return false;
        }
        return true;
    };

    const isDescriptionValid = () => {
        if (!description) {
            setDescriptionTextareaErrorMsg(
                t("startPetitionPage.pleaseEnterDescription")
            );
            return false;
        }
        return true;
    };

    const isPetitionDurationInDaysValid = () => {
        if (
            isNaN(petitionDurationInDays) ||
            petitionDurationInDays === undefined ||
            petitionDurationInDays < MIN_PETITION_DURATION_IN_DAYS ||
            petitionDurationInDays > MAX_PETITION_DURATION_IN_DAYS
        ) {
            setPetitionDurationInDaysInputErrorMsg(
                t("startPetitionPage.pleaseEnterValueBetween", {
                    min_value: MIN_PETITION_DURATION_IN_DAYS,
                    max_value: MAX_PETITION_DURATION_IN_DAYS,
                })
            );
            return false;
        }
        return true;
    };

    const isPledgeAmountValid = () => {
        if (
            isNaN(pledgeAmount) ||
            pledgeAmount === undefined ||
            pledgeAmount < validMinPledgeAmount ||
            pledgeAmount > MAX_PETITION_PLEDGE_AMOUNT
        ) {
            setPledgeAmountInputErrorMsg(
                t("startPetitionPage.pleaseEnterValueBetween", {
                    min_value: MIN_PETITION_PLEDGE_AMOUNT,
                    max_value: MAX_PETITION_PLEDGE_AMOUNT,
                })
            );
            return false;
        }
        return true;
    };

    const selectUserHandler = (user: User | YoutubeCreator) => {
        closeModal();
        setAddressee(user);
        setAddresseeInputErrorMsg("");
        setErrorMsg("");
        onSelectAddressee([user]);
    };

    const removeAddresseeHandler = () => {
        setAddressee(null);
        setAddresseeInputErrorMsg("");
        onSelectAddressee([]);
    };

    const addresseeInputFocusHandler = () => {
        showModal(
            <FindUserOverlay
                selectUserHandler={selectUserHandler}
                creatorsOnly={creatorsOnly}
                youtubeCreators={youtubeCreators}
            />
        );
    };

    const placeholder = creatorsOnly
        ? youtubeCreators
            ? t("searchUserForm.findYouTubeCreator")
            : t("searchUserForm.findCreator")
        : t("searchUserForm.findUser");

    const addresseeContent = addressee ? (
        <div className="d-flex justify-content-between align-items-center">
            <UserBasicInfo user={addressee} />
            <Button
                className="btn btn-outline-danger"
                onClick={removeAddresseeHandler}
                title={t("startPetitionPage.removeAddressee")}
            >
                <AiOutlineDelete className="my-1" />
            </Button>
        </div>
    ) : (
        <InputGroup
            className="input-group-sm"
            errorMsg={addresseeInputErrorMsg}
            type="text"
            placeholder={placeholder}
            onFocus={addresseeInputFocusHandler}
        />
    );

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (
            isAddresseeValid() &&
            isTitleValid() &&
            isDescriptionValid() &&
            isPetitionDurationInDaysValid() &&
            isPledgeAmountValid()
        ) {
            setIsLoading(true);
            try {
                const addressees: string[] = [];
                const youtubeChannelIds: string[] = [];

                if (addressee) {
                    if (addressee instanceof User)
                        addressees.push(addressee.uuid);
                    else youtubeChannelIds.push(addressee.channel_id);
                }

                const response = await api_createPetition(
                    title,
                    description,
                    addressees,
                    petitionDurationInDays,
                    pledgeAmount * 100,
                    youtubeChannelIds
                );
                onCreatePetition(response);
            } catch (error) {
                const { errors, status_code, code } = error as FailureResponse;

                if (errors) {
                    if (status_code === 403) {
                        if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                            showModal(
                                <EmailNotVerifiedErrorOverlay
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                        } else
                            showModal(
                                <AlertOverlay
                                    status="error"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                    } else {
                        if (code === ApiErrorCode.NOT_ENOUGH_BALANCE) {
                            showModal(
                                <AlertOverlay
                                    status="error"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                    link={
                                        <Link
                                            to="/settings/account"
                                            className="btn btn-sm btn-primary"
                                        >
                                            {t(
                                                "settingsPage.accountSettings.balanceSettings.topUpBalance"
                                            )}
                                        </Link>
                                    }
                                />
                            );
                        } else {
                            if (errors.title)
                                setTitleTextareaErrorMsg(errors.title[0]);
                            if (errors.description)
                                setDescriptionTextareaErrorMsg(
                                    errors.description[0]
                                );
                            if (errors.petition_duration_in_days)
                                setPetitionDurationInDaysInputErrorMsg(
                                    errors.petition_duration_in_days[0]
                                );
                            if (errors.author_pledge_amount)
                                setPledgeAmountInputErrorMsg(
                                    errors.author_pledge_amount[0]
                                );
                            if (errors.addressees)
                                setAddresseeInputErrorMsg(errors.addressees[0]);
                            if (errors.non_field_errors)
                                setErrorMsg(errors.non_field_errors[0]);
                            if (errors.error) console.log(errors.error);
                        }
                    }
                }
            }
            setIsLoading(false);
        }
    };

    let showPledgeAmountInput = false;
    if (addressee) {
        if (addressee instanceof User) {
            showPledgeAmountInput =
                addressee.uuid !== user?.uuid &&
                addressee.account!.payment_settings.accepting_pledges_enabled;
        } else {
            // addressee instanceof YoutubeCreator
            showPledgeAmountInput =
                !addressee.user ||
                (addressee.user.uuid !== user?.uuid &&
                    addressee.user.account!.payment_settings
                        .accepting_pledges_enabled);
        }
    }

    if (!showPledgeAmountInput) {
        if (pledgeAmountInputErrorMsg) setPledgeAmountInputErrorMsg("");

        if (pledgeAmount !== validMinPledgeAmount)
            setPledgeAmount(validMinPledgeAmount);
    }

    const currency = user!.account!.payment_settings.currency;

    let content;
    if (
        addressee &&
        addressee instanceof User &&
        addressee.account!.payment_settings.accepting_pledges_enabled &&
        !currency
    ) {
        content = (
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between mt-4">
                {t("startPetitionPage.haveToPledgeMoney")}
                <Link to="/settings/account" className="btn btn-sm btn-primary">
                    {t("startPetitionPage.setCurrency")}
                </Link>
            </div>
        );
    } else {
        content = (
            <>
                <div className="mb-3">
                    <Textarea
                        className="form-control-sm"
                        rows={2}
                        errorMsg={titleTextareaErrorMsg}
                        placeholder={t("startPetitionPage.title")}
                        onChange={titleChangeHandler}
                        value={title}
                        maxLength={MAX_PETITION_TITLE_LENGTH}
                    />
                    <div className="form-text d-flex justify-content-end">
                        {MAX_PETITION_TITLE_LENGTH - title.length}
                    </div>
                </div>
                <div className="mb-3">
                    <Textarea
                        className="form-control-sm"
                        rows={10}
                        errorMsg={descriptionTextareaErrorMsg}
                        placeholder={t("startPetitionPage.description")}
                        onChange={descriptionChangeHandler}
                        value={description}
                        maxLength={MAX_PETITION_DESCRIPTION_LENGTH}
                    />
                    <div className="form-text d-flex justify-content-end">
                        {MAX_PETITION_DESCRIPTION_LENGTH - description.length}
                    </div>
                </div>
                <div className="mb-3 row">
                    <label
                        htmlFor="petitionDurationInDaysInput"
                        className="col-sm-6 col-form-label"
                    >
                        {t("startPetitionPage.needContentIn")}:
                    </label>
                    <div className="col-sm-6">
                        <InputGroup
                            id="petitionDurationInDaysInput"
                            className="input-group-sm"
                            errorMsg={petitionDurationInDaysInputErrorMsg}
                            trailingAddon={
                                <span className="input-group-text">
                                    {t("startPetitionPage.days")}
                                </span>
                            }
                            type="number"
                            min={MIN_PETITION_DURATION_IN_DAYS}
                            max={MAX_PETITION_DURATION_IN_DAYS}
                            onChange={petitionDurationInDaysChangeHandler}
                            value={
                                petitionDurationInDays !== undefined
                                    ? petitionDurationInDays
                                    : ""
                            }
                        />
                    </div>
                </div>
                {showPledgeAmountInput && (
                    <div className="mb-3 row">
                        <label
                            htmlFor="pledgeAmountInput"
                            className="col-sm-6 col-form-label"
                        >
                            {t("startPetitionPage.pledge")}:
                        </label>
                        <div className="col-sm-6">
                            <InputGroup
                                id="pledgeAmountInput"
                                className="input-group-sm"
                                errorMsg={pledgeAmountInputErrorMsg}
                                leadingAddon={
                                    <span className="input-group-text">
                                        {currency.toUpperCase()}
                                    </span>
                                }
                                trailingAddon={
                                    <span className="input-group-text">
                                        .00
                                    </span>
                                }
                                type="number"
                                min={MIN_PETITION_PLEDGE_AMOUNT}
                                max={MAX_PETITION_PLEDGE_AMOUNT}
                                onChange={pledgeAmountChangeHandler}
                                value={
                                    pledgeAmount !== undefined
                                        ? pledgeAmount
                                        : ""
                                }
                            />
                        </div>
                    </div>
                )}
                {errorMsg && (
                    <div className="form-text text-danger mt-0">{errorMsg}</div>
                )}
                <div className="d-flex justify-content-end">
                    <Button
                        className="btn btn-sm btn-primary"
                        type="submit"
                        disabled={isLoading}
                        isLoading={isLoading}
                    >
                        {t("startPetitionPage.start")}
                    </Button>
                </div>
            </>
        );
    }

    return (
        <form onSubmit={submitHandler}>
            <div className="mb-3">{addresseeContent}</div>
            {content}
        </form>
    );
};

export default StartPetitionForm;
