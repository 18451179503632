import { Link } from "react-router-dom";
import { ProfilePageFollowsTab } from "../ProfilePageFollows";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { ProfilePagePetitionsTab } from "../../ProfilePagePetitions/ProfilePagePetitions";
import { useTranslation } from "react-i18next";

type Props = {
    activeTab: ProfilePageFollowsTab;
};

const ProfilePageFollowsNavbar = ({ activeTab }: Props) => {
    const { t } = useTranslation();
    return (
        <ul className="nav nav-underline py-3">
            <li className="nav-item">
                <Link
                    className={`nav-link`}
                    to={ProfilePagePetitionsTab.SUPPORTED}
                >
                    <AiOutlineArrowLeft />
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className={`nav-link ${
                        activeTab === ProfilePageFollowsTab.FOLLOWING &&
                        "active"
                    }`}
                    to={ProfilePageFollowsTab.FOLLOWING}
                >
                    {t("profilePage.header.following")}
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className={`nav-link ${
                        activeTab === ProfilePageFollowsTab.FOLLOWERS &&
                        "active"
                    }`}
                    to={ProfilePageFollowsTab.FOLLOWERS}
                >
                    {t("profilePage.header.followers")}
                </Link>
            </li>
        </ul>
    );
};

export default ProfilePageFollowsNavbar;
