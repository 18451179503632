import {
    ApiErrorCode,
    MAX_TOP_UP_BALANCE_AMOUNT,
    MIN_TOP_UP_BALANCE_AMOUNT,
} from "../../../../../../constants";
import InputGroup from "../../../../../../components/UI/InputGroup/InputGroup";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Button from "../../../../../../components/UI/Button/Button";
import AlertOverlay from "../../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../../contexts/modal-context";
import EmailNotVerifiedErrorOverlay from "../../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import {
    createPayment as api_createPayment,
    FailureResponse,
} from "../../../../../../api/payments/createPayment";
import { Link } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import { delay } from "../../../../../../utils/helpers";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../../../contexts/auth-context";
import { PaymentIntent } from "@stripe/stripe-js";

const TopUpBalanceForm = () => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const { user, refreshUser } = useContext(AuthContext);
    const { showModal } = useContext(ModalContext);
    const [topUpAmount, setTopUpAmount] = useState<number>(
        MIN_TOP_UP_BALANCE_AMOUNT
    );
    const [topUpAmountInputErrorMsg, setTopUpAmountInputErrorMsg] =
        useState("");
    const [isLoading, setIsLoading] = useState(false);

    const processPaymentIntentStatus = useCallback(
        async (paymentIntent: PaymentIntent) => {
            let alertStatus: "success" | "warning" | "error" = "error";
            let message = "";
            let link = <></>;
            switch (paymentIntent.status) {
                case "succeeded":
                    // wait until Stripe webhook event is processed on the server
                    // 5s should be more than enough I guess
                    await delay(5000);

                    refreshUser();
                    setTopUpAmount(MIN_TOP_UP_BALANCE_AMOUNT);
                    alertStatus = "success";
                    message = t(
                        "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.balanceTopUpSucceeded"
                    );
                    break;

                // case "processing":
                //     setMessage(
                //         "Payment processing. We'll update you when payment is received."
                //     );
                //     break;

                case "requires_payment_method":
                    message = t(
                        "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.tryAnotherPaymentMethod"
                    );

                    // if (
                    //     paymentIntent.last_payment_error &&
                    //     paymentIntent.last_payment_error.message
                    // )
                    //     message = paymentIntent.last_payment_error.message;

                    link = (
                        <Link
                            to="/settings/payment-methods"
                            className="btn btn-sm btn-primary"
                        >
                            {t(
                                "settingsPage.paymentMethodsSettings.addNewPaymentMethod.updatePaymentMethod"
                            )}
                        </Link>
                    );
                    break;

                default:
                    message = t(
                        "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.somethingWentWrong"
                    );
                    break;
            }

            showModal(
                <AlertOverlay
                    status={alertStatus}
                    message={message}
                    link={link}
                />
            );
        },
        []
    );

    useEffect(() => {
        if (!stripe) {
            return;
        }

        // Docs: https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements#web-submit-payment
        // Retrieve the "payment_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (clientSecret)
            stripe
                .retrievePaymentIntent(clientSecret)
                .then(({ paymentIntent }) => {
                    // Inspect the PaymentIntent `status` to indicate the status of the payment
                    // to your customer.
                    //
                    // Some payment methods will [immediately succeed or fail][0] upon
                    // confirmation, while others will first enter a `processing` state.
                    //
                    // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                    processPaymentIntentStatus(paymentIntent!);
                });
    }, [stripe, processPaymentIntentStatus]);

    const isTopUpAmountValid = () => {
        if (
            isNaN(topUpAmount) ||
            topUpAmount === undefined ||
            topUpAmount < MIN_TOP_UP_BALANCE_AMOUNT ||
            topUpAmount > MAX_TOP_UP_BALANCE_AMOUNT
        ) {
            setTopUpAmountInputErrorMsg(
                t(
                    "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.pleaseEnterValueBetween",
                    {
                        min_value: MIN_TOP_UP_BALANCE_AMOUNT,
                        max_value: MAX_TOP_UP_BALANCE_AMOUNT,
                    }
                )
            );
            return false;
        }
        return true;
    };

    const topUpAmountChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const val = parseInt(event.currentTarget.value);
        setTopUpAmount(val);
        if (
            isNaN(val) ||
            val < MIN_TOP_UP_BALANCE_AMOUNT ||
            val > MAX_TOP_UP_BALANCE_AMOUNT
        ) {
            setTopUpAmountInputErrorMsg(
                t(
                    "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.pleaseEnterValueBetween",
                    {
                        min_value: MIN_TOP_UP_BALANCE_AMOUNT,
                        max_value: MAX_TOP_UP_BALANCE_AMOUNT,
                    }
                )
            );
        } else {
            setTopUpAmountInputErrorMsg("");
        }
    };

    const topUpBalance = async () => {
        if (!stripe) return;
        if (!isTopUpAmountValid()) return;

        // Docs: https://stripe.com/docs/payments/accept-a-payment?ui=elements
        setTopUpAmountInputErrorMsg("");
        setIsLoading(true);
        try {
            const { payment_intent_client_secret: clientSecret } =
                await api_createPayment(topUpAmount * 100);

            const { paymentIntent, error } = await stripe.confirmCardPayment(
                clientSecret,
                {
                    return_url: `${process.env.REACT_APP_BASE_URL}/settings/account`,
                }
            );

            if (error) {
                console.log(error);
                if (error.payment_intent)
                    await processPaymentIntentStatus(error.payment_intent);
                else {
                    let message = t(
                        "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.somethingWentWrong"
                    );

                    if (error.message) message = error.message;
                    showModal(
                        <AlertOverlay status="error" message={message} />
                    );
                }
            } else {
                await processPaymentIntentStatus(paymentIntent);
            }
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else if (code === ApiErrorCode.NO_PAYMENT_METHOD) {
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                                link={
                                    <Link
                                        to="/settings/payment-methods"
                                        className="btn btn-sm btn-primary"
                                    >
                                        {t(
                                            "settingsPage.paymentMethodsSettings.addNewPaymentMethod.addPaymentMethod"
                                        )}
                                    </Link>
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (errors.amount)
                        setTopUpAmountInputErrorMsg(errors.amount[0]);
                    else if (errors.non_field_errors)
                        setTopUpAmountInputErrorMsg(errors.non_field_errors[0]);
                    else if (errors.error)
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                }
            }
        }
        setIsLoading(false);
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!isTopUpAmountValid()) return;

        // Stripe fee: [2.9% for US cards, 4.4% for international cards] + USD 0.30
        // https://stripe.com/pricing
        const maxFee = `${user?.account!.payment_settings.currency.toUpperCase()} ${(
            topUpAmount * 0.044
        ).toFixed(2)} + USD 0.30`;
        showModal(
            <AlertOverlay
                status="warning"
                message={t(
                    "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.cardChargeWarning",
                    {
                        amount: `${user?.account!.payment_settings.currency.toUpperCase()} ${topUpAmount.toFixed(
                            2
                        )}`,
                        maxFee: maxFee,
                    }
                )}
                onConfirm={async () => await topUpBalance()}
            />
        );
    };

    return (
        <form onSubmit={submitHandler}>
            <InputGroup
                className="input-group-sm"
                errorMsg={topUpAmountInputErrorMsg}
                leadingAddon={
                    <span className="input-group-text">
                        {user?.account!.payment_settings.currency.toUpperCase()}
                    </span>
                }
                trailingAddon={
                    <>
                        <span className="input-group-text">.00</span>
                        <Button
                            className="btn btn-sm btn-primary"
                            type="submit"
                            isLoading={isLoading}
                            onlySpinner={true}
                        >
                            {t(
                                "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.topUp"
                            )}
                        </Button>
                    </>
                }
                type="number"
                placeholder={t(
                    "settingsPage.accountSettings.balanceSettings.topUpBalanceForm.topUpAmount"
                )}
                min={MIN_TOP_UP_BALANCE_AMOUNT}
                max={MAX_TOP_UP_BALANCE_AMOUNT}
                // step={0.01}
                onChange={topUpAmountChangeHandler}
                value={topUpAmount !== undefined ? topUpAmount : ""}
            />
        </form>
    );
};

export default TopUpBalanceForm;
