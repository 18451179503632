import React, { ComponentProps, useCallback, useEffect, useState } from "react";
import { getTimeRemainingFormatted } from "../../../utils/helpers";

interface Props extends ComponentProps<any> {
    deadline: Date;
}

const Countdown = ({ deadline, className }: Props) => {
    const [timer, setTimer] = useState("");

    const startTimer = useCallback((e: Date) => {
        setTimer(getTimeRemainingFormatted(e));
    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            startTimer(deadline);
        }, 1000);

        return () => clearInterval(id);
    }, [deadline, startTimer]);

    return (
        <span className={className} style={{ width: "110px" }}>
            {timer}
        </span>
    );
};

export default Countdown;
