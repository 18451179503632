import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import SupportPetitionForm from "./SupportPetitionForm/SupportPetitionForm";
import Petition from "../../../../models/Petition";
import { useTranslation } from "react-i18next";

type Props = {
    petition: Petition;
    onSupport: (petition: Petition) => void;
};

const SupportPetitionOverlay = ({ petition, onSupport }: Props) => {
    const { t } = useTranslation();
    return (
        <ModalOverlay>
            <h6>{t("modal.overlays.supportPetition.supportPetition")}</h6>
            <hr />
            <SupportPetitionForm petition={petition} onSupport={onSupport} />
        </ModalOverlay>
    );
};

export default SupportPetitionOverlay;
