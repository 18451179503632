import React, { ForwardedRef, TextareaHTMLAttributes } from "react";
import classes from "./Textarea.module.scss";
import { Property } from "csstype";

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    resize?: Property.Resize;
    errorMsg: string;
}
const Textarea = React.forwardRef(
    (
        { className, label, resize = "none", errorMsg, ...rest }: Props,
        ref: ForwardedRef<HTMLTextAreaElement>
    ) => {
        return (
            <div className="w-100">
                {label && <label className="form-label">{label}</label>}
                <textarea
                    className={`${classes.textarea} form-control ${
                        !!errorMsg && "is-invalid"
                    } ${className}`}
                    style={{ resize: resize }}
                    ref={ref}
                    {...rest}
                />
                <div className="invalid-feedback">{errorMsg}</div>
            </div>
        );
    }
);

export default Textarea;
