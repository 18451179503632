import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/auth-context";
import { useNavigate } from "react-router-dom";
import InputGroup from "../../components/UI/InputGroup/InputGroup";
import FindUserOverlay from "../../components/Modal/overlays/FindUserOverlay/FindUserOverlay";
import { Trans, useTranslation } from "react-i18next";
import ModalContext from "../../contexts/modal-context";
import User from "../../models/User";
import YoutubeCreator from "../../models/YoutubeCreator";
import Button from "../../components/UI/Button/Button";
import UserBasicInfo from "../../components/UserBasicInfo/UserBasicInfo";
import { AiOutlineDelete } from "react-icons/ai";
import { followFavoriteYoutubeCreators as api_followFavoriteYoutubeCreators } from "../../api/users/followFavoriteYoutubeCreators";
import { FailureResponse } from "../../api/petitions/addPetitionEndResult";
import {
    ApiErrorCode,
    MAX_FAVORITE_CREATORS,
    MIN_FAVORITE_CREATORS,
} from "../../constants";
import EmailNotVerifiedErrorOverlay from "../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AlertOverlay from "../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import youtube_logo from "../../images/youtube-logo-white.svg";

const FollowFavoriteCreatorsPage = () => {
    const { user, hideNavbar, refreshUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const [creators, setCreators] = useState<YoutubeCreator[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (user!.account!.favorites_page_seen) navigate("/");
        // hide navbar when visiting the page
        hideNavbar(true);
        return () => {
            // show navbar when leaving the page
            hideNavbar(false);
        };
    }, []);

    const creatorInputFocusHandler = () => {
        showModal(
            <FindUserOverlay
                selectUserHandler={selectUserHandler}
                creatorsOnly={true}
                youtubeCreators={true}
                showVerifiedMark={false}
            />
        );
    };

    const selectUserHandler = (user: User | YoutubeCreator) => {
        closeModal();
        if (
            creators.some(
                (creator) =>
                    creator.channel_id === (user as YoutubeCreator).channel_id
            )
        )
            return;

        setCreators((prev: YoutubeCreator[]) => [
            ...prev,
            user as YoutubeCreator,
        ]);
    };

    const removeCreatorHandler = (creator: YoutubeCreator) => {
        setCreators(
            creators.filter((c) => c.channel_id !== creator.channel_id)
        );
    };

    const followFavoriteYoutubeCreators = async () => {
        setIsLoading(true);
        try {
            const response = await api_followFavoriteYoutubeCreators(
                creators.map((item) => item.channel_id)
            );
            await refreshUser();
            hideNavbar(false);
            navigate("/");
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (errors.non_field_errors)
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={errors.non_field_errors[0]}
                            />
                        );
                    if (errors.error)
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                }
            }
        }
        setIsLoading(false);
    };

    const renderCreators = creators.map((creator) => (
        <div className="row justify-content-md-center w-100">
            <div className="col-md-10 col-lg-8 col-xl-6 p-2 align-items-center border rounded d-flex justify-content-between">
                <UserBasicInfo user={creator} showVerifiedMark={false} />
                <Button
                    className="btn btn-outline-danger"
                    onClick={() => removeCreatorHandler(creator)}
                    title={t("startPetitionPage.removeAddressee")}
                >
                    <AiOutlineDelete className="my-1" />
                </Button>
            </div>
        </div>
    ));

    return (
        <div className="container d-flex flex-column align-items-center gap-3">
            <h2 className="m-0 mt-3 text-center">
                {t("followFavoriteCreatorsPage.title")}
            </h2>
            <p className="m-0 text-center">
                {t("followFavoriteCreatorsPage.subtitle", {
                    max_value: MAX_FAVORITE_CREATORS,
                })}
            </p>
            <div className="row justify-content-md-center w-100">
                <div className="col-md-10 col-lg-8 col-xl-6 p-0">
                    <InputGroup
                        className="input-group"
                        type="text"
                        placeholder={t("searchUserForm.findYouTubeCreator")}
                        onFocus={creatorInputFocusHandler}
                        disabled={creators.length == MAX_FAVORITE_CREATORS + 1}
                    />
                </div>
            </div>
            {renderCreators}
            <div>
                <Button
                    className="btn btn-dark"
                    onClick={followFavoriteYoutubeCreators}
                    disabled={creators.length < MIN_FAVORITE_CREATORS}
                    isLoading={isLoading}
                >
                    {creators.length < MIN_FAVORITE_CREATORS
                        ? t("followFavoriteCreatorsPage.disabledButtonText", {
                              value: MIN_FAVORITE_CREATORS - creators.length,
                          })
                        : t("followFavoriteCreatorsPage.enabledButtonText")}
                </Button>
            </div>
        </div>
    );
};

export default FollowFavoriteCreatorsPage;
