import React, { useEffect, useState } from "react";
import InputGroup from "../UI/InputGroup/InputGroup";
import { useTranslation } from "react-i18next";

type Props = {
    onChange: (searchTerm: string) => void;
    creatorsOnly?: boolean;
    youtubeCreators?: boolean;
};
let timeout: NodeJS.Timeout;
const SearchUserForm = ({
    onChange,
    creatorsOnly = false,
    youtubeCreators = false,
}: Props) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            onChange(searchTerm);
        }, 1000);
    });

    const placeholder = creatorsOnly
        ? youtubeCreators
            ? t("searchUserForm.findYouTubeCreator")
            : t("searchUserForm.findCreator")
        : t("searchUserForm.findUser");

    return (
        <form>
            <InputGroup
                className="input-group-sm"
                type="text"
                autoFocus={true}
                placeholder={placeholder}
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
            />
        </form>
    );
};

export default SearchUserForm;
