import React, { useCallback, useContext, useEffect, useState } from "react";
import Petition from "../../models/Petition";
import { listPetitions as api_listPetitions } from "../../api/petitions/listPetitions";
import Spinner from "../../components/UI/Spinner/Spinner";
import PetitionsList from "../../components/PetitionsList/PetitionsList";
import { parseCursor } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import authContext from "../../contexts/auth-context";

const HomePage = () => {
    const { user } = useContext(authContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [petitions, setPetitions] = useState<Petition[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getPetitions = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                const response = await api_listPetitions(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    cursor
                );
                if (cursor === undefined) setPetitions(response.results);
                else
                    setPetitions((prev: Petition[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        []
    );

    useEffect(() => {
        if (!user!.account!.favorites_page_seen) navigate("/favorites");
        getPetitions(undefined, true);
    }, [getPetitions]);

    return (
        <div>
            {!!petitions.length && (
                <PetitionsList
                    petitions={petitions}
                    getPetitions={() => getPetitions(cursor, hasMore)}
                    isLoading={isLoading}
                />
            )}
            {isLoading && <Spinner className="mt-3" />}
            {!isLoading && !petitions.length && (
                <div className="d-flex justify-content-center form-text mt-0">
                    No petitions found
                </div>
            )}
        </div>
    );
};

export default HomePage;
