import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import Petition from "../../../../models/Petition";
import React, { useCallback, useContext, useEffect, useState } from "react";
import PetitionSupporter from "../../../../models/PetitionSupporter";
import { listPetitionSupporters as api_listPetitionSupporters } from "../../../../api/petitions/listPetitionSupporters";
import Spinner from "../../../UI/Spinner/Spinner";
import ModalContext from "../../../../contexts/modal-context";
import Button from "../../../UI/Button/Button";
import { parseCursor } from "../../../../utils/helpers";
import PetitionSupportersList from "../../../PetitionSupportersList/PetitionSupportersList";
import { useTranslation } from "react-i18next";

type Props = {
    petition: Petition;
};

const PetitionSupportersListOverlay = ({ petition }: Props) => {
    const { t } = useTranslation();
    const { closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(true);
    const [supporters, setSupporters] = useState<PetitionSupporter[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getPetitionSupporters = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                const response = await api_listPetitionSupporters(
                    petition.uuid,
                    cursor
                );
                if (cursor === undefined) setSupporters(response.results);
                else
                    setSupporters((prev: PetitionSupporter[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [petition.uuid]
    );

    useEffect(() => {
        setSupporters([]);
        setCursor(undefined);
        setHasMore(true);
        getPetitionSupporters(undefined, true);
    }, [getPetitionSupporters]);

    return (
        <ModalOverlay>
            <h6>{t("modal.overlays.petitionSupportersList.supporters")}</h6>
            <hr />
            {!!supporters && (
                <PetitionSupportersList
                    supporters={supporters}
                    showPledgeAmount={petition.accepts_pledges}
                    getPetitionSupporters={() =>
                        getPetitionSupporters(cursor, hasMore)
                    }
                    isLoading={isLoading}
                />
            )}
            {isLoading && <Spinner className="mt-3" />}
            <hr />
            <div className="d-flex justify-content-end">
                <Button className="btn btn-sm btn-dark" onClick={closeModal}>
                    {t("modal.close")}
                </Button>
            </div>
        </ModalOverlay>
    );
};

export default PetitionSupportersListOverlay;
