import LoginForm from "./LoginForm/LoginForm";
import { useMatch } from "react-router-dom";
import SignupForm, { SignUpFormID } from "./SignupForm/SignupForm";
import classes from "./AuthPage.module.scss";
import ForgotPasswordForm from "./ForgotPasswordForm/ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";
import VerifyEmailPage from "../VerifyEmailPage/VerifyEmailPage";
import Footer from "../../components/Footer/Footer";
import { useContext, useEffect } from "react";
import ModalContext from "../../contexts/modal-context";
import CreatorSignupRequestForm from "./CreatorSignupRequestForm/CreatorSignupRequestForm";

enum Page {
    LOGIN = "login",
    SIGN_UP = "signup",
    JOIN_AS_CREATOR = "join-as-creator",
    FORGOT_PASSWORD = "forgot-password",
    RESET_PASSWORD = "reset-password",
    VERIFY_EMAIL = "verify-email",
}

const AuthPage = () => {
    const match = useMatch("/:page");
    const page: Page = match?.params.page as Page;
    const { closeModal } = useContext(ModalContext);

    useEffect(() => {
        closeModal();
    }, [closeModal]);

    let content;
    switch (page) {
        case Page.LOGIN:
            content = <LoginForm />;
            break;
        case Page.SIGN_UP:
            content = <SignupForm id={SignUpFormID.SIGNUP_FORM} />;
            break;
        case Page.JOIN_AS_CREATOR:
            content = <CreatorSignupRequestForm />;
            break;
        case Page.FORGOT_PASSWORD:
            content = <ForgotPasswordForm />;
            break;
        case Page.RESET_PASSWORD:
            content = <ResetPasswordForm />;
            break;
        case Page.VERIFY_EMAIL:
            content = <VerifyEmailPage />;
            break;
    }

    return (
        <div className="row d-flex flex-column align-items-center justify-content-between">
            <div className={`col ${classes.col}`}>
                <div className={`${classes.content} p-3 py-4`}>{content}</div>
            </div>
            <Footer />
        </div>
    );
};

export default AuthPage;
