class PetitionEndResult {
    readonly uuid: string;
    readonly url: string;
    readonly average_rate: number;
    readonly can_i_rate: boolean;
    readonly dt_created: string;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.url = data.url;
        this.average_rate = data.average_rate;
        this.can_i_rate = data.can_i_rate;
        this.dt_created = data.dt_created;
    }
}

export default PetitionEndResult;
