import React from "react";
import PayoutMethodsListItem from "./PayoutMethodsListItem/PayoutMethodsListItem";
import { useTranslation } from "react-i18next";

type Props = {
    payoutMethods: any[];
};

const PayoutMethodsList = ({ payoutMethods }: Props) => {
    const { t } = useTranslation();
    const renderPayoutMethodsList = payoutMethods.map((item) => {
        return <PayoutMethodsListItem key={item.id} payoutMethod={item} />;
    });

    return (
        <div className="d-flex flex-column gap-1">
            {renderPayoutMethodsList.length > 0 ? (
                renderPayoutMethodsList
            ) : (
                <span className="form-text">
                    {t(
                        "settingsPage.payoutSettings.payoutMethodSettings.payoutMethodsList.noPayoutMethodFound"
                    )}
                </span>
            )}
        </div>
    );
};

export default PayoutMethodsList;
