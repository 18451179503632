import User from "./User";

class YoutubeCreator {
    readonly channel_id: string;
    readonly channel_title: string;
    readonly thumbnail: string;
    readonly user?: User;

    constructor(data: any) {
        this.channel_id = data.channel_id;
        this.channel_title = data.channel_title;
        this.thumbnail = data.thumbnail;
        this.user = data.user ? new User(data.user) : undefined;
    }
}

export default YoutubeCreator;
