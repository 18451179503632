import PaymentSettings from "./PaymentSettings";

class Account {
    readonly favorites_page_seen: boolean;
    readonly payment_settings: PaymentSettings;

    constructor(data: any) {
        this.favorites_page_seen = data.favorites_page_seen;
        this.payment_settings = new PaymentSettings(data.payment_settings);
    }
}

export default Account;
