import axios from "../axiosConfig";
import PaymentMethod from "../../models/PaymentMethod";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export const setPaymentMethodAsDefault = async (
    payment_method_id: string
): Promise<PaymentMethod[]> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.put(
            `/payments/payment-methods/${payment_method_id}`,
            null,
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
