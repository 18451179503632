import axios from "../axiosConfig";
import User from "../../models/User";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        profile_image?: string[];
        error?: string | string[];
    };
};

export const updateProfileImage = async (
    profile_image?: any
): Promise<User> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.patch(
            `/users/self`,
            {
                profile_image,
            },
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Content-Type": "multipart/form-data",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return new User(response.data);
    } catch (error: any) {
        throw error.response.data;
    }
};
