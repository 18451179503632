import axios from "../axiosConfig";
import UserFollow from "../../models/UserFollow";
import { ProfilePageFollowsTab } from "../../pages/ProfilePage/ProfilePageFollows/ProfilePageFollows";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export type SuccessResponse = {
    results: UserFollow[];
    next: string;
    previous: string;
};

export const listUserFollows = async (
    uuid_or_username: string,
    type: ProfilePageFollowsTab,
    cursor?: string
): Promise<SuccessResponse> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/users/${uuid_or_username}/${type}`, {
            headers: {
                Authorization: token ? `Token ${token}` : "",
                "Accept-Language":
                    localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
            },
            params: {
                cursor,
            },
        });

        response.data.results = response.data.results.map(
            (item: UserFollow) => new UserFollow(item)
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
