import React, { useCallback, useContext, useEffect, useState } from "react";
import { getPayoutCountries as api_getPayoutCountries } from "../../../../../api/payments/getPayoutCountries";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/UI/Button/Button";
import {
    FailureResponse as UpdateAccountFailureResponse,
    updateAccount as api_updateAccount,
} from "../../../../../api/users/updateAccount";
import { ApiErrorCode } from "../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import AuthContext from "../../../../../contexts/auth-context";
import ModalContext from "../../../../../contexts/modal-context";

type County = {
    code: string;
    name: string;
};

const PayoutCountrySettings = () => {
    const { user, refreshUser } = useContext(AuthContext);
    const { showModal } = useContext(ModalContext);
    const { t } = useTranslation();
    const [isLoadingCountries, setIsLoadingCountries] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState<[County]>();
    const [countryCode, setCountryCode] = useState<string>();
    const [errorMsg, setErrorMsg] = useState<string>();

    const getPayoutCountries = useCallback(async () => {
        setIsLoadingCountries(true);
        const response = await api_getPayoutCountries();
        setCountries(response.countries);
        setIsLoadingCountries(false);
    }, []);

    useEffect(() => {
        getPayoutCountries();
    }, [getPayoutCountries]);

    const updateAccount = async (payment_settings?: {
        payout_country_code?: string;
    }) => {
        setIsLoading(true);
        try {
            await api_updateAccount(payment_settings);
            refreshUser();
        } catch (error) {
            const { errors, status_code, code } =
                error as UpdateAccountFailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else {
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    }
                } else {
                    if (errors.payment_settings?.payout_country_code)
                        setErrorMsg(
                            errors.payment_settings?.payout_country_code[0]
                        );
                }
            }
        }
        setIsLoading(false);
    };

    const handleSaveCountry = async () => {
        if (!countryCode) {
            setErrorMsg(
                t(
                    "settingsPage.payoutSettings.payoutCountrySettings.pleaseSelectPayoutCountry"
                )
            );
            return;
        }
        await updateAccount({ payout_country_code: countryCode });
    };

    const changeHandler = (event: React.FormEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setErrorMsg(undefined);
        const val = event.currentTarget.value;
        if (!!val) setCountryCode(val);
        else setCountryCode(undefined);
    };

    const renderCountry = (country: { code: string; name: string }) => (
        <option value={country.code} key={country.code}>
            {country.name} ({country.code})
        </option>
    );

    let renderedCountries = <></>;

    if (countries)
        renderedCountries = (
            <>
                <option value="" key="default">
                    {t(
                        "settingsPage.payoutSettings.payoutCountrySettings.selectCountry"
                    )}
                </option>
                {countries.map((country) => renderCountry(country))}
            </>
        );

    let content = <Spinner />;
    if (!isLoadingCountries) {
        content = (
            <div className="input-group input-group-sm has-validation">
                <select
                    className={`form-select  ${!!errorMsg && "is-invalid"}`}
                    onChange={changeHandler}
                >
                    {renderedCountries}
                </select>
                <Button
                    className="btn btn-sm btn-dark"
                    type="submit"
                    onClick={handleSaveCountry}
                    isLoading={isLoading}
                    onlySpinner={true}
                >
                    {t("settingsPage.save")}
                </Button>
                <div className="invalid-feedback">{errorMsg}</div>
            </div>
        );
    }

    return (
        <>
            <h6>
                {t(
                    "settingsPage.payoutSettings.payoutCountrySettings.payoutCountry"
                )}
            </h6>
            <hr />
            {content}
        </>
    );
};

export default PayoutCountrySettings;
