import axios from "../axiosConfig";
import { ApiErrorCode, FALLBACK_LOCALE, LOCALE } from "../../constants";
import Payout from "../../models/Payout";

export type FailureResponse = {
    status_code: number;
    code: ApiErrorCode;
    errors?: {
        error?: string | string[];
    };
};

export const payOut = async (): Promise<Payout[]> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.post(`/payments/payouts`, null, {
            headers: {
                Authorization: token ? `Token ${token}` : "",
                "Accept-Language":
                    localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
            },
        });
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
