import User from "./User";

class PetitionSupporter {
    readonly uuid: string;
    readonly user: User;
    readonly pledge_amount: number;
    readonly currency: string;
    readonly dt_created: string;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.user = new User(data.user);
        this.pledge_amount = data.pledge_amount;
        this.currency = data.currency;
        this.dt_created = data.dt_created;
    }
}

export default PetitionSupporter;
