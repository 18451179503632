import axios from "../axiosConfig";
import { ApiErrorCode, FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: ApiErrorCode;
    errors?: {
        error?: string | string[];
    };
};

export const getStripeAccountLink = async (update: boolean) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/payments/account-link`, {
            headers: {
                Authorization: token ? `Token ${token}` : "",
                "Accept-Language":
                    localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
            },
            params: {
                update,
            },
        });
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
