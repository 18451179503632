import classes from "./ModalBackdrop.module.scss";
import { useContext } from "react";
import ModalContext from "../../../contexts/modal-context";

const ModalBackdrop = () => {
    const { closeModal } = useContext(ModalContext);
    return <div className={classes.backdrop} onClick={closeModal} />;
};

export default ModalBackdrop;
