import React, { useCallback, useContext, useEffect, useState } from "react";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import PaymentMethodsList from "./PaymentMethodsList/PaymentMethodsList";
import {
    FailureResponse as SetPaymentMethodAsDefaultFailureResponse,
    setPaymentMethodAsDefault as api_setPaymentMethodAsDefault,
} from "../../../../api/payments/setPaymentMethodAsDefault";
import {
    deletePaymentMethod as api_deletePaymentMethod,
    FailureResponse as DeletePaymentMethodFailureResponse,
} from "../../../../api/payments/deletePaymentMethod";
import PaymentMethod from "../../../../models/PaymentMethod";
import {
    FailureResponse as GetPaymentMethodsFailureResponse,
    listPaymentMethods as api_listPaymentMethods,
} from "../../../../api/payments/listPaymentMethods";
import AddNewPaymentMethod from "./AddNewPaymentMethod/AddNewPaymentMethod";
import AlertOverlay from "../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../contexts/modal-context";

const PaymentMethodsSettingsPage = () => {
    const { showModal, closeModal } = useContext(ModalContext);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const getPaymentMethods = useCallback(async () => {
        try {
            const response = await api_listPaymentMethods();
            setPaymentMethods(response);
        } catch (error) {
            const { errors } = error as GetPaymentMethodsFailureResponse;
            if (errors)
                showModal(
                    <AlertOverlay
                        status="error"
                        message={
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error!
                        }
                    />
                );
        }
        setIsLoading(false);
    }, [showModal]);

    useEffect(() => {
        closeModal();
        getPaymentMethods();
    }, [getPaymentMethods]);

    const setPaymentMethodAsDefault = useCallback(
        async (paymentMethodId: string) => {
            setIsLoading(true);
            try {
                const response = await api_setPaymentMethodAsDefault(
                    paymentMethodId
                );
                setPaymentMethods(response);
            } catch (error) {
                const { errors } =
                    error as SetPaymentMethodAsDefaultFailureResponse;
                if (errors)
                    showModal(
                        <AlertOverlay
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
            }
            setIsLoading(false);
        },
        [showModal]
    );

    const deletePaymentMethod = async (paymentMethodId: string) => {
        setIsLoading(true);
        try {
            const response = await api_deletePaymentMethod(paymentMethodId);
            setPaymentMethods(response);
        } catch (error) {
            const { errors } = error as DeletePaymentMethodFailureResponse;
            if (errors)
                showModal(
                    <AlertOverlay
                        status="error"
                        message={
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error!
                        }
                    />
                );
        }
        setIsLoading(false);
    };

    return (
        <div className="d-flex flex-column gap-3">
            <div className="border rounded-3 p-3">
                <AddNewPaymentMethod
                    setPaymentMethodAsDefault={setPaymentMethodAsDefault}
                />
            </div>
            <div className="border rounded-3 p-3">
                {isLoading && <Spinner />}
                {!isLoading && (
                    <PaymentMethodsList
                        paymentMethods={paymentMethods}
                        setPaymentMethodAsDefault={setPaymentMethodAsDefault}
                        deletePaymentMethod={deletePaymentMethod}
                    />
                )}
            </div>
        </div>
    );
};

export default PaymentMethodsSettingsPage;
