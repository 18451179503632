import {
    FailureResponse as GetStripeAccountFailureResponse,
    getStripeAccount as api_getStripeAccount,
} from "../../../../../api/payments/getStripeAccount";
import {
    FailureResponse as GetStripeAccountLinkFailureResponse,
    getStripeAccountLink as api_getStripeAccountLink,
} from "../../../../../api/payments/getStripeAccountLink";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ModalContext from "../../../../../contexts/modal-context";
import { ApiErrorCode } from "../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import Button from "../../../../../components/UI/Button/Button";
import PayoutMethodsList from "./PayoutMethodsList/PayoutMethodsList";
import StripeAccount, {
    PayoutDisabledReason,
} from "../../../../../models/StripeAccount";
import { useTranslation } from "react-i18next";

const PayoutMethodSettings = () => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const [stripeAccount, setStripeAccount] = useState<StripeAccount | null>(
        null
    );
    const [gettingAccount, setGettingAccount] = useState(true);
    const [gettingAccountLink, setGettingAccountLink] = useState(false);

    const urlParam = new URLSearchParams(window.location.search).get("url");

    const getStripeAccount = useCallback(async () => {
        try {
            const response = await api_getStripeAccount();
            setStripeAccount(response);
        } catch (error) {
            const { errors, status_code, code } =
                error as GetStripeAccountFailureResponse;

            if (code === ApiErrorCode.NOT_FOUND) {
                setStripeAccount(null);
            } else if (errors)
                if (
                    status_code === 403 &&
                    code === ApiErrorCode.EMAIL_NOT_VERIFIED
                ) {
                    showModal(
                        <EmailNotVerifiedErrorOverlay
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
                } else
                    showModal(
                        <AlertOverlay
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
        }
        setGettingAccount(false);
    }, [showModal]);

    useEffect(() => {
        getStripeAccount();
        if (urlParam === "refresh") getStripeAccountLink(false);
    }, [getStripeAccount]);

    const getStripeAccountLink = async (update: boolean) => {
        setGettingAccountLink(true);
        try {
            const response = await api_getStripeAccountLink(update);
            // redirect
            window.location.href = response.url;
        } catch (error) {
            const { errors, status_code, code } =
                error as GetStripeAccountLinkFailureResponse;

            if (errors)
                if (
                    status_code === 403 &&
                    code === ApiErrorCode.EMAIL_NOT_VERIFIED
                ) {
                    showModal(
                        <EmailNotVerifiedErrorOverlay
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
                } else
                    showModal(
                        <AlertOverlay
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
            setGettingAccountLink(false);
        }
    };

    const payoutMethods = stripeAccount
        ? stripeAccount.external_accounts.data
        : [];

    const alerts = [];
    let disabled_reason: string = "";
    let buttonClass: string = "dark";
    let buttonText: string = t(
        "settingsPage.payoutSettings.payoutMethodSettings.addPayoutMethod"
    );
    let buttonDisabled: boolean = false;

    let linkTypeUpdate: boolean = false;

    if (!gettingAccount) {
        if (stripeAccount) {
            if (stripeAccount.external_accounts.data.length > 0) {
                linkTypeUpdate = true;
                buttonClass = "warning";
                buttonText = t(
                    "settingsPage.payoutSettings.payoutMethodSettings.editPayoutMethod"
                );
            }

            if (stripeAccount.payouts_enabled) {
                if (stripeAccount.requirements.eventually_due.length > 0) {
                    linkTypeUpdate = false;
                    buttonClass = "warning";
                    buttonText = t(
                        "settingsPage.payoutSettings.payoutMethodSettings.provideMissingInfo"
                    );

                    alerts.push(
                        <div
                            className="alert alert-warning w-100 d-flex flex-column gap-3"
                            role="alert"
                        >
                            <div>
                                <span>
                                    {t(
                                        "settingsPage.payoutSettings.payoutMethodSettings.followingEventuallyRequired"
                                    )}
                                </span>
                                <ul className="m-0 mt-2">
                                    {stripeAccount.requirements.eventually_due.map(
                                        (item: string, index: number) => (
                                            <li key={index}>
                                                {item.split(".").join(" ")}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        </div>
                    );

                    if (stripeAccount.requirements.errors.length > 0) {
                        linkTypeUpdate = false;
                        buttonClass = "warning";
                        buttonText = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.provideMissingInfo"
                        );

                        alerts.push(
                            <div
                                className="alert alert-warning w-100"
                                role="alert"
                            >
                                <span>
                                    {t(
                                        "settingsPage.payoutSettings.payoutMethodSettings.errors"
                                    )}
                                    :
                                </span>
                                <ul className="m-0 mt-2">
                                    {stripeAccount.requirements.errors.map(
                                        (item, index: number) => (
                                            <li key={index}>{item.reason}</li>
                                        )
                                    )}
                                </ul>
                            </div>
                        );
                    }
                }
            } else {
                linkTypeUpdate = false;
                buttonClass = "danger";
                buttonText = t(
                    "settingsPage.payoutSettings.payoutMethodSettings.fixPayoutMethod"
                );

                // Docs: https://stripe.com/docs/connect/handling-api-verification
                switch (stripeAccount.requirements.disabled_reason) {
                    case PayoutDisabledReason.ACTION_REQUIRED__REQUESTED_CAPABILITIES: {
                        disabled_reason = "";
                        break;
                    }
                    case PayoutDisabledReason.LISTED: {
                        // disabled_reason =
                        //     "Your account might be on a prohibited persons or companies list. Stripe will investigate and either reject or reinstate your account.";
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountUnderReviewByStripe"
                        );
                        buttonClass = "secondary";
                        buttonText = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.payoutMethodUnderReview"
                        );
                        buttonDisabled = true;
                        break;
                    }
                    case PayoutDisabledReason.REJECTED__FRAUD: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountRejectedDueSuspectedFraud"
                        );
                        break;
                    }
                    case PayoutDisabledReason.REJECTED__INCOMPLETE_VERIFICATION: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountRejectedDueIncompleteVerification"
                        );
                        break;
                    }
                    case PayoutDisabledReason.REJECTED__LISTED: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountRejectedDueThirdParty"
                        );
                        break;
                    }
                    case PayoutDisabledReason.REJECTED__OTHER: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountRejectedForAnotherReason"
                        );
                        break;
                    }
                    case PayoutDisabledReason.REJECTED__TERMS_OF_SERVICE: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountRejectedDueSuspectedTermsViolations"
                        );
                        break;
                    }
                    case PayoutDisabledReason.REQUIREMENTS__PAST_DUE: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.additionalVerificationInfoRequired"
                        );
                        break;
                    }
                    case PayoutDisabledReason.REQUIREMENTS__PENDING_VERIFICATION: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.stripeVerifyingInfo"
                        );
                        buttonClass = "secondary";
                        buttonText = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.payoutMethodUnderReview"
                        );
                        buttonDisabled = true;
                        break;
                    }
                    case PayoutDisabledReason.UNDER_REVIEW: {
                        disabled_reason = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.accountUnderReviewByStripe"
                        );
                        buttonClass = "secondary";
                        buttonText = t(
                            "settingsPage.payoutSettings.payoutMethodSettings.payoutMethodUnderReview"
                        );
                        buttonDisabled = true;
                        break;
                    }
                }

                alerts.push(
                    <div className="alert alert-danger w-100" role="alert">
                        <div className="fw-semibold">
                            {t(
                                "settingsPage.payoutSettings.payoutMethodSettings.payoutsDisabled"
                            )}
                        </div>
                        <div className="mt-2">
                            <small>{disabled_reason}</small>
                        </div>
                        <div className="mt-3">
                            <small>
                                {t(
                                    "settingsPage.payoutSettings.payoutMethodSettings.contactUsForHelp"
                                )}
                            </small>
                        </div>
                    </div>
                );

                if (stripeAccount.requirements.errors.length > 0) {
                    buttonClass = "danger";
                    buttonText = t(
                        "settingsPage.payoutSettings.payoutMethodSettings.fixPayoutMethod"
                    );
                    buttonDisabled = false;

                    alerts.push(
                        <div className="alert alert-danger w-100" role="alert">
                            <span>
                                {t(
                                    "settingsPage.payoutSettings.payoutMethodSettings.errors"
                                )}
                                :
                            </span>
                            <ul className="m-0 mt-2">
                                {stripeAccount.requirements.errors.map(
                                    (item, index: number) => (
                                        <li key={index}>{item.reason}</li>
                                    )
                                )}
                            </ul>
                        </div>
                    );
                }
            }
        }
    }

    return (
        <>
            <h6>
                {t(
                    "settingsPage.payoutSettings.payoutMethodSettings.payoutDetails"
                )}
            </h6>
            <hr />
            {gettingAccount && <Spinner />}
            {!gettingAccount && (
                <>
                    {alerts.map((item, index: number) => (
                        <span key={index}>{item}</span>
                    ))}
                    <PayoutMethodsList payoutMethods={payoutMethods} />
                    <hr />
                    <Button
                        className={`btn btn-sm btn-${buttonClass} float-end`}
                        isLoading={gettingAccountLink}
                        onClick={() => getStripeAccountLink(linkTypeUpdate)}
                        disabled={buttonDisabled}
                    >
                        {buttonText}
                    </Button>
                </>
            )}
        </>
    );
};

export default PayoutMethodSettings;
