import Petition from "../../../../../models/Petition";
import React, { useContext, useState } from "react";
import {
    ApiErrorCode,
    MAX_PETITION_PLEDGE_AMOUNT,
    MIN_PETITION_PLEDGE_AMOUNT,
} from "../../../../../constants";
import InputGroup from "../../../../UI/InputGroup/InputGroup";
import {
    FailureResponse,
    supportPetition as api_supportPetition,
} from "../../../../../api/petitions/supportPetition";
import Button from "../../../../UI/Button/Button";
import AlertOverlay from "../../AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../contexts/modal-context";
import EmailNotVerifiedErrorOverlay from "../../EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import { Link } from "react-router-dom";
import AuthContext from "../../../../../contexts/auth-context";
import { useTranslation } from "react-i18next";

type Props = {
    petition: Petition;
    onSupport: (petition: Petition) => void;
};

const SupportPetitionForm = ({ petition, onSupport }: Props) => {
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const { user } = useContext(AuthContext);
    const [pledgeAmount, setPledgeAmount] = useState<number>(
        MIN_PETITION_PLEDGE_AMOUNT
    );
    const [pledgeAmountInputErrorMsg, setPledgeAmountInputErrorMsg] =
        useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const isPledgeAmountValid = () => {
        if (
            isNaN(pledgeAmount) ||
            pledgeAmount === undefined ||
            pledgeAmount < MIN_PETITION_PLEDGE_AMOUNT ||
            pledgeAmount > MAX_PETITION_PLEDGE_AMOUNT
        ) {
            setPledgeAmountInputErrorMsg(
                t("startPetitionPage.pleaseEnterValueBetween", {
                    min_value: MIN_PETITION_PLEDGE_AMOUNT,
                    max_value: MAX_PETITION_PLEDGE_AMOUNT,
                })
            );
            return false;
        }
        return true;
    };

    const pledgeAmountChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const val = parseInt(event.currentTarget.value);
        setPledgeAmount(val);
        if (
            isNaN(val) ||
            val < MIN_PETITION_PLEDGE_AMOUNT ||
            val > MAX_PETITION_PLEDGE_AMOUNT
        ) {
            setPledgeAmountInputErrorMsg(
                t("startPetitionPage.pleaseEnterValueBetween", {
                    min_value: MIN_PETITION_PLEDGE_AMOUNT,
                    max_value: MAX_PETITION_PLEDGE_AMOUNT,
                })
            );
        } else {
            setPledgeAmountInputErrorMsg("");
        }
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!isPledgeAmountValid()) return;

        setIsLoading(true);
        try {
            const response = await api_supportPetition(
                petition.uuid,
                pledgeAmount * 100
            );
            onSupport(response);
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (errors.pledge_amount)
                        setPledgeAmountInputErrorMsg(errors.pledge_amount[0]);
                    if (errors.non_field_errors)
                        setErrorMsg(errors.non_field_errors[0]);
                    if (errors.error)
                        setErrorMsg(
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error
                        );
                }
            }
        }
        setIsLoading(false);
    };

    return (
        <form onSubmit={submitHandler}>
            <div className="mb-3 row">
                <label
                    htmlFor="pledgeAmountInput"
                    className="col-sm-2 col-form-label col-form-label-sm"
                >
                    {t("modal.overlays.supportPetition.pledge")}:
                </label>
                <div className="col-sm-10">
                    <InputGroup
                        id="pledgeAmountInput"
                        className="input-group-sm"
                        errorMsg={pledgeAmountInputErrorMsg}
                        leadingAddon={
                            <span className="input-group-text">
                                {user!.account!.payment_settings.currency.toUpperCase()}
                            </span>
                        }
                        trailingAddon={
                            <span className="input-group-text">.00</span>
                        }
                        type="number"
                        min={MIN_PETITION_PLEDGE_AMOUNT}
                        max={MAX_PETITION_PLEDGE_AMOUNT}
                        onChange={pledgeAmountChangeHandler}
                        value={pledgeAmount !== undefined ? pledgeAmount : ""}
                    />
                </div>
            </div>
            {errorMsg && (
                <div className="form-text text-danger mt-0">{errorMsg}</div>
            )}
            <hr />
            <div className="d-flex justify-content-end gap-1">
                <Link
                    to="/settings/account"
                    className="btn btn-sm btn-secondary"
                >
                    {t("modal.overlays.supportPetition.topUpBalance")}
                </Link>
                <Button
                    className="btn btn-sm btn-primary"
                    type="submit"
                    disabled={isLoading}
                    isLoading={isLoading}
                >
                    {t("modal.overlays.supportPetition.support")}
                </Button>
                <Button
                    className="btn btn-sm btn-dark"
                    type="button"
                    onClick={closeModal}
                >
                    {t("modal.close")}
                </Button>
            </div>
        </form>
    );
};

export default SupportPetitionForm;
