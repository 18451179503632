import React, { useContext, useState } from "react";
import Button from "../../../../../components/UI/Button/Button";
import AlertOverlay from "../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../../contexts/modal-context";
import { useTranslation } from "react-i18next";

type Props = {
    isLoading: boolean;
    updateAccount: (currency: string) => void;
};

const CurrencySettings = ({ updateAccount, isLoading }: Props) => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const [currency, setCurrency] = useState<string | undefined>();
    const [errorMsg, setErrorMsg] = useState<string>();

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!!currency) {
            showModal(
                <AlertOverlay
                    status="warning"
                    message={`${t(
                        "settingsPage.accountSettings.currencySettings.setCurrencyWarning",
                        { currency: currency.toUpperCase() }
                    )}`}
                    onConfirm={updateCurrency}
                />
            );
        } else
            setErrorMsg(
                t(
                    "settingsPage.accountSettings.currencySettings.pleaseSelectCurrency"
                )
            );
    };

    const updateCurrency = () => {
        updateAccount(currency!);
    };

    const changeHandler = (event: React.FormEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setErrorMsg(undefined);
        const val = event.currentTarget.value;
        if (!!val) setCurrency(val);
        else setCurrency(undefined);
    };

    return (
        <div>
            <h6>Set balance currency</h6>
            <hr />
            <form onSubmit={submitHandler}>
                <div className="input-group input-group-sm has-validation">
                    <select
                        className={`form-select ${!!errorMsg && "is-invalid"}`}
                        onChange={changeHandler}
                    >
                        <option value="">
                            {t(
                                "settingsPage.accountSettings.currencySettings.selectCurrency"
                            )}
                        </option>
                        <option value="eur">EUR</option>
                        <option value="gbp">GBP</option>
                        <option value="usd">USD</option>
                    </select>
                    <Button
                        className="btn btn-sm btn-dark"
                        type="submit"
                        isLoading={isLoading}
                        onlySpinner={true}
                    >
                        {t("settingsPage.save")}
                    </Button>
                    <div className="invalid-feedback">{errorMsg}</div>
                </div>
            </form>
        </div>
    );
};

export default CurrencySettings;
