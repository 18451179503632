import PetitionsListItem from "./PetitionsListItem/PetitionsListItem";
import Petition from "../../models/Petition";
import { ComponentProps, Ref, useCallback, useRef } from "react";

interface Props extends ComponentProps<any> {
    petitions: Petition[];
    getPetitions: () => void;
    isLoading: boolean;
}

const PetitionsList = ({
    petitions,
    getPetitions,
    isLoading,
    className,
}: Props) => {
    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
        (node: Element) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => {
                    if (entries[0].isIntersecting) {
                        getPetitions();
                    }
                }
            );
            if (node) observer.current.observe(node);
        },
        [isLoading, getPetitions]
    ) as Ref<HTMLDivElement>;

    const renderPetitionsList = petitions.map(
        (petition: Petition, index: number) => {
            return (
                <div
                    key={petition.uuid}
                    ref={
                        petitions.length === index + 1
                            ? lastElementRef
                            : undefined
                    }
                >
                    <PetitionsListItem petition={petition} />
                </div>
            );
        }
    );

    return (
        <div className={`${className} d-flex flex-column gap-3`}>
            {renderPetitionsList}
        </div>
    );
};

export default PetitionsList;
