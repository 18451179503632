import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        youtube_channel_id?: string[];
        non_field_errors?: string[];
        error?: string | string[];
    };
};

export const requestBecomeCreator = async (youtube_channel_id: string) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.post(
            "/admins/request-become-creator",
            {
                youtube_channel_id,
            },
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
