import { useContext } from "react";
import ReactDOM from "react-dom";
import ModalContext from "../../contexts/modal-context";
import ModalBackdrop from "./ModalBackdrop/ModalBackdrop";

const portalElement = document.getElementById("modal")!;
const Modal = () => {
    const { visible, overlay } = useContext(ModalContext);
    return (
        <>
            {visible && (
                <>
                    {ReactDOM.createPortal(<ModalBackdrop />, portalElement)}
                    {ReactDOM.createPortal(overlay, portalElement)}
                </>
            )}
        </>
    );
};

export default Modal;
