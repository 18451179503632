import SettingsPageNavbar from "./SetingsPageNavbar/SettingsPageNavbar";
import { Navigate, Outlet, useMatch } from "react-router-dom";
import { useContext, useEffect } from "react";
import ModalContext from "../../contexts/modal-context";

export enum SettingsPageTab {
    PROFILE = "profile",
    ACCOUNT = "account",
    PAYMENT_METHODS = "payment-methods",
    BILLING = "billing",
    PAYOUT = "payout",
    NOTIFICATIONS = "notifications",
}

const SettingsPage = () => {
    const { closeModal } = useContext(ModalContext);
    useEffect(() => {
        closeModal();
    }, [closeModal]);

    const match = useMatch("/settings/:tab");
    if (!match)
        return (
            <Navigate
                to={`/settings/${SettingsPageTab.PROFILE}`}
                replace={true}
            />
        );

    return (
        <div>
            <SettingsPageNavbar />
            <div className="py-4">
                <Outlet />
            </div>
        </div>
    );
};

export default SettingsPage;
