import axios from "../axiosConfig";
import User from "../../models/User";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        name?: string[];
        username?: string[];
        error?: string | string[];
    };
};

export const updateUser = async (
    name?: string,
    username?: string
): Promise<User> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.patch(
            `/users/self`,
            {
                name,
                username,
            },
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return new User(response.data);
    } catch (error: any) {
        throw error.response.data;
    }
};
