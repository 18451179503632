export enum NotificationClickDestinationType {
    USER = "user",
    PETITION = "petition",
}

export default class Notification {
    constructor(
        readonly uuid: string,
        readonly dt_created: string,
        readonly is_new: boolean,
        readonly is_seen: boolean,
        readonly notification: {
            readonly message: string;
            readonly initiator: {
                readonly uuid: string;
                readonly profile_image: string;
            };
            readonly click_destination_type: NotificationClickDestinationType;
            readonly click_destination_unique_id: string;
        }
    ) {}
}
