import {
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import Button from "../../../../../../components/UI/Button/Button";
import React, { useContext, useEffect, useState } from "react";
import ModalContext from "../../../../../../contexts/modal-context";
import AlertOverlay from "../../../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import { useTranslation } from "react-i18next";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import {
    FailureResponse,
    getStripeSetupIntent as api_getStripeSetupIntent,
} from "../../../../../../api/payments/getStripeSetupIntent";
import { ApiErrorCode } from "../../../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";

type Props = {
    setPaymentMethodAsDefault: (id: string) => void;
};

const PaymentMethodSetupForm = ({ setPaymentMethodAsDefault }: Props) => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "setup_intent_client_secret"
        );

        if (clientSecret)
            stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
                // Inspect the SetupIntent `status` to indicate the status of the payment
                // to your customer.
                //
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                switch (setupIntent!.status) {
                    case "succeeded":
                        setPaymentMethodAsDefault(
                            typeof setupIntent!.payment_method === "string"
                                ? setupIntent!.payment_method
                                : setupIntent!.payment_method!.id
                        );
                        showModal(
                            <AlertOverlay
                                status="success"
                                message={t(
                                    "settingsPage.paymentMethodsSettings.addNewPaymentMethod.paymentMethodSetupForm.savedPaymentMethod"
                                )}
                            />
                        );
                        break;
                    case "processing":
                        showModal(
                            <AlertOverlay
                                status="warning"
                                message={t(
                                    "settingsPage.paymentMethodsSettings.addNewPaymentMethod.paymentMethodSetupForm.processingPaymentDetails"
                                )}
                            />
                        );
                        break;
                    case "requires_payment_method":
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={t(
                                    "settingsPage.paymentMethodsSettings.addNewPaymentMethod.paymentMethodSetupForm.failedProcessPaymentDetails"
                                )}
                            />
                        );
                        break;
                }
            });
    }, [stripe, setPaymentMethodAsDefault, showModal]);

    const getStripeSetupIntent = async () => {
        try {
            const response = await api_getStripeSetupIntent();
            return response.client_secret;
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors)
                if (
                    status_code === 403 &&
                    code === ApiErrorCode.EMAIL_NOT_VERIFIED
                ) {
                    showModal(
                        <EmailNotVerifiedErrorOverlay
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
                } else
                    showModal(
                        <AlertOverlay
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
        }
    };

    const submitHandler = async (event: React.FormEvent) => {
        // Docs: https://stripe.com/docs/payments/accept-a-payment-deferred?platform=web&type=setup
        event.preventDefault();
        setErrorMessage(undefined);

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        // Trigger form validation
        const { error: submitError } = await elements.submit();
        if (submitError) {
            setIsLoading(false);
            setErrorMessage(submitError.message);
            return;
        }

        const clientSecret = await getStripeSetupIntent();
        if (clientSecret) {
            const { error } = await stripe.confirmSetup({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: `${process.env.REACT_APP_BASE_URL}/settings/payment-methods`,
                },
            });

            if (error) {
                setErrorMessage(error.message);
            }
        }

        setIsLoading(false);
    };

    const options: StripePaymentElementOptions = {
        layout: {
            type: "accordion",
            defaultCollapsed: false,
            radios: true,
            spacedAccordionItems: false,
        },
    };

    return (
        <form onSubmit={submitHandler}>
            <PaymentElement options={options} />
            <span className="text-danger form-text">{errorMessage}</span>
            <Button
                className="btn btn-sm btn-primary mt-3 float-end"
                type="submit"
                disabled={!stripe}
                isLoading={isLoading}
            >
                {t(
                    "settingsPage.paymentMethodsSettings.addNewPaymentMethod.addPaymentMethod"
                )}
            </Button>
        </form>
    );
};

export default PaymentMethodSetupForm;
