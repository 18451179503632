import { Link } from "react-router-dom";
import User from "../../models/User";
import UserBasicInfo from "../UserBasicInfo/UserBasicInfo";

type Props = {
    user: User;
    className?: string;
};
const ProfileLink = ({ user, className }: Props) => {
    return (
        <Link
            to={`/users/${user.username}`}
            className={`text-decoration-none text-dark d-flex align-items-center ${className}`}
        >
            <UserBasicInfo user={user} />
        </Link>
    );
};

export default ProfileLink;
