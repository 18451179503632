import React, { ComponentProps, Ref, useCallback, useRef } from "react";
import PetitionSupporter from "../../models/PetitionSupporter";
import classes from "./PetitionSupportersList.module.scss";
import { Link } from "react-router-dom";
import PetitionSupportersListItem from "./PetitionSupportersListItem/PetitionSupportersListItem";

interface Props extends ComponentProps<any> {
    supporters: PetitionSupporter[];
    showPledgeAmount: boolean;
    getPetitionSupporters: () => void;
    isLoading: boolean;
}

const PetitionSupportersList = ({
    supporters,
    showPledgeAmount,
    getPetitionSupporters,
    isLoading,
    className,
}: Props) => {
    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
        (node: Element) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => {
                    if (entries[0].isIntersecting) {
                        getPetitionSupporters();
                    }
                }
            );
            if (node) observer.current.observe(node);
        },
        [isLoading, getPetitionSupporters]
    );

    const renderedUsers = supporters.map(
        (supporter: PetitionSupporter, index: number) => {
            const item = (
                <PetitionSupportersListItem
                    supporter={supporter}
                    showPledgeAmount={showPledgeAmount}
                />
            );

            return (
                <Link
                    key={supporter.uuid}
                    to={`/users/${supporter.user.username}`}
                    className={`${classes.supporter} d-block text-decoration-none text-dark rounded`}
                    ref={
                        supporters.length === index + 1
                            ? (lastElementRef as Ref<HTMLAnchorElement>)
                            : undefined
                    }
                >
                    {item}
                </Link>
            );
        }
    );
    return (
        <div className={`${className} d-flex flex-column gap-1`}>
            {renderedUsers}
        </div>
    );
};

export default PetitionSupportersList;
