import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import User from "../../models/User";
import { getUser as api_getUser } from "../../api/users/getUser";
import Spinner from "../../components/UI/Spinner/Spinner";
import ProfilePageHeader from "./ProfilePageHeader/ProfilePageHeader";
import AuthContext from "../../contexts/auth-context";
import ModalContext from "../../contexts/modal-context";
import ProfilePagePetitions, {
    ProfilePagePetitionsTab,
} from "./ProfilePagePetitions/ProfilePagePetitions";
import ProfilePageFollows, {
    ProfilePageFollowsTab,
} from "./ProfilePageFollows/ProfilePageFollows";

const ProfilePage = () => {
    const { closeModal } = useContext(ModalContext);
    const { username } = useParams();

    const { storeUser } = useContext(AuthContext);
    const [user, setUser] = useState<User | null>(null);

    let activeTab = useLocation().pathname.split("/").at(-1)!;
    if (!activeTab || activeTab === username)
        activeTab =
            user && user.is_creator
                ? ProfilePagePetitionsTab.RECEIVED
                : ProfilePagePetitionsTab.SUPPORTED;

    const getUser = useCallback(async () => {
        try {
            const response = await api_getUser(username!);
            setUser(response);
            if (response.is_myself) storeUser(response);
        } catch (error) {
            console.log(error);
        }
    }, [storeUser, username]);

    useEffect(() => {
        closeModal();
        getUser();
    }, [closeModal, getUser]);

    return (
        <div>
            {!user && <Spinner />}
            {user && (
                <>
                    <ProfilePageHeader user={user} />
                    {Object.values<string>(ProfilePagePetitionsTab).includes(
                        activeTab
                    ) && (
                        <ProfilePagePetitions
                            user={user}
                            activeTab={activeTab as ProfilePagePetitionsTab}
                        />
                    )}
                    {Object.values<string>(ProfilePageFollowsTab).includes(
                        activeTab
                    ) && (
                        <ProfilePageFollows
                            user={user}
                            activeTab={activeTab as ProfilePageFollowsTab}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default ProfilePage;
