import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./en/translation.json";
import translationRU from "./ru/translation.json";
import { FALLBACK_LOCALE, LOCALE } from "../constants";

if (!localStorage.getItem(LOCALE))
    localStorage.setItem(LOCALE, FALLBACK_LOCALE);
const lng = localStorage.getItem(LOCALE) || FALLBACK_LOCALE;

i18next.use(initReactI18next).init({
    lng: lng,
    fallbackLng: FALLBACK_LOCALE,
    debug: false,
    resources: {
        en: {
            translation: translationEN,
        },
        ru: {
            translation: translationRU,
        },
    },
});

export default i18next;
