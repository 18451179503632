import axios from "../axiosConfig";
import User from "../../models/User";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        headline?: string[];
        description?: string[];
        error?: string | string[];
    };
};

export const updateProfile = async (
    uuid: string,
    headline?: string,
    description?: string
): Promise<any> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.patch(
            `/users/${uuid}/profile`,
            {
                headline,
                description,
            },
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        // return new User(response.data);
    } catch (error: any) {
        throw error.response.data;
    }
};
