import { NavLink } from "react-router-dom";
import { GoHome, GoSearch } from "react-icons/go";
import { BsBell, BsPerson, BsPlusSquare } from "react-icons/bs";
import classes from "./Navbar.module.scss";
import { useContext } from "react";
import authContext from "../../contexts/auth-context";
import NotificationContext from "../../contexts/notification-context";
import { useTranslation } from "react-i18next";

const Navbar = () => {
    const { t } = useTranslation();
    const { user } = useContext(authContext);
    const { newNotificationsCount } = useContext(NotificationContext);

    return (
        <>
            <div className={`${classes.navbar} p-3`}>
                <nav className="nav flex-column gap-2 nav-underline">
                    <NavLink to="/" className="nav-link">
                        <GoHome className="mb-1 me-2" /> {t("navbar.home")}
                    </NavLink>
                    <NavLink to="/search" className="nav-link">
                        <GoSearch className="mb-1 me-2" /> {t("navbar.search")}
                    </NavLink>
                    <NavLink to="/start" className="nav-link">
                        <BsPlusSquare className="mb-1 me-2" />{" "}
                        {t("navbar.startPetition")}
                    </NavLink>
                    <NavLink
                        to="/notifications"
                        className="nav-link position-relative d-flex justify-content-between align-items-center"
                    >
                        <span>
                            <BsBell className="mb-1 me-2" />{" "}
                            {t("navbar.notifications")}
                        </span>
                        {newNotificationsCount > 0 && (
                            <span className="badge rounded-pill bg-danger-subtle text-danger-emphasis">
                                {newNotificationsCount <= 9
                                    ? newNotificationsCount
                                    : "9+"}
                            </span>
                        )}
                    </NavLink>
                    <NavLink
                        to={`/users/${user?.username}`}
                        className="nav-link"
                    >
                        <BsPerson className="mb-1 me-2" /> {t("navbar.profile")}
                    </NavLink>
                </nav>
            </div>

            <div className={`${classes["mobile-navbar"]} border-top`}>
                <nav className="nav flex-row justify-content-sm-center justify-content-between nav-underline">
                    <NavLink to="/" className="nav-link">
                        <GoHome />
                    </NavLink>
                    <NavLink to="/search" className="nav-link">
                        <GoSearch />
                    </NavLink>
                    <NavLink to="/start" className="nav-link">
                        <BsPlusSquare />
                    </NavLink>
                    <NavLink
                        to="/notifications"
                        className="nav-link position-relative"
                    >
                        <BsBell />
                        {newNotificationsCount > 0 && (
                            <span className="position-absolute p-1 bg-danger border border-light rounded-circle" />
                        )}
                    </NavLink>
                    <NavLink
                        to={`/users/${user?.username}`}
                        className="nav-link"
                    >
                        <BsPerson />
                    </NavLink>
                </nav>
            </div>
        </>
    );
};

export default Navbar;
